import { useEffect } from "react";
import { Loader } from "../../components/loader";
import { LOADER_TYPE } from "../../constants";
import {
  useGenerateUrlForStripeOnboardingMutation,
  GenerateUrlForStripeOnboardingMutationResult,
} from "../../generated/graphql";
import { useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

export function OnboardingStartStripeRoute() {
  const toast = useToast();
  const navigate = useNavigate();
  const [generateUrlForStripeOnboardingMutation] = useGenerateUrlForStripeOnboardingMutation();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  async function processOnboarding() {
    const response = (await generateUrlForStripeOnboardingMutation()) as GenerateUrlForStripeOnboardingMutationResult;

    if (response.data?.generateUrlForStripeOnboarding.ok && response.data.generateUrlForStripeOnboarding.url) {
      window.location.assign(response.data.generateUrlForStripeOnboarding.url);
    } else {
      const errorMessage =
        response.data?.generateUrlForStripeOnboarding.error?.message ??
        "Something unexpectedly went wrong while redirecting you to Stripe. Please try again.";
      toast({
        title: "Something went wrong",
        description: errorMessage,
        status: "error",
      });
      navigate("/");
    }
  }

  useEffect(() => {
    processOnboarding();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Loader type={LOADER_TYPE.fullView} message="Redirecting to Stripe..." />;
}
