import { useState } from "react";
import { Box, Text, Divider, Grid, Stack } from "@chakra-ui/react";
import {
  useSelectWritingSampleTopicMutation,
  WritingFragment,
  WritingSample_TopicFragment,
} from "../../generated/graphql";
import { AppColors } from "../../core/custom-theme";
import { PrimaryButton } from "../../components/buttons";
import { HEADER_HEIGHT } from "../../components/header";
import { CountdownText } from "../../components/countdown-text";
import { PageHeading } from "../../components/page-heading";
import { Subtitle } from "../../components/subtitle";

interface Props {
  writingSampleTopics: WritingSample_TopicFragment[];
  refetch: () => void;
  writingSample: WritingFragment;
}

export default function SelectTopic(props: Props) {
  const { writingSampleTopics, refetch, writingSample } = props;
  const [selectedTopic, setSelectedTopic] = useState(-1);
  const [selectWritingSampleTopic, { loading }] = useSelectWritingSampleTopicMutation();

  async function onBegin() {
    const response = await selectWritingSampleTopic({
      variables: {
        input: {
          writingSampleTopicId: selectedTopic,
        },
      },
    });

    if (response.data?.selectWritingSampleTopic.ok) {
      refetch();
    }
  }

  function onSelect(id: number) {
    setSelectedTopic(id);
  }

  function renderToolbarMobile() {
    return (
      <Box
        as="nav"
        alignItems="center"
        justifyContent="flex-end"
        textAlign="end"
        flexWrap="wrap"
        display={{ base: "flex", md: "none" }}
        position="sticky"
        top={HEADER_HEIGHT}
        bg="white"
        zIndex={999}
        borderBottom="1px solid black"
        height="40px"
      >
        <Box pr={4}>
          <CountdownText date={writingSample.endsAt} color={AppColors.textOrange} />
        </Box>
      </Box>
    );
  }

  function renderWritingSampleTopic(writingSampleTopic: WritingSample_TopicFragment) {
    const isSelected = writingSampleTopic.id === selectedTopic;
    return (
      <Box
        key={writingSampleTopic.id}
        bg={isSelected ? AppColors.blue : "white"}
        color={isSelected ? "white" : "inherit"}
        height={{ base: undefined, md: "300px" }}
        padding="16px"
        display="flex"
        alignItems="center"
        justifyContent="center"
        cursor="pointer"
        onClick={onSelect.bind(null, writingSampleTopic.id)}
      >
        <Box>
          <Stack spacing={6}>
            <Text fontWeight="bold" fontSize="lg">
              {writingSampleTopic.category?.name ?? ""}
            </Text>
            <Text>{writingSampleTopic.topic}</Text>
          </Stack>
        </Box>
      </Box>
    );
  }

  return (
    <>
      {renderToolbarMobile()}
      <Box>
        <PageHeading textAlign="center">Select your topic</PageHeading>
        <Subtitle>{`Below are some of the most common writing topics based on the industry listed for Creatives Scale customers.
          Please select what type of writing topics you would like to see, by industry.
          This will help us tailor available tasks to your favorite topics to write about.`}</Subtitle>
        <Divider marginY={6} />
        <Grid
          templateColumns="repeat(auto-fit, minmax(220px, 1fr))"
          columnGap={4}
          rowGap={4}
          justifyContent="center"
          alignItems="center"
          textAlign="center"
        >
          {writingSampleTopics.map((writingSampleTopic: WritingSample_TopicFragment) =>
            renderWritingSampleTopic(writingSampleTopic)
          )}
        </Grid>
        <Box textAlign="center" my={8}>
          <PrimaryButton onClick={onBegin} isLoading={loading} isDisabled={selectedTopic === -1} paddingX={24}>
            Begin
          </PrimaryButton>
        </Box>
      </Box>
    </>
  );
}
