import { useState, useEffect } from "react";
import { DateTime } from "luxon";

export function useExpiration(date: Date): [boolean] {
  const [isExpired, setIsExpired] = useState(false);

  useEffect(() => {
    const msToExpiration = DateTime.fromJSDate(date).diffNow().toObject()?.milliseconds ?? 0;
    const timeout = setTimeout(() => {
      setIsExpired(true);
    }, msToExpiration);

    return () => {
      clearTimeout(timeout);
    };
  }, [date]);

  return [isExpired];
}
