import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { StringParam, useQueryParam, DelimitedNumericArrayParam, NumberParam, withDefault } from "use-query-params";
import { DateTime } from "luxon";

import { TasksSort, useAdminQaViewTasksQuery } from "../../generated/graphql";
import { usePagination } from "../../util/use-pagination";
import { CS_VENDOR_ID, PAGE_LIMIT, TASK_STATUS, TASK_TYPES } from "../../constants";

export function useAdminQaViewTasks() {
  const navigate = useNavigate();
  const [search, setSearch] = useQueryParam("search", withDefault(StringParam, ""));
  const [userId, setUserId] = useQueryParam("users", withDefault(NumberParam, 0));
  const [clientIds, setClientIdsParam] = useQueryParam("clientIds", withDefault(DelimitedNumericArrayParam, []));
  const pagination = usePagination(PAGE_LIMIT);
  const completedAt = useMemo(() => DateTime.now().minus({ days: 30 }).toJSDate(), []);

  const queryResult = useAdminQaViewTasksQuery({
    fetchPolicy: "network-only",
    variables: {
      limit: pagination.limit,
      offset: pagination.offset,
      statusIds: [TASK_STATUS.COMPLETED],
      vendorIds: [CS_VENDOR_ID],
      taskTypeIds: [TASK_TYPES.CONTENT],
      search: search && search.length > 0 ? search : undefined,
      completedAt: { gte: completedAt },
      assignedUserIds: userId ? [userId] : [],
      customerIds: clientIds as number[],
      sort: TasksSort.WriterAsc,
    },
  });
  const tasks = queryResult.data?.tasks.nodes ?? [];

  function handleSearchChange(e: React.ChangeEvent<HTMLInputElement>) {
    setSearch(e.target.value);
    pagination.resetOffset();
  }

  function onUserSelected(userId: number | null) {
    setUserId(userId);
    pagination.resetOffset();
  }

  function onCustomerSelected(customerIds: number[]) {
    setClientIdsParam(customerIds);
    pagination.resetOffset();
  }

  let filterCount = 0;
  if (search) {
    filterCount++;
  }
  if (clientIds.length > 0) {
    filterCount++;
  }
  if (userId) {
    filterCount++;
  }

  function clearFilters() {
    navigate({ search: "" });
  }

  return {
    loading: queryResult.loading,
    tasks,
    pagination,
    totalCount: queryResult.data?.tasks.totalCount ?? 0,
    handleSearchChange,
    search: search ?? "",
    onUserSelected,
    userId,
    onCustomerSelected,
    clientIds,
    filterCount,
    clearFilters,
  };
}
