import { useEffect, useState } from "react";
import {
  Box,
  Flex,
  Text,
  Divider,
  Input,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Stack,
  Heading,
  FormControl,
  FormLabel,
  Textarea,
  Alert,
  AlertIcon,
} from "@chakra-ui/react";
import { TriangleDownIcon, TriangleUpIcon } from "@chakra-ui/icons";
import { faCheckCircle, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { faCircle } from "@fortawesome/free-regular-svg-icons";
import { useNavigate } from "react-router-dom";
import { DateTime } from "luxon";

import AppIcon from "../../components/app-icon";
import { useSelectWritingSampleTopicMutation, WritingFragment } from "../../generated/graphql";
import { ContentEditor } from "../../components/content-editor";
import { useSaveWritingSampleMutation } from "../../generated/graphql";
import { HOUR_MINUTE_FORMAT } from "../../constants";
import { PrimaryButton } from "../../components/buttons";
import { AppColors } from "../../core/custom-theme";
import { HEADER_HEIGHT } from "../../components/header";
import { CountdownText } from "../../components/countdown-text";
import { PanelGroup, PanelTop, PanelBottom, PanelMiddle, PanelBox } from "../../components/panels";
import { ValidationChecklistPanel } from "../../components/validation-checklist-panel";

const AUTOSAVE_INTERVAL = 2000;

interface Props {
  writingSample: WritingFragment;
  refetch: () => void;
}

export function WritingSampleWorkspace(props: Props) {
  const { writingSample, refetch } = props;

  const [title, setTitle] = useState(writingSample.contentItem?.title);
  const [content, setContent] = useState(writingSample.contentItem?.content);
  const [lastSaved, setLastSaved] = useState("");
  const navigate = useNavigate();

  const [saveWritingSample, { loading }] = useSaveWritingSampleMutation();
  const [selectWritingSampleTopic] = useSelectWritingSampleTopicMutation();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  async function autosave() {
    const response = await saveWritingSample({
      variables: {
        input: {
          title: title ?? "",
          content: content ?? "",
          isFinished: false,
        },
      },
    });

    if (response.data?.saveWritingSample.ok) {
      setLastSaved(DateTime.now().toFormat(HOUR_MINUTE_FORMAT));
    }
  }

  async function onSubmit() {
    const response = await saveWritingSample({
      variables: {
        input: {
          title: title ?? "",
          content: content ?? "",
          isFinished: true,
        },
      },
    });

    if (response.data?.saveWritingSample.ok) {
      setLastSaved(DateTime.now().toFormat(HOUR_MINUTE_FORMAT));
    }

    // Redirect to journey
    navigate("/");
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      if (writingSample.contentItem?.content !== content || writingSample.contentItem?.title !== title) {
        autosave();
      }
    }, AUTOSAVE_INTERVAL);
    return () => {
      clearTimeout(timer);
    };
  }, [title, content, writingSample.contentItem?.content, writingSample.contentItem?.title, autosave]);

  function onTitleChange(event: React.ChangeEvent<HTMLInputElement>) {
    setTitle(event.target.value);
  }

  function onContentChange(newContent: string) {
    setContent(newContent);
  }

  const validationChecks = writingSample.validationCheckSummary?.checks ?? [];

  function renderCheckList() {
    return validationChecks.map((check, index) => {
      return (
        <Box
          key={check.id}
          paddingY={2}
          borderBottom={index === validationChecks.length - 1 ? undefined : `1px solid #E3E8EF`}
        >
          <Flex justifyContent="space-between">
            <Text color={AppColors.textDarkGray} fontSize="sm">
              {check.name}
              <Popover trigger="hover">
                <PopoverTrigger>
                  <span>
                    <AppIcon icon={faInfoCircle} standardLeftMargin />
                  </span>
                </PopoverTrigger>
                <PopoverContent zIndex={4} color={AppColors.textDarkGray}>
                  <PanelBox>
                    <Stack shouldWrapChildren spacing={2}>
                      <Text>{check.helpText}</Text>
                      <Divider />
                      <Text>
                        <strong>Current</strong>: {check.actualValue}
                      </Text>
                      <Text>
                        <strong>Success</strong>: {check.successCriteria}
                      </Text>
                      <Text>
                        <strong>Failure</strong>: {check.failureCriteria}
                      </Text>
                    </Stack>
                  </PanelBox>
                </PopoverContent>
              </Popover>
            </Text>
            {check.isValid ? (
              <AppIcon icon={faCheckCircle} color="green" />
            ) : (
              <AppIcon icon={faCircle} color="#E3E8EF" />
            )}
          </Flex>
        </Box>
      );
    });
  }

  const checkedCount = validationChecks.filter((check) => check.isValid).length;

  function renderToolbarMobile() {
    return (
      <Box
        as="nav"
        alignItems="center"
        justifyContent="space-around"
        flexWrap="wrap"
        display={{ base: "flex", md: "none" }}
        position="sticky"
        top={HEADER_HEIGHT}
        bg="white"
        zIndex={999}
        borderBottom="1px solid black"
        height="40px"
      >
        <Popover>
          {({ isOpen }) => (
            <>
              <PopoverTrigger>
                <Flex
                  align="center"
                  role="button"
                  aria-label="topic"
                  borderBottom={isOpen ? "2px solid black" : "none"}
                  height="100%"
                >
                  <Text fontSize="md">Topic</Text>
                  {isOpen ? (
                    <TriangleUpIcon height="0.5em" width="1.5em" />
                  ) : (
                    <TriangleDownIcon height="0.5em" width="1.5em" />
                  )}
                </Flex>
              </PopoverTrigger>
              <PopoverContent zIndex={4}>
                <PopoverBody>
                  <Text fontSize="sm">{writingSample.writingSampleTopic?.topic ?? "-"}</Text>
                </PopoverBody>
              </PopoverContent>
            </>
          )}
        </Popover>
        <Popover>
          {({ isOpen }) => (
            <>
              <PopoverTrigger>
                <Flex
                  align="center"
                  role="button"
                  aria-label="topic"
                  borderBottom={isOpen ? "2px solid black" : "none"}
                  height="100%"
                >
                  <Text fontSize="md">{`Checklist (${checkedCount}/${validationChecks.length})`}</Text>
                  {isOpen ? (
                    <TriangleUpIcon height="0.5em" width="1.5em" />
                  ) : (
                    <TriangleDownIcon height="0.5em" width="1.5em" />
                  )}
                </Flex>
              </PopoverTrigger>
              <PopoverContent zIndex={4}>
                <PopoverBody>{renderCheckList()}</PopoverBody>
              </PopoverContent>
            </>
          )}
        </Popover>
        <Box>
          <CountdownText date={writingSample.endsAt} color={AppColors.textOrange} />
        </Box>
      </Box>
    );
  }

  async function goBackToTopics() {
    // Reset the topicId back to null to disregard current topic selection.
    const response = await selectWritingSampleTopic({
      variables: {
        input: {
          writingSampleTopicId: null,
        },
      },
    });
    if (response.data?.selectWritingSampleTopic.ok) {
      refetch();
    }
  }

  return (
    <>
      {renderToolbarMobile()}
      <Alert status="error" marginBottom={2}>
        <AlertIcon />
        {`We understand that AI can help aid in writing content, however anyone caught using AI for the actual content will be immediately terminated from CreativesScale.`}
      </Alert>
      <Flex>
        <Stack spacing={4} width="30%" display={{ base: "none", md: "block" }} marginRight={4}>
          <PanelGroup>
            <PanelTop showBottomBorder>
              <Heading size="md">Information</Heading>
            </PanelTop>
            <PanelBottom>
              <Stack spacing={4}>
                <FormControl>
                  <FormLabel>Category</FormLabel>
                  <Input variant="flushed" value={writingSample.writingSampleTopic?.category?.name ?? ""} isDisabled />
                </FormControl>
                <FormControl>
                  <Flex justifyContent="space-between">
                    <FormLabel>Topic</FormLabel>
                    <PrimaryButton size="xs" onClick={goBackToTopics}>
                      Change
                    </PrimaryButton>
                  </Flex>
                  <Input variant="flushed" value={writingSample.writingSampleTopic?.topic ?? ""} isDisabled />
                </FormControl>
                {writingSample.writingSampleTopic?.primaryKeyword && (
                  <FormControl>
                    <FormLabel>Primary Keyword</FormLabel>
                    <Input
                      variant="flushed"
                      value={writingSample.writingSampleTopic?.primaryKeyword ?? ""}
                      isDisabled
                    />
                  </FormControl>
                )}
                {writingSample.writingSampleTopic?.voice && (
                  <FormControl>
                    <FormLabel>Voice</FormLabel>
                    <Input variant="flushed" value={writingSample.writingSampleTopic?.voice ?? ""} isDisabled />
                  </FormControl>
                )}
                {writingSample.writingSampleTopic?.website && (
                  <FormControl>
                    <FormLabel>Website</FormLabel>
                    <Input variant="flushed" value={writingSample.writingSampleTopic?.website ?? ""} isDisabled />
                  </FormControl>
                )}
                {writingSample.writingSampleTopic?.additionalInformation && (
                  <FormControl>
                    <FormLabel>Additional Information</FormLabel>
                    <Textarea
                      variant="flushed"
                      value={writingSample.writingSampleTopic?.additionalInformation ?? ""}
                      isDisabled
                      resize="none"
                    />
                  </FormControl>
                )}
              </Stack>
            </PanelBottom>
          </PanelGroup>
        </Stack>
        <Stack spacing={4} width={{ base: "100%", md: "70%" }}>
          <PanelGroup>
            <PanelTop>
              <Input onChange={onTitleChange} value={title} placeholder="Title" fontSize="xl" fontWeight="bold" />
            </PanelTop>
            <PanelMiddle>
              <ContentEditor
                onChange={onContentChange}
                initialValue={writingSample.contentItem?.content ?? ""}
                height="300px"
              />
            </PanelMiddle>
            <PanelBottom display="flex" justifyContent="space-between" alignItems="center">
              <Text fontSize="sm" color={AppColors.textGray}>
                {!!lastSaved && `Last Saved ${lastSaved}`}
              </Text>
              <PrimaryButton onClick={onSubmit} isLoading={loading}>
                Submit
              </PrimaryButton>
            </PanelBottom>
          </PanelGroup>
          {writingSample.validationCheckSummary && (
            <ValidationChecklistPanel {...writingSample.validationCheckSummary} />
          )}
        </Stack>
      </Flex>
    </>
  );
}
