import { FormControl, FormLabel } from "@chakra-ui/react";

import { CustomMultiSelect } from "../../components/custom-selector";
import { TASK_STATUS } from "../../constants";

interface Props {
  handleStatusIdsChange: (statusIds: string[]) => void;
  statusIds: string[];
}

export function TaskStatusSelector(props: Props) {
  const { handleStatusIdsChange, statusIds } = props;
  const items = [
    {
      displayName: "Assigned",
      value: TASK_STATUS.ASSIGNED,
    },
    {
      displayName: "Completed",
      value: TASK_STATUS.COMPLETED,
    },
  ];
  return (
    <FormControl>
      <FormLabel fontSize="sm">Status</FormLabel>
      <CustomMultiSelect
        items={items.map((taskStatus) => ({
          displayText: taskStatus.displayName,
          value: taskStatus.value,
        }))}
        onChange={handleStatusIdsChange}
        value={statusIds}
        emptyValueTitle="All"
        showSelectAll={true}
      />
    </FormControl>
  );
}
