import { Stack, FormControl, FormLabel, Input, Textarea } from "@chakra-ui/react";

import { PanelBox } from "../../../../components/panels";
import { TitleMetaDescriptionTaskWorkspace_TaskFragment } from "../../../../generated/graphql";

interface Props {
  task: TitleMetaDescriptionTaskWorkspace_TaskFragment;
}

export function InformationPanel(props: Props) {
  const { task } = props;

  return (
    <PanelBox>
      <Stack spacing={4}>
        <FormControl>
          <FormLabel>Company Name</FormLabel>
          <Input variant="flushed" isDisabled value={task.customer.name} />
        </FormControl>
        <FormControl>
          <FormLabel>Website URL</FormLabel>
          <Input variant="flushed" isDisabled value={task.customer.website ?? "Not provided"} />
        </FormControl>
        <FormControl>
          <FormLabel>Order Instructions</FormLabel>
          <Textarea
            variant="flushed"
            isDisabled
            value={task.order.instructions ?? "Not provided"}
            resize="none"
            rows={7}
            overflowY="scroll"
            scrollBehavior="smooth"
            overscrollBehaviorY="contain"
          />
        </FormControl>
      </Stack>
    </PanelBox>
  );
}
