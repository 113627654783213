import { useEffect, useState } from "react";
import { Heading, Stack, Alert, FormControl, FormLabel, Input, useToast, Box, Switch } from "@chakra-ui/react";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { PanelBottom, PanelGroup, PanelTop } from "../../components/panels";
import { TeamMemberProfileQueryResult, useUpdateTeamMemberProfileMutation } from "../../generated/graphql";
import AppIcon from "../../components/app-icon";
import { Loader } from "../../components/loader";
import { Permissions } from "./permissions";
import { LOADER_TYPE } from "../../constants";
import { PrimaryButton } from "../../components/buttons";
import AppLink from "../../components/app-link";

interface Props {
  queryResult: TeamMemberProfileQueryResult;
}

export function Account({ queryResult }: Props) {
  const { loading, error, data } = queryResult;
  const { creativesScaleUser } = data ?? {};

  const toast = useToast();

  const productTaskTypes = data?.productTaskTypes.nodes ?? [];
  const userTaskPermissions = data?.creativesScaleUser?.user.userTaskPermissions ?? [];
  const resumeFile = data?.creativesScaleUser?.user?.userProfessional?.resumeFile;
  const [updateTeamMember, { loading: updateLoading }] = useUpdateTeamMemberProfileMutation();

  const [writerClassification, setWriterClassification] = useState(creativesScaleUser?.writerClassification ?? "");
  const [isRCMWriter, setIsRCMWriter] = useState(creativesScaleUser?.isRCMWriter ?? false);

  useEffect(() => {
    if (creativesScaleUser?.writerClassification) {
      setWriterClassification(creativesScaleUser.writerClassification);
    }

    if (creativesScaleUser?.isRCMWriter !== undefined) {
      setIsRCMWriter(creativesScaleUser.isRCMWriter);
    }
  }, [creativesScaleUser?.writerClassification, creativesScaleUser?.isRCMWriter]);

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    setWriterClassification(event.target.value);
  }

  function onRCMWriterChange() {
    setIsRCMWriter(!isRCMWriter);
  }

  async function onSave() {
    const response = await updateTeamMember({
      variables: {
        input: {
          id: creativesScaleUser?.id ?? 0,
          writerClassification,
          isRCMWriter,
        },
      },
    });

    if (response.data?.updateCreativesScaleUser.ok) {
      toast({
        title: "Team member updated successfully!",
        status: "success",
      });
    }
  }

  return (
    <PanelGroup>
      <PanelTop showBottomBorder>
        <Heading size="md" textAlign="left">
          Account
        </Heading>
      </PanelTop>
      <PanelBottom>
        {loading && <Loader type={LOADER_TYPE.content} />}
        {error && (
          <Alert status="error">
            <AppIcon icon={faExclamationTriangle} wideRightMargin />
            An error occurred while retrieving profile.
          </Alert>
        )}
        {!loading && !error && !!creativesScaleUser && (
          <Stack spacing={4}>
            <FormControl isReadOnly>
              <FormLabel>First Name</FormLabel>
              <Input variant="flushed" value={creativesScaleUser?.user.firstName ?? ""} isDisabled />
            </FormControl>
            <FormControl isReadOnly>
              <FormLabel>Last Name</FormLabel>
              <Input variant="flushed" value={creativesScaleUser?.user.lastName ?? ""} isDisabled />
            </FormControl>
            <FormControl isReadOnly>
              <FormLabel>Email</FormLabel>
              <Input variant="flushed" value={creativesScaleUser?.user.email ?? ""} isDisabled />
            </FormControl>
            <FormControl>
              <FormLabel>Is RCM Writer</FormLabel>
              <Switch isChecked={isRCMWriter} onChange={onRCMWriterChange} />
            </FormControl>
            <FormControl isReadOnly>
              <FormLabel>LinkedIn Profile URL</FormLabel>
              <Input
                value={creativesScaleUser?.user?.userProfessional?.linkedInProfileUrl ?? ""}
                variant="flushed"
                isDisabled
              />
            </FormControl>
            {resumeFile && (
              <FormControl>
                <FormLabel>
                  <Box>Resume</Box>
                  <AppLink isExternal to={resumeFile?.url} target="_blank">
                    {resumeFile?.name ?? "-"}
                  </AppLink>
                </FormLabel>
              </FormControl>
            )}
            <FormControl isReadOnly>
              <FormLabel>Average Start Rating</FormLabel>
              <Input value={creativesScaleUser?.avgStarRating ?? 0} variant="flushed" isDisabled />
            </FormControl>
            <Permissions
              onPermissionsUpdated={() => {
                queryResult.refetch();
              }}
              productTaskTypes={productTaskTypes}
              userId={queryResult.data?.creativesScaleUser?.user.id ?? 0}
              userTaskPermissions={userTaskPermissions}
            />
            <FormControl mt={4}>
              <FormLabel>Writer Classification</FormLabel>
              <Input variant="flushed" value={writerClassification} onChange={handleChange} />
            </FormControl>
            <PrimaryButton onClick={onSave} isDisabled={updateLoading}>
              Save
            </PrimaryButton>
          </Stack>
        )}
      </PanelBottom>
    </PanelGroup>
  );
}
