import { useParams } from "react-router-dom";
import { Global } from "@emotion/react";
import { Box, SimpleGrid } from "@chakra-ui/react";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { useTeamMemberProfileQuery } from "../../generated/graphql";
import { AppColors } from "../../core/custom-theme";
import { ContentContainer } from "../../components/content-container";
import { PageHeading } from "../../components/page-heading";
import { MainNav } from "../../components/main-nav";
import AppLink from "../../components/app-link";
import AppIcon from "../../components/app-icon";
import { Account } from "./account";
import { OnboardingStatus } from "./onboarding-status";
import { Tasks } from "./tasks";

export function AdminTeamMemberProfileRoute() {
  const { id } = useParams<"id">();
  const teamMemberId = id ? parseInt(id, 10) : 0;
  const queryResult = useTeamMemberProfileQuery({
    variables: {
      id: teamMemberId,
    },
    fetchPolicy: "network-only",
  });

  return (
    <>
      <Global styles={{ body: { backgroundColor: AppColors.body } }} />
      <MainNav />
      <ContentContainer centerHorizontally>
        <Box marginY={4}>
          <AppLink to="/admin/team-members">
            <AppIcon icon={faAngleLeft} standardRightMargin />
            Team Members
          </AppLink>
        </Box>
        <PageHeading>Profile</PageHeading>
        <SimpleGrid columns={{ base: 1, lg: 2 }} gridGap={4}>
          <Box>
            <Account queryResult={queryResult} />
          </Box>
          <Box>
            <OnboardingStatus queryResult={queryResult} />
            <Tasks teamMemberId={teamMemberId} />
          </Box>
        </SimpleGrid>
      </ContentContainer>
    </>
  );
}
