import { useContext, useEffect } from "react";
import { Loader } from "../../components/loader";
import { AuthContext } from "../../core/auth-manager";
import { useNavigate } from "react-router-dom";
import { LOADER_TYPE } from "../../constants";

export function LogoutRoute() {
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    (async function handleLogout() {
      await authContext.logout();
      navigate("/");
    })();
  }, [authContext, navigate]);

  return <Loader type={LOADER_TYPE.fullView} message="Logging out..." />;
}
