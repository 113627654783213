import qs from "qs";
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { QS_PARSE_OPTIONS, QS_STRINGIFY_OPTIONS } from "./common";

function getSearchParams(search: string) {
  return qs.parse(search, QS_PARSE_OPTIONS);
}

export function useQueryParamInt(name: string): [number | undefined, (value: number | undefined) => void] {
  const navigate = useNavigate();
  const location = useLocation();
  const parsed = qs.parse(location.search, { ignoreQueryPrefix: true });
  const qsValue = parsed[name];
  const [value, setValue] = useState<number | undefined>(qsValue ? parseInt(qsValue as string) : undefined);

  function handleSetValue(newValue?: number) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { [name]: current, ...rest } = getSearchParams(location.search);
    if (newValue !== undefined) {
      const newParams = qs.stringify({ ...rest, [name]: newValue }, QS_STRINGIFY_OPTIONS);
      navigate({ search: newParams });
    } else {
      const newParams = qs.stringify(rest, QS_STRINGIFY_OPTIONS);
      navigate({ search: newParams });
    }
  }

  useEffect(() => {
    const calculatedValue = qsValue ? parseInt(qsValue as string) : undefined;
    if (calculatedValue !== value) {
      setValue(calculatedValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [qsValue]);

  return [value, handleSetValue];
}
