import { Global } from "@emotion/react";
import { AppColors } from "../../core/custom-theme";
import Header from "../../components/header";
import { ContentContainer } from "../../components/content-container";
import { Text, Box, Flex, Image, Stack } from "@chakra-ui/react";
import NoEntrySVG from "../../assets/no-entry.svg";
import { PrimaryButton } from "../../components/buttons";
import { useNavigate } from "react-router-dom";

export function GrammarTestFail() {
  const navigate = useNavigate();

  return (
    <>
      <Global styles={{ body: { backgroundColor: AppColors.body } }} />
      <Header />
      <ContentContainer centerHorizontally>
        <Stack spacing={8} marginTop={16}>
          <Flex justifyContent="center">
            <Image src={NoEntrySVG} alt="failed-test" />
          </Flex>
          <Text as="h2" fontSize="4xl" textAlign="center">
            Oh No!
          </Text>
          <Text textAlign="center">
            {`We're sad to say that you did not pass our grammar test. You will be able to try again in 7 days. We hope to see
        you again soon!`}
          </Text>
          <Box textAlign="center">
            <PrimaryButton
              onClick={() => {
                navigate("/");
              }}
              paddingX={24}
            >
              Go to Journey
            </PrimaryButton>
          </Box>
        </Stack>
      </ContentContainer>
    </>
  );
}
