import { Link, LinkProps } from "@chakra-ui/react";
import Color from "color";
import { AppColors } from "../../core/custom-theme";

export function ExternalLink(props: LinkProps) {
  return (
    <Link
      isExternal
      {...props}
      color={AppColors.blue}
      _hover={{ textDecoration: "none", color: Color(AppColors.blue).lighten(0.2).hex() }}
    />
  );
}
