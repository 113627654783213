import { useUserSelectorV2Query } from "../../generated/graphql";

interface Options {
  onUserSelected: (userId: number | null) => void;
  showAll?: boolean;
  isAdminOrSuperAdmin?: boolean;
}

export function useUserSelectorV2(options: Options) {
  const { showAll, onUserSelected } = options;

  const userSelectorQueryResult = useUserSelectorV2Query({
    fetchPolicy: "network-only",
  });

  const creativeScaleUsers = userSelectorQueryResult.data?.creativesScaleUsers?.nodes ?? [];
  const userOptions = creativeScaleUsers.map((creativeScaleUser) => {
    return {
      value: `${creativeScaleUser.user.id}`,
      displayText: creativeScaleUser.user.fullName,
    };
  });

  if (showAll) {
    userOptions.unshift({
      value: "",
      displayText: "All",
    });
  }

  function onUserChange(value: string | null) {
    if (value) {
      onUserSelected(parseInt(value, 10));
    } else {
      onUserSelected(null);
    }
  }

  return {
    loading: userSelectorQueryResult.loading,
    userOptions,
    onUserChange,
  };
}
