import { Badge, Box, Flex, FormControl, FormLabel, Grid, Stack, Text } from "@chakra-ui/react";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { PrimaryIconButton, PrimaryLinkButton } from "../../../../components/buttons";
import { FormattedDate } from "../../../../components/formatted-date";
import { DATE_FORMAT } from "../../../../constants/date-formats";
import { AppColors } from "../../../../core/custom-theme";
import { ContentStrategyV2_OrderContentStrategyFragment } from "../../../../generated/graphql";
import { ContentStrategyForm } from "./content-strategy-form";
import { useContentStrategyItem } from "./content-strategy-item-hook";
import { ContentStrategyItemWrapper } from "./content-strategy-item-wrapper";

interface Props {
  orderContentStrategy: ContentStrategyV2_OrderContentStrategyFragment;
  workspaceRefetch: () => void;
}

export function ContentStrategyItem(props: Props) {
  const { orderContentStrategy } = props;
  const { isComplete, isEditing, onEdit, onStopEdit, reviews, unresolvedRevisionRequests, resolveReview } =
    useContentStrategyItem(props);

  return (
    <ContentStrategyItemWrapper isRevisionRequested={unresolvedRevisionRequests.length > 0}>
      <Grid gridTemplateColumns="1fr 100px">
        <Box>
          {unresolvedRevisionRequests.length > 0 &&
            reviews.map((review) => (
              <FormControl
                key={review.id}
                marginBottom={4}
                borderLeft={`10px solid ${review.resolvedAt ? AppColors.info : AppColors.error}`}
                paddingLeft={2}
              >
                <FormLabel>
                  Client Comments (<FormattedDate format={DATE_FORMAT} date={review.createdAt} />)
                </FormLabel>
                <Stack spacing={1}>
                  <Text>{review.comments}</Text>
                  {!review.resolvedAt && (
                    <Box>
                      <PrimaryLinkButton
                        onClick={() => {
                          resolveReview(review.id);
                        }}
                      >
                        Mark as Resolved
                      </PrimaryLinkButton>
                    </Box>
                  )}
                  {review.resolvedAt && (
                    <Text fontSize="sm">
                      Resolved on <FormattedDate format={DATE_FORMAT} date={review.resolvedAt} />
                    </Text>
                  )}
                </Stack>
              </FormControl>
            ))}
          <ContentStrategyForm
            isEditing={isEditing}
            onStopEditing={onStopEdit}
            orderContentStrategy={orderContentStrategy}
          />
        </Box>
        <Flex justifyContent="space-between" alignItems="flex-end" flexDirection="column">
          {isComplete && (
            <Box>
              <Badge colorScheme="green">Complete</Badge>
            </Box>
          )}
          {!isComplete && (
            <Box>
              <Badge colorScheme="red">Incomplete</Badge>
            </Box>
          )}
          {!isEditing && <PrimaryIconButton actionName="Edit" icon={faPencilAlt} onClick={onEdit} />}
        </Flex>
      </Grid>
    </ContentStrategyItemWrapper>
  );
}
