import qs from "qs";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { QS_PARSE_OPTIONS, QS_STRINGIFY_OPTIONS } from "./common";

function getSearchParams() {
  return qs.parse(location.search, QS_PARSE_OPTIONS);
}

export function useQueryParamString(name: string): [string | undefined, (value: string | undefined) => void] {
  const navigate = useNavigate();
  const parsed = getSearchParams();
  const qsValue = parsed[name];
  const [value, setValue] = useState<string | undefined>(qsValue as string | undefined);

  function handleSetValue(newValue?: string) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { [name]: current, ...rest } = getSearchParams();
    if (newValue !== undefined) {
      const newParams = qs.stringify({ ...rest, [name]: newValue }, QS_STRINGIFY_OPTIONS);
      navigate({ search: newParams });
    } else {
      const newParams = qs.stringify(rest, QS_STRINGIFY_OPTIONS);
      navigate({ search: newParams });
    }
  }

  useEffect(() => {
    const calculatedValue = qsValue as string | undefined;
    if (calculatedValue !== value) {
      setValue(calculatedValue);
    }
  }, [qsValue, value]);

  return [parsed[name] as string | undefined, handleSetValue];
}
