import { DateTime } from "luxon";
import {
  Box,
  Flex,
  Image,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Stack,
  Tooltip,
  Text,
} from "@chakra-ui/react";
import { ArrowUpDownIcon } from "@chakra-ui/icons";

import FilterSVG from "../../../../assets/filter.svg";

import {
  Table,
  TableBody,
  TableCell,
  TableFoot,
  TableHead,
  TableHeaderCell,
  TablePagination,
  TableRow,
} from "../../../../components/table";
import { PanelBottom, PanelGroup, PanelTop } from "../../../../components/panels";
import SearchBox from "../../../../components/search-box";
import { TruncatedText } from "../../../../components/truncated-text";
import { Container } from "../../../../components/layout/container";
import { DangerButton, SecondaryButton } from "../../../../components/buttons";

import { DATE_FORMAT, DATE_TIME_FORMAT } from "../../../../constants/date-formats";
import { PAGE_LIMIT } from "../../../../constants/pagination";
import { OrdersSort } from "../../../../generated/graphql";

import { OrderStatusIndicator } from "../../../../common/order-status-indicator/order-status-indicator";
import { OrderIssuesIndicator } from "../../../../common/order-issues-indicator/order-issues-indicator";

import { useContentStrategyV2Roadmap } from "./content-strategy-v2-roadmap-hook";

interface Props {
  customerId: number;
}

export function ContentStrategyRoadmap({ customerId }: Props) {
  const {
    onSearchChange,
    onSortChange,
    onOrderStatusSelected,
    onNextPage,
    onPreviousPage,
    clearFilters,
    totalCount,
    orders,
    sort,
    ordersQueryResult,
    statusIds,
    orderStatusOptions,
  } = useContentStrategyV2Roadmap({ customerId });

  const orderStatusMenuItemOptions = orderStatusOptions?.map((status) => (
    <MenuItemOption key={status.value} value={status.value}>
      {status.label}
    </MenuItemOption>
  ));

  return (
    <Container centerHorizontally>
      <PanelGroup>
        <PanelTop showBottomBorder>
          <Flex justifyContent="space-between">
            <Box>
              <SearchBox onChange={onSearchChange} placeholder="Search" />
            </Box>
            <Stack isInline spacing={4} shouldWrapChildren>
              <Menu>
                <MenuButton as={SecondaryButton}>
                  Sort <ArrowUpDownIcon ml={2} />
                </MenuButton>
                <MenuList>
                  <MenuOptionGroup title="Start Date" type="radio" value={sort} onChange={onSortChange}>
                    <MenuItemOption value={OrdersSort.StartedAtDesc}>Newest First</MenuItemOption>
                    <MenuItemOption value={OrdersSort.StartedAtAsc}>Oldest First</MenuItemOption>
                  </MenuOptionGroup>
                </MenuList>
              </Menu>
              <Stack spacing={4} isInline>
                <Menu isLazy>
                  <MenuButton as={SecondaryButton}>
                    <Stack isInline>
                      <Text>Filters</Text>
                      <Image src={FilterSVG} ml={2} />
                    </Stack>
                  </MenuButton>
                  <MenuList>
                    <MenuOptionGroup
                      title="Order Status Id"
                      type="radio"
                      value={statusIds && statusIds.length > 0 ? statusIds[0] : undefined}
                      onChange={onOrderStatusSelected}
                    >
                      {orderStatusMenuItemOptions}
                    </MenuOptionGroup>
                  </MenuList>
                </Menu>
                {statusIds.length > 0 && <DangerButton onClick={clearFilters}>Clear Filters</DangerButton>}
              </Stack>
            </Stack>
          </Flex>
        </PanelTop>
        <PanelBottom containsTable>
          <Table isEmpty={orders.length === 0} isLoading={ordersQueryResult.loading}>
            <TableHead>
              <TableRow key="head">
                <TableHeaderCell>Product Type</TableHeaderCell>
                <TableHeaderCell>Topic</TableHeaderCell>
                <TableHeaderCell>Primary Keyword</TableHeaderCell>
                <TableHeaderCell>Status</TableHeaderCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orders.map((order) => {
                const startDateTimeStr = DateTime.fromJSDate(order.startDate).toFormat(DATE_TIME_FORMAT);
                const startDateMonthStr = DateTime.fromJSDate(order.startDate).toFormat(DATE_FORMAT);

                return (
                  <TableRow key={order.id}>
                    <TableCell>
                      <Stack spacing={0}>
                        {order.partnerProduct.id ? <Text>{order.partnerProduct.name}</Text> : "-"}
                        <Box>
                          <Tooltip label={startDateTimeStr} aria-label={startDateTimeStr}>
                            <span style={{ fontWeight: 600 }}>{startDateMonthStr}</span>
                          </Tooltip>
                        </Box>
                      </Stack>
                    </TableCell>
                    <TableCell>
                      <TruncatedText maxLength={30}>{order.topic ?? "-"}</TruncatedText>
                    </TableCell>
                    <TableCell>
                      <TruncatedText maxLength={30}>{order.customerKeyword?.name ?? "-"}</TruncatedText>
                    </TableCell>
                    <TableCell>
                      <OrderStatusIndicator status={order.status} startDate={order.startDate} />
                      <OrderIssuesIndicator orderIssues={order.orderIssues} />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
            <TableFoot>
              <TablePagination
                totalCount={totalCount}
                offset={ordersQueryResult.variables?.offset ?? 0}
                limit={ordersQueryResult.variables?.limit ?? PAGE_LIMIT}
                onPreviousPage={onPreviousPage}
                onNextPage={onNextPage}
              />
            </TableFoot>
          </Table>
        </PanelBottom>
      </PanelGroup>
    </Container>
  );
}
