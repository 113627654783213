import { Text, BoxProps, Tooltip } from "@chakra-ui/react";

interface Props extends Omit<BoxProps, "children"> {
  maxLength: number;
  children: string;
}

export function TruncatedText(props: Props) {
  const { children, maxLength, ...rest } = props;

  if (children.length < maxLength) {
    return <Text {...rest}>{children}</Text>;
  } else {
    return (
      <Tooltip hasArrow label={children} aria-label={children}>
        <Text {...rest}>{children.substring(0, maxLength)}&hellip;</Text>
      </Tooltip>
    );
  }
}
