import axios from "axios";

export async function downloadFile(url: string) {
  // eslint-disable-next-line no-useless-catch
  try {
    const response = await axios.get(url, {
      responseType: "blob",
    });
    return response.data;
  } catch (e: any) {
    throw e;
  }
}
