import { Alert, Link, Text, Popover, PopoverTrigger, PopoverContent, PopoverArrow, Box } from "@chakra-ui/react";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import StarRatings from "react-star-ratings";
import parse from "html-react-parser";
import { MyTasksQueryResult, MyTaskFragment } from "../../generated/graphql";
import AppIcon from "../../components/app-icon";
import {
  Table,
  TableHead,
  TableRow,
  TableHeaderCell,
  TableBody,
  TablePagination,
  TableCell,
  TableFoot,
} from "../../components/table";
import { DATE_FORMAT, PAGE_LIMIT, TASK_STATUS, TASK_TYPES } from "../../constants";
import { AppColors } from "../../core/custom-theme";
import { TaskStatusIndicator } from "../../common/task-status-indicator";
import { DateTime } from "luxon";

interface Props {
  queryResult: MyTasksQueryResult;
  onPreviousPage: () => void;
  onNextPage: () => void;
}

export default function MyTaskTable(props: Props) {
  const { queryResult, onNextPage, onPreviousPage } = props;
  const { loading, data, error } = queryResult;

  const totalCount = data?.tasks?.totalCount ?? 0;
  const tasks = data?.tasks?.nodes ?? [];

  if (error) {
    return (
      <Alert status="error">
        <AppIcon icon={faExclamationTriangle} wideRightMargin />
        An error occurred while retrieving the tasks.
      </Alert>
    );
  }

  function renderTaskTypeRow(task: MyTaskFragment) {
    if (task.status.id === TASK_STATUS.ASSIGNED) {
      return (
        <Link href={`/tasks/${task.id}`} color={AppColors.blue}>
          {task.taskType.name}
        </Link>
      );
    }
    if (task.taskType.id === TASK_TYPES.CONTENT && task.status.id === TASK_STATUS.COMPLETED) {
      return (
        <Link href={`/tasks/${task.id}/view`} color={AppColors.blue}>
          {task.taskType.name}
        </Link>
      );
    }
    if (task.status.id !== TASK_STATUS.ASSIGNED) {
      return <Text>{task.taskType.name}</Text>;
    }
  }

  return (
    <Table isLoading={loading} isEmpty={tasks.length === 0}>
      <TableHead>
        <TableRow key="head">
          <TableHeaderCell>Task</TableHeaderCell>
          <TableHeaderCell>Topic</TableHeaderCell>
          <TableHeaderCell>Category</TableHeaderCell>
          <TableHeaderCell>Status</TableHeaderCell>
          <TableHeaderCell>Rating</TableHeaderCell>
          <TableHeaderCell>AI Probability</TableHeaderCell>
          <TableHeaderCell>Completed At</TableHeaderCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {tasks.map((task) => (
          <TableRow key={task.id}>
            <TableCell>
              {renderTaskTypeRow(task)} <Text fontSize="sm">({task.product.name})</Text>
            </TableCell>
            <TableCell>{task.order.topic}</TableCell>
            <TableCell>{task.category?.name ?? "-"}</TableCell>
            <TableCell>
              <TaskStatusIndicator csTaskStatus={task.creativesScaleTaskStatus} />
            </TableCell>
            <TableCell>
              {task.contentItem?.feedbackContentItems?.[0] ? (
                <Popover trigger="hover">
                  <PopoverTrigger>
                    <span>
                      <StarRatings
                        rating={task.contentItem.feedbackContentItems[0].rating}
                        starRatedColor={AppColors.blue}
                        starDimension="20px"
                        starSpacing="2px"
                      />
                    </span>
                  </PopoverTrigger>
                  <PopoverContent maxW={200} zIndex={500}>
                    <PopoverArrow bg={AppColors.textNormal} />
                    <Box backgroundColor={AppColors.textNormal} color={AppColors.white} p={2} maxW={200} fontSize="sm">
                      {parse(task.contentItem.feedbackContentItems[0].feedback ?? "-")}
                    </Box>
                  </PopoverContent>
                </Popover>
              ) : (
                "-"
              )}
            </TableCell>
            <TableCell>
              {task.contentItem?.probability !== null && task.contentItem?.probability !== undefined ? (
                <Text>{Math.round((task.contentItem?.probability ?? 0) * 100 * 100) / 100}%</Text>
              ) : (
                "-"
              )}
            </TableCell>
            <TableCell>
              {task.completedAt ? (
                <Text fontSize="sm">{DateTime.fromJSDate(task.completedAt).toFormat(DATE_FORMAT)}</Text>
              ) : (
                "-"
              )}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
      <TableFoot>
        <TablePagination
          totalCount={totalCount}
          limit={queryResult.variables?.limit ?? PAGE_LIMIT}
          offset={queryResult.variables?.offset ?? 0}
          onNextPage={onNextPage}
          onPreviousPage={onPreviousPage}
        />
      </TableFoot>
    </Table>
  );
}
