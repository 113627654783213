import { useContext, useState } from "react";
import { Box, Text, Image, Flex } from "@chakra-ui/react";
import { Global } from "@emotion/react";

import Logo from "../../assets/creatives-scale-logo-color.png";
import { AppColors } from "../../core/custom-theme";
import { PrimaryButton } from "../../components/buttons";
import { useResendConfirmationEmailMutation } from "../../generated/graphql";
import { AuthContext } from "../../core/auth-manager";
import { ONBOARDING_STATUS } from "../../constants";
import { Navigate } from "react-router-dom";

export function OnboardingEmailConfirmationNeededRoute() {
  const [wasResent, setWasResent] = useState(false);
  const [resendConfirmationEmail] = useResendConfirmationEmailMutation();
  const authContext = useContext(AuthContext);

  if (
    !authContext.isLoading &&
    authContext.viewer?.creativesScaleUser?.onboardingStatusId !== ONBOARDING_STATUS.WAITING_FOR_EMAIL_CONFIRM.id
  ) {
    return <Navigate to="/" />;
  }

  async function resendEmail() {
    await resendConfirmationEmail({
      variables: {
        input: {
          creativesScaleUserId: authContext.viewer?.creativesScaleUser?.id,
        },
      },
    });
    setWasResent(true);
  }
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems={{ base: "flex-start", lg: "center" }}
      height="100vh"
      paddingTop={4}
    >
      <Global styles={{ body: { backgroundColor: AppColors.body } }} />
      <Box p={4} mt={1} width={{ base: 350, lg: 675 }} borderRadius={4}>
        <Flex justifyContent="center" marginBottom={4}>
          <Image src={Logo} alt="Creatives Scale" marginY={12} width="250px" height="100px" objectFit="contain" />
        </Flex>
        <Box marginBottom={2} textAlign="center">
          <Text as="h1" fontSize="4xl" fontWeight="bold">
            Please confirm your email
          </Text>
        </Box>
        <Box textAlign="center">
          {wasResent && (
            <Text>
              We sent you an email with a link to validate your email address. Please click the link in the email to
              continue getting started.
            </Text>
          )}
          {!wasResent && (
            <>
              <Text>
                We need you to confirm your email before we can get started. If you didn&apos;t receive a confirmation
                email, check your spam folder or click the button below to resend the confirmation email.
              </Text>
              <Box marginTop={10}>
                <PrimaryButton onClick={resendEmail}>Resend Confirmation Email</PrimaryButton>
              </Box>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
}
