import { useLocation, useNavigate } from "react-router-dom";
import { Global } from "@emotion/react";
import queryString from "qs";
import { Text, Box } from "@chakra-ui/react";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { TaskHistoryFragment, useTasksHistoryQuery, TasksSort } from "../../generated/graphql";
import { AppColors } from "../../core/custom-theme";
import { ContentContainer } from "../../components/content-container";
import TasksTable from "./tasks-table";
import { PanelGroup, PanelTop, PanelBottom } from "../../components/panels";
import { PageHeading } from "../../components/page-heading";
import { MainNav } from "../../components/main-nav";
import AppLink from "../../components/app-link";
import AppIcon from "../../components/app-icon";
import { getQueryParams, setParams } from "../../util/query-param-helper";
import { CS_VENDOR_ID, PAGE_LIMIT, TASK_STATUS } from "../../constants";

interface Props {
  teamMemberId: number;
  userId: number;
}

export function TeamMemberTasksHistory({ teamMemberId, userId }: Props) {
  const location = useLocation();
  const navigate = useNavigate();

  const queryParams = getQueryParams(location.search, queryString) || {};
  const currentPageOffset = queryParams?.currentOffset ? parseInt(queryParams.currentOffset, 10) : 0;
  const historyPageOffset = queryParams?.historyOffset ? parseInt(queryParams.historyOffset, 10) : 0;

  const queryResult = useTasksHistoryQuery({
    variables: {
      assignedUserIds: [userId],
      vendorIds: [CS_VENDOR_ID],
      currentStatusIds: [TASK_STATUS.ASSIGNED],
      historyStatusIds: [TASK_STATUS.COMPLETED],
      currentLimit: PAGE_LIMIT,
      currentOffset: currentPageOffset,
      currentSort: TasksSort.IdDesc,
      historyLimit: PAGE_LIMIT,
      historyOffset: historyPageOffset,
      historySort: TasksSort.CompletedAtDesc,
    },
    fetchPolicy: "network-only",
  });

  const { data, loading, error } = queryResult;

  const currentTasks: TaskHistoryFragment[] = data?.currentTasks.nodes ?? [];
  const historyTasks: any[] = data?.historyTasks?.nodes ?? [];

  function onCurrentNextPage() {
    const { currentOffset, ...remainingQueryParams } = queryParams;
    const newOffset = parseInt(currentOffset ?? "0", 10) + PAGE_LIMIT;
    const params = setParams(remainingQueryParams, "currentOffset", newOffset.toString());
    navigate({ search: `?${params}` });
  }

  function onCurrentPreviousPage() {
    const { currentOffset, ...remainingQueryParams } = queryParams;
    const newOffset = Math.max(parseInt(currentOffset ?? "0", 10) - PAGE_LIMIT, 0);
    const params = setParams(remainingQueryParams, "currentOffset", newOffset.toString());
    navigate({ search: `?${params}` });
  }

  function onHistoryNextPage() {
    const { historyOffset, ...remainingQueryParams } = queryParams;
    const newOffset = parseInt(historyOffset ?? "0", 10) + PAGE_LIMIT;
    const params = setParams(remainingQueryParams, "historyOffset", newOffset.toString());
    navigate({ search: `?${params}` });
  }

  function onHistoryPreviousPage() {
    const { historyOffset, ...remainingQueryParams } = queryParams;
    const newOffset = Math.max(parseInt(historyOffset ?? "0", 10) - PAGE_LIMIT, 0);
    const params = setParams(remainingQueryParams, "historyOffset", newOffset.toString());
    navigate({ search: `?${params}` });
  }

  // TODO: Implement search, sort and filter
  return (
    <>
      <Global styles={{ body: { backgroundColor: AppColors.body } }} />
      <MainNav />
      <ContentContainer centerHorizontally>
        <Box marginY={4}>
          <AppLink to={`/admin/team-members`}>Team Members</AppLink>
          <AppIcon icon={faAngleRight} standardLeftMargin standardRightMargin />
          <AppLink to={`/admin/team-members/${teamMemberId}`}>Profile</AppLink>
        </Box>
        <PageHeading>Team Member Tasks</PageHeading>
        <PanelGroup>
          <PanelTop showBottomBorder>
            <Text fontWeight="bold">Current Tasks</Text>
          </PanelTop>
          <PanelBottom containsTable>
            <TasksTable
              tasks={currentTasks}
              loading={loading}
              error={error}
              limit={PAGE_LIMIT}
              totalCount={data?.currentTasks.totalCount ?? 0}
              offset={currentPageOffset}
              onNextPage={onCurrentNextPage}
              onPreviousPage={onCurrentPreviousPage}
            />
          </PanelBottom>
        </PanelGroup>
        <PanelGroup>
          <PanelTop showBottomBorder>
            <Text fontWeight="bold">Task History</Text>
          </PanelTop>
          <PanelBottom containsTable>
            <TasksTable
              tasks={historyTasks}
              loading={loading}
              error={error}
              limit={PAGE_LIMIT}
              offset={historyPageOffset}
              totalCount={data?.historyTasks?.totalCount ?? 0}
              onPreviousPage={onHistoryPreviousPage}
              onNextPage={onHistoryNextPage}
            />
          </PanelBottom>
        </PanelGroup>
      </ContentContainer>
    </>
  );
}
