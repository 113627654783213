import * as yup from "yup";
import { Box, useToast, Image, FormControl, FormLabel, Input, FormErrorMessage, Text, Stack } from "@chakra-ui/react";
import { useFormik, FormikHelpers } from "formik";
import { useParams, useNavigate } from "react-router-dom";
import CSLogo from "../../assets/creatives-scale-logo-color.png";
import { getDisplayMessageForError } from "../../util/error-helper";
import { useResetPasswordMutation } from "../../generated/graphql";
import { AppColors } from "../../core/custom-theme";
import { PrimaryButton } from "../../components/buttons";
import { Global } from "@emotion/react";
import { PanelBox } from "../../components/panels";
import AppLink from "../../components/app-link";
import AppIcon from "../../components/app-icon";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";

const resetPasswordSchema = yup.object().shape({
  password: yup.string().label("Password").required(),
  confirmPassword: yup
    .string()
    .label("Confirm Password")
    .required()
    .test("passwords-match", "Passwords must match", function (value) {
      return this.parent.password === value;
    }),
});

interface FormValues {
  password: string;
  confirmPassword: string;
}

export function ResetPasswordRoute() {
  const [resetPassword] = useResetPasswordMutation();
  const toast = useToast();
  const navigate = useNavigate();
  const { id } = useParams<"id">();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async function handleFormSubmit(values: FormValues, _formikHelpers: FormikHelpers<FormValues>) {
    console.log(values);
    try {
      const response = await resetPassword({
        variables: {
          input: {
            id: id ?? "",
            password: values.password,
          },
        },
      });
      if (response?.data?.resetPassword?.wasReset) {
        toast({
          title: "Password Set",
          description: "Your password was set successfully.",
          status: "success",
        });
        navigate("/login");
      } else {
        throw new Error("There was a problem resetting password. Please try again.");
      }
    } catch (e: any) {
      toast({ title: "Reset Password", description: getDisplayMessageForError(e), status: "error" });
    }
  }

  const formik = useFormik<FormValues>({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validateOnBlur: true,
    validationSchema: resetPasswordSchema,
    onSubmit: handleFormSubmit,
  });

  return (
    <>
      <Global styles={{ body: { backgroundColor: AppColors.body } }} />
      <Box
        width="100%"
        display="flex"
        justifyContent="center"
        paddingBottom={10}
        alignItems={{ base: "flex-start", lg: "center" }}
        height="100vh"
      >
        <Box width="400px">
          <Box justifyContent="center" display="flex">
            <Image src={CSLogo} alt="Creatives Scale" marginY={8} width="250px" height="100px" objectFit="contain" />
          </Box>
          <PanelBox>
            <Stack spacing={6}>
              <AppLink to="/login" fontSize="sm">
                <AppIcon icon={faChevronLeft} standardRightMargin />
                Go to Login
              </AppLink>
              <Text fontWeight={600} color={AppColors.textNormal} fontSize={24} mb={8}>
                Password Reset
              </Text>
              <form onSubmit={formik.handleSubmit} noValidate={true}>
                <FormControl isInvalid={!!formik.errors.password && formik.touched.password} mb={2}>
                  <FormLabel fontSize={14} fontWeight="normal">
                    New Password
                  </FormLabel>
                  <Input
                    type="password"
                    id="password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    autoFocus
                  />
                  {formik.touched.password && formik.errors.password && (
                    <FormErrorMessage>{formik.errors.password}</FormErrorMessage>
                  )}
                </FormControl>
                <FormControl isInvalid={!!formik.errors.confirmPassword && formik.touched.confirmPassword}>
                  <FormLabel fontSize={14} fontWeight="normal">
                    Confirm New Password
                  </FormLabel>
                  <Input
                    type="password"
                    id="confirmPassword"
                    value={formik.values.confirmPassword}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.confirmPassword && formik.errors.confirmPassword && (
                    <FormErrorMessage>{formik.errors.confirmPassword}</FormErrorMessage>
                  )}
                </FormControl>
                <Box mt={8}>
                  <PrimaryButton
                    width="100%"
                    bg={AppColors.textNormal}
                    _hover={{ bg: AppColors.textNormal }}
                    _active={{ bg: AppColors.textNormal }}
                    type="submit"
                    isDisabled={formik.isSubmitting}
                    isLoading={formik.isSubmitting}
                    loadingText="Resetting Password..."
                  >
                    Set Password
                  </PrimaryButton>
                </Box>
              </form>
            </Stack>
          </PanelBox>
        </Box>
      </Box>
    </>
  );
}
