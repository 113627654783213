import { useState } from "react";
import { Comment_ReviewTaskFragment } from "../../generated/graphql";
import { PanelGroup, PanelTop, PanelBottom, PanelMiddle } from "../../components/panels";
import { Heading, Stack, Text, Flex, Box, Collapse } from "@chakra-ui/react";
import { RichContent } from "../../components/rich-content";
import { AppColors } from "../../core/custom-theme";
import AppIcon from "../../components/app-icon";
import { faChevronDown, faChevronUp, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { PrimaryLinkButton } from "../../components/buttons/primary-link-button";
import { TASK_TYPES } from "../../constants";

interface Props {
  reviewTasks: Comment_ReviewTaskFragment[];
}

interface ReviewCommentProps {
  id: number;
  taskTypeId: string;
  reviewComment?: string | null;
}

function ReviewComment(props: ReviewCommentProps) {
  const { taskTypeId, reviewComment } = props;
  if (!reviewComment) {
    return null;
  }

  const author = taskTypeId === TASK_TYPES.CONTENT_REVIEW ? "QA Comments" : "Client Comments";
  return (
    <Stack spacing={2}>
      <Text fontSize="sm" fontWeight="bold" color={AppColors.textDarkGray}>
        {author}
      </Text>
      <RichContent content={reviewComment} />
    </Stack>
  );
}

export function RevisionPanel(props: Props) {
  const { reviewTasks } = props;
  const [isCollapsed, setIsCollapsed] = useState(true);

  if (reviewTasks.length === 0) {
    return null;
  }

  function toggleCollapse() {
    setIsCollapsed(!isCollapsed);
  }

  const [firstReviewTask, ...olderReviewTasks] = reviewTasks;

  return (
    <PanelGroup status="error">
      <PanelTop>
        <Heading size="md">
          <AppIcon icon={faExclamationTriangle} standardRightMargin />
          Revision Requested
        </Heading>
      </PanelTop>
      {olderReviewTasks.length > 0 && (
        <>
          <PanelMiddle showBottomBorder>
            <ReviewComment {...firstReviewTask} />
          </PanelMiddle>
          <PanelBottom>
            <Flex justifyContent="space-between" alignItems="center">
              <Heading size="md">Revision History</Heading>
              <Box>
                {isCollapsed && (
                  <>
                    <PrimaryLinkButton onClick={toggleCollapse}>
                      View All
                      <AppIcon icon={faChevronDown} standardLeftMargin />
                    </PrimaryLinkButton>
                  </>
                )}
                {!isCollapsed && (
                  <>
                    <PrimaryLinkButton onClick={toggleCollapse}>
                      Hide All
                      <AppIcon icon={faChevronUp} standardLeftMargin />
                    </PrimaryLinkButton>
                  </>
                )}
              </Box>
            </Flex>
            <Collapse in={!isCollapsed}>
              <Stack spacing={8} shouldWrapChildren marginTop={8}>
                {olderReviewTasks.map((reviewTask) => (
                  <ReviewComment key={reviewTask.id} {...reviewTask} />
                ))}
              </Stack>
            </Collapse>
          </PanelBottom>
        </>
      )}
      {olderReviewTasks.length === 0 && (
        <PanelBottom>
          <ReviewComment {...firstReviewTask} />
        </PanelBottom>
      )}
    </PanelGroup>
  );
}
