import { DateTime } from "luxon";
import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Stack,
  Stat,
  StatLabel,
  StatNumber,
  Text,
} from "@chakra-ui/react";

import { TaskStatusIndicator } from "../../common/task-status-indicator";
import { LayoutBase } from "../../components/layout/layout-base";
import { PageHeading } from "../../components/page-heading";
import { PanelBottom, PanelGroup, PanelTop } from "../../components/panels";
import {
  Table,
  TableBody,
  TableCell,
  TableFoot,
  TableHead,
  TableHeaderCell,
  TablePagination,
  TableRow,
} from "../../components/table";
import { DATE_TIME_FORMAT } from "../../constants";
import { useAdminTasks } from "./admin-tasks-hook";

export function AdminTasksRoute() {
  const { loading, tasks, pagination, totalCount, handleSearchChange, search } = useAdminTasks();
  return (
    <LayoutBase pageTitle="Tasks (Admin)">
      <PageHeading>Tasks</PageHeading>
      <PanelGroup>
        <PanelTop showBottomBorder>
          <Flex justifyContent="space-between">
            <HStack spacing="8px" justify="left" justifyContent="flex-start" alignItems="flex-end" shouldWrapChildren>
              <FormControl>
                <FormLabel fontSize="sm">Search (Task ID)</FormLabel>
                <Input value={search} onChange={handleSearchChange} minW={150} />
              </FormControl>
            </HStack>
            <Box>
              <Stat>
                <StatLabel>Tasks Found</StatLabel>
                <StatNumber>{totalCount}</StatNumber>
              </Stat>
            </Box>
          </Flex>
        </PanelTop>
        <PanelBottom containsTable>
          <Table isEmpty={tasks.length === 0} isLoading={loading}>
            <TableHead>
              <TableRow key="header">
                <TableHeaderCell>ID</TableHeaderCell>
                <TableHeaderCell>Type</TableHeaderCell>
                <TableHeaderCell>Client</TableHeaderCell>
                <TableHeaderCell>Status</TableHeaderCell>
                <TableHeaderCell>Writer</TableHeaderCell>
                <TableHeaderCell>Dates</TableHeaderCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tasks.map((task) => {
                return (
                  <TableRow key={task.id}>
                    <TableCell>{task.id}</TableCell>
                    <TableCell>
                      <Stack spacing={1}>
                        <Text>{task.taskType.name}</Text>
                        <Text fontSize="sm">{task.product.name}</Text>
                      </Stack>
                    </TableCell>
                    <TableCell>
                      <Stack spacing={1}>
                        <Text>{task.customer.name}</Text>
                        <Text fontSize="sm">Order {task.order.id}</Text>
                      </Stack>
                    </TableCell>
                    <TableCell>
                      <TaskStatusIndicator csTaskStatus={task.creativesScaleTaskStatus} />
                    </TableCell>
                    <TableCell>{task.assignedUser?.fullName}</TableCell>
                    <TableCell>
                      <Stack spacing={1}>
                        <Text fontSize="sm">
                          Created: {DateTime.fromJSDate(task.createdAt).toFormat(DATE_TIME_FORMAT)}
                        </Text>
                        <Text fontSize="sm">
                          Available: {DateTime.fromJSDate(task.availableAt).toFormat(DATE_TIME_FORMAT)}
                        </Text>
                        {!!task.assignedAt && (
                          <Text fontSize="sm">
                            Assigned: {DateTime.fromJSDate(task.assignedAt).toFormat(DATE_TIME_FORMAT)}
                          </Text>
                        )}
                        {!!task.completedAt && (
                          <Text fontSize="sm">
                            Completed: {DateTime.fromJSDate(task.completedAt).toFormat(DATE_TIME_FORMAT)}
                          </Text>
                        )}
                      </Stack>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
            <TableFoot>
              <TablePagination {...pagination} totalCount={totalCount} />
            </TableFoot>
          </Table>
        </PanelBottom>
      </PanelGroup>
    </LayoutBase>
  );
}
