import { DateTime } from "luxon";
import { PageHeading } from "../../../../components/page-heading";
import StarRatings from "react-star-ratings";
import Color from "color";
import {
  useToast,
  Grid,
  Stack,
  Input,
  Textarea,
  Flex,
  Box,
  Text,
  Heading,
  Image,
  FormControl,
  FormLabel,
} from "@chakra-ui/react";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { faTimesCircle } from "@fortawesome/free-regular-svg-icons";
import { useContentReview } from "./use-content-review";
import { Loader } from "../../../../components/loader";
import { Navigate } from "react-router-dom";
import { ChecklistPanel } from "../../../../common/checklist-panel";
import { PanelGroup, PanelTop, PanelBottom, PanelMiddle } from "../../../../components/panels";
import { ContentEditor } from "../../../../components/content-editor";
import { SecondaryButton, PrimaryButton, CancelButton } from "../../../../components/buttons";
import { InformationPanel } from "./information-panel";
import { TaskWorkspaceCommonProps } from "../../task-workspace-common-props";
import { ValidationChecklistPanel } from "../../../../components/validation-checklist-panel";
import { RevisionPanel } from "../../../../common/revisions-panel";
import { HOUR_MINUTE_FORMAT, LOADER_TYPE, WEB_COPY_PRODUCTS } from "../../../../constants";
import { AppColors } from "../../../../core/custom-theme";
import AppIcon from "../../../../components/app-icon";
import { UploadFileDialog } from "../../../../common/upload-file-modal";
import { PrimaryIconButton } from "../../../../components/buttons";
import { htmlDecode } from "../../../../util/decode";

export function ContentReview(props: TaskWorkspaceCommonProps) {
  const {
    loading,
    task,
    validationCheckSummary,
    selectedChecklistItems,
    unselectedChecklistItems,
    onChecklistItemSelected,
    formik,
    onReject,
    cancelReject,
    reviewTasks,
    lastAutosavedAt,
    onContentItemUpdate,
    needsAutosave,
    showThumbnailUpload,
    onThumbnailUpload,
    onThumbnailUploadCancel,
    onThumbnailUploaded,
    onThumbnailRemove,
    feedbackContentItemFormik,
    disableReject,
  } = useContentReview(props);
  const toast = useToast();

  if (loading) {
    return <Loader type={LOADER_TYPE.fullView} />;
  }

  if (!task) {
    toast({
      title: "Invalid Task",
      description: "The task specified is invalid. Please try again.",
      status: "warning",
    });
    return <Navigate to="/" />;
  }

  const isWebCopy = WEB_COPY_PRODUCTS.includes(task.order.partnerProduct.product.id);

  return (
    <>
      <UploadFileDialog
        isOpen={showThumbnailUpload}
        onFileUploaded={onThumbnailUploaded}
        onCancel={onThumbnailUploadCancel}
        instructionText="Thumbnail must be a png or jpeg. Recommended size: 600px x 400px or smaller."
      />
      <PageHeading>QA Review Task</PageHeading>
      <Grid gridTemplateColumns="1fr 1.5fr" gridGap={4}>
        <Stack spacing={4}>
          <RevisionPanel reviewTasks={reviewTasks} />
          <ChecklistPanel
            productId={task.order.partnerProduct.product.id}
            selectedItems={selectedChecklistItems}
            onChange={onChecklistItemSelected}
          />
          <InformationPanel task={task} />
        </Stack>
        <Stack spacing={4}>
          <PanelGroup>
            <PanelTop>
              <Input
                size="lg"
                id="title"
                value={formik.values.title}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  formik.handleChange(e);
                  onContentItemUpdate(e.target.value, formik.values.content, formik.values.file);
                }}
              />
            </PanelTop>
            <PanelMiddle>
              <ContentEditor
                initialValue={task.reviewTargetTask?.contentItem?.content ?? ""}
                onChange={(newValue) => {
                  formik.setFieldValue("content", newValue);
                  if (newValue !== formik.values.content) {
                    onContentItemUpdate(formik.values.title, newValue, formik.values.file);
                  }
                }}
                height={500}
                min_height={500}
              />
            </PanelMiddle>
            <PanelMiddle>
              <Stack spacing={4}>
                <FormControl>
                  <FormLabel>Meta Title</FormLabel>
                  <Input value={task.reviewTargetTask?.contentItem?.metaTitle ?? ""} isReadOnly />
                </FormControl>
                <FormControl>
                  <FormLabel>Meta Description</FormLabel>
                  <Textarea
                    value={htmlDecode(task.reviewTargetTask?.contentItem?.metaDescription ?? "") ?? ""}
                    isReadOnly
                  />
                </FormControl>
              </Stack>
            </PanelMiddle>
            <PanelMiddle>
              <PanelTop>
                <Flex justifyContent="space-between" alignItems="center">
                  <Box>
                    <Heading size="md">Thumbnail</Heading>
                    <Text fontSize="sm" color={AppColors.textGray}>
                      Recommended Size: 600px x 400px
                    </Text>
                  </Box>
                  <SecondaryButton onClick={onThumbnailUpload}>Upload</SecondaryButton>
                </Flex>
              </PanelTop>
              <PanelBottom>
                {formik.values.file && (
                  <Box display="inline-flex" position="relative">
                    <Image
                      htmlHeight={400}
                      htmlWidth={600}
                      objectFit="cover"
                      src={formik.values.file.url}
                      alt={formik.values.file.name}
                      crossOrigin=""
                    />
                    <PrimaryIconButton
                      position="absolute"
                      top="-7px"
                      right="-15px"
                      justifyContent="flex-end"
                      paddingLeft={0}
                      paddingRight={0}
                      marginLeft={0}
                      marginRight={0}
                      height="auto"
                      backgroundColor="white"
                      outline="none"
                      minWidth="auto"
                      borderRadius="50%"
                      icon={faTimesCircle}
                      actionName="Remove Image"
                      onClick={onThumbnailRemove}
                      zIndex={1}
                    />
                  </Box>
                )}
                {!formik.values.file && (
                  <Text>
                    <AppIcon icon={faInfoCircle} standardRightMargin />
                    Not provided.
                  </Text>
                )}
              </PanelBottom>
            </PanelMiddle>
            <PanelBottom showTopBorder>
              <Stack spacing={4}>
                {formik.values.isRejected && (
                  <Textarea
                    id="comments"
                    value={formik.values.comments}
                    onChange={formik.handleChange}
                    placeholder="Explain what needs to change"
                  />
                )}
                {formik.values.isRejected && (
                  <Stack isInline spacing={2} justifyContent="flex-end">
                    <CancelButton onClick={cancelReject}>Cancel</CancelButton>
                    <PrimaryButton isLoading={formik.isSubmitting} onClick={formik.submitForm}>
                      Submit
                    </PrimaryButton>
                  </Stack>
                )}
                {/* Currently, We are submitting feedback on Complete task button*/}
                {!formik.values.isRejected && (
                  <>
                    <FormControl>
                      <FormLabel>Star</FormLabel>
                      <StarRatings
                        rating={feedbackContentItemFormik.values.rating}
                        changeRating={(newValue: number) => {
                          feedbackContentItemFormik.setFieldValue("rating", newValue);
                        }}
                        starRatedColor={AppColors.blue}
                        starHoverColor={Color(AppColors.blue).darken(0.1).hex()}
                        starDimension="20px"
                        starSpacing="2px"
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel>Feedback</FormLabel>
                      <ContentEditor
                        onChange={(html) => {
                          feedbackContentItemFormik.setFieldValue("feedback", html);
                        }}
                      />
                    </FormControl>
                  </>
                )}
                {!formik.values.isRejected && (
                  <Flex justifyContent="space-between" alignItems="center">
                    <Box marginLeft={2}>
                      {!needsAutosave && !!lastAutosavedAt && (
                        <Text fontSize="sm" color={AppColors.textGray}>
                          Last Saved {DateTime.fromJSDate(lastAutosavedAt).toFormat(HOUR_MINUTE_FORMAT)}
                        </Text>
                      )}
                    </Box>
                    <Stack isInline spacing={2} justifyContent="flex-end">
                      <SecondaryButton onClick={onReject} isDisabled={disableReject}>
                        Reject
                      </SecondaryButton>
                      <PrimaryButton
                        isLoading={formik.isSubmitting}
                        onClick={formik.submitForm}
                        isDisabled={unselectedChecklistItems.length !== 0}
                      >
                        Approve
                      </PrimaryButton>
                    </Stack>
                  </Flex>
                )}
              </Stack>
            </PanelBottom>
          </PanelGroup>
          {validationCheckSummary && !isWebCopy && <ValidationChecklistPanel {...validationCheckSummary} />}
        </Stack>
      </Grid>
    </>
  );
}
