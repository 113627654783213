import qs from "qs";
import { useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { isEqual } from "lodash";
import { QS_PARSE_OPTIONS, QS_STRINGIFY_OPTIONS } from "./common";

function getSearchParams(search: string) {
  return qs.parse(search, QS_PARSE_OPTIONS);
}

export function useQueryParamStringArray(
  name: string,
  defaultValue: string[] = []
): [string[], (value: string[]) => void] {
  const navigate = useNavigate();
  const location = useLocation();
  const parsed = getSearchParams(location.search);
  const qsValue = parsed[name];
  const [value, setValue] = useState<string[]>(qsValue ? (qsValue as string[]) : defaultValue);

  function handleSetValue(newValue: string[]) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { [name]: current, ...rest } = getSearchParams(location.search);
    const newParams = qs.stringify({ ...rest, [name]: newValue }, QS_STRINGIFY_OPTIONS);
    navigate({ search: newParams });
  }

  useEffect(() => {
    const calculatedValue = qsValue ? [qsValue as string | string[]].flat() : defaultValue;
    if (!isEqual(value, calculatedValue)) {
      setValue(calculatedValue);
    }
  }, [defaultValue, qsValue, value]);

  return [value, handleSetValue];
}
