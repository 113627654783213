import { useContext } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { AuthContext } from "../../core/auth-manager";

interface Props {
  initialValue?: string;
  height?: string | number;
  min_height?: number;
  onChange?: (value: string) => void;
  readOnly?: boolean;
}

export function ContentEditor(props: Props) {
  const { onChange, height, min_height, initialValue, readOnly } = props;
  const authContext = useContext(AuthContext);
  const user = authContext.viewer?.user;
  const userId = user?.id ? user.id : "";
  const userName = user?.fullName ? `${user.fullName}` : "";
  return (
    <Editor
      apiKey="kn8lz9ejf1pk3v1n49ig7fb1p43hjhw2wozkm8iec1306dht"
      initialValue={initialValue ?? ""}
      onEditorChange={onChange}
      disabled={readOnly}
      init={{
        height: height ?? 500,
        min_height: min_height,
        menubar: false,
        skin_url: `${process.env.PUBLIC_URL}/skins/rcm`,
        plugins: "autolink lists link anchor table help wordcount autoresize powerpaste tinycomments",
        tinycomments_mode: "embedded",
        tinycomments_author: userId,
        tinycomments_author_name: userName,
        powerpaste_allow_local_images: true,
        powerpaste_word_import: "prompt",
        powerpaste_html_import: "prompt",
        toolbar:
          "undo redo | blocks | bold italic underline | \
                    bullist numlist outdent indent | link | forecolor removeformat | fontfamily fontsize| addcomment showcomments",
      }}
    />
  );
}
