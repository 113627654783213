import { Global } from "@emotion/react";
import { Box, Menu, MenuList, MenuButton, MenuOptionGroup, MenuItemOption, Image } from "@chakra-ui/react";

import { ONBOARDING_STATUS } from "../../constants";

import { AppColors } from "../../core/custom-theme";

import FilterSVG from "../../assets/filter.svg";

import SearchBox from "../../components/search-box";
import { MainNav } from "../../components/main-nav";
import { PageHeading } from "../../components/page-heading";
import { SecondaryButton } from "../../components/buttons";
import { ContentContainer } from "../../components/content-container";
import { PanelGroup, PanelTop, PanelBottom } from "../../components/panels";

import { useAdminTeamMembers } from "./admin-team-members-hook";
import TeamMemberTable from "./team-member-table";

export function AdminTeamMembersRoute() {
  const {
    onSearch,
    onNextPage,
    onPreviousPage,
    onFilter,
    onFilterOnboardingStatusId,
    queryResult,
    searchValue,
    filter,
    onboardingStatusIds,
  } = useAdminTeamMembers();

  const OnboardingStatusOptions = Object.values(ONBOARDING_STATUS).reduceRight((result, status) => {
    result.push(
      <MenuItemOption key={status.id} value={status.id}>
        {status.name}
      </MenuItemOption>
    );
    return result;
  }, [] as React.ReactNode[]);

  return (
    <>
      <Global styles={{ body: { backgroundColor: AppColors.body } }} />
      <MainNav />
      <ContentContainer centerHorizontally>
        <PageHeading>Team Members</PageHeading>
        <PanelGroup>
          <PanelTop showBottomBorder>
            <Box display="flex" justifyContent="space-between" p={4}>
              <Box>
                <SearchBox onChange={onSearch} placeholder="Search" defaultValue={searchValue ?? ""} />
              </Box>
              <Box display="flex">
                <Box marginX={2}>
                  <Menu>
                    <MenuButton as={SecondaryButton}>
                      <Box display="flex">
                        Filter <Image src={FilterSVG} ml={2} />
                      </Box>
                    </MenuButton>
                    <MenuList>
                      <MenuOptionGroup type="checkbox" title="Filter" onChange={onFilter} value={filter}>
                        <MenuItemOption value="isAdmin">Is Admin</MenuItemOption>
                      </MenuOptionGroup>
                      <MenuOptionGroup
                        type="radio"
                        title="Onboarding Status"
                        onChange={onFilterOnboardingStatusId}
                        value={onboardingStatusIds[0]}
                      >
                        {OnboardingStatusOptions}
                      </MenuOptionGroup>
                    </MenuList>
                  </Menu>
                </Box>
              </Box>
            </Box>
          </PanelTop>
          <PanelBottom containsTable>
            <TeamMemberTable queryResult={queryResult} onPreviousPage={onPreviousPage} onNextPage={onNextPage} />
          </PanelBottom>
        </PanelGroup>
      </ContentContainer>
    </>
  );
}
