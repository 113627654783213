import { ReactNode } from "react";
import { Navigate } from "react-router-dom";
import { useToast } from "@chakra-ui/react";
import { Loader } from "../../components/loader";
import { useOnboardingRouteAuthQuery } from "../../generated/graphql";
import { LOADER_TYPE } from "../../constants";

interface Props {
  children: ReactNode;
  allowedOnboardingStatuses: string[];
}

export function OnboardingRouteAuthorization(props: Props) {
  const { allowedOnboardingStatuses, children } = props;
  const queryResult = useOnboardingRouteAuthQuery({
    fetchPolicy: "network-only",
  });
  const toast = useToast();

  if (queryResult.loading) {
    return <Loader type={LOADER_TYPE.fullView} />;
  }

  const csUser = queryResult.data?.viewer.creativesScaleUser ?? null;

  if (!csUser) {
    toast({
      title: "Looks like something went wrong. Please try again.",
      status: "warning",
    });
    return <Navigate to="/" />;
  }

  if (!allowedOnboardingStatuses.includes(csUser.onboardingStatusId)) {
    toast({
      title: "Looks like something went wrong. Please try again.",
      status: "warning",
    });
    return <Navigate to="/" />;
  }

  return <>{children}</>;
}
