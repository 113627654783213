import { useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Box, Flex, useToast } from "@chakra-ui/react";
import { Global } from "@emotion/react";
import { AppColors } from "../../core/custom-theme";
import { Loader } from "../../components/loader";
import { useConfirmEmailMutation } from "../../generated/graphql";
import { AuthContext } from "../../core/auth-manager";

export function ConfirmEmailRoute() {
  const authContext = useContext(AuthContext);
  const { confirmationToken } = useParams<"confirmationToken">();
  const navigate = useNavigate();
  const toast = useToast();
  const [confirmEmail, { loading }] = useConfirmEmailMutation();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  async function callConfirmEmail() {
    const response = await confirmEmail({
      variables: {
        input: {
          confirmationToken: confirmationToken ?? "",
        },
      },
    });

    if (response.data?.confirmEmail.ok) {
      toast({ title: "Success", description: "Your email address has been confirmed.", status: "success" });
      await authContext.refresh();
    } else {
      toast({
        title: "Error",
        description: "Something went wrong while confirming your email. Please try again.",
        status: "error",
      });
    }
    if (authContext.isLoggedIn) {
      navigate("/");
    } else {
      navigate("/login");
    }
  }

  useEffect(() => {
    callConfirmEmail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems={{ base: "flex-start", lg: "center" }}
      height="100vh"
      paddingTop={4}
    >
      <Global styles={{ body: { backgroundColor: AppColors.body } }} />
      <Box p={4} mt={1} width={{ base: 350, lg: 675 }} borderRadius={4}>
        <Flex justifyContent="center" marginBottom={4}>
          {loading && <Loader message="Confirming your email" />}
        </Flex>
      </Box>
    </Box>
  );
}
