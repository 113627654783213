import { Box, Stack, Text, useToast } from "@chakra-ui/react";
import { faFileUpload } from "@fortawesome/free-solid-svg-icons";
import Color from "color";
import { Accept, FileError, useDropzone } from "react-dropzone";

import { AppColors } from "../../core/custom-theme";
import AppIcon from "../app-icon";

interface Props {
  onFileUpload: (file: File) => void;
  onFileRejected?: (err: FileError) => void;
  acceptedFileExtensions?: Accept;
}

const CommonTransitionStyle = "0.2s ease";

export function FileDropUpload(props: Props) {
  const { onFileUpload, acceptedFileExtensions, onFileRejected } = props;
  const toast = useToast();
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    disabled: false,
    multiple: false,
    onDropAccepted: (files) => {
      if (files.length > 1) {
        console.warn("Multiple files uploaded");
        return;
      }
      onFileUpload(files[0]);
    },
    onDropRejected: (rejections) => {
      if (onFileRejected) {
        onFileRejected(rejections[0].errors[0]);
      } else {
        toast({
          title: "Invalid File Selected",
          description: rejections[0].errors[0].message,
          status: "error",
        });
      }
    },
    accept: acceptedFileExtensions,
  });

  return (
    <Box
      as="div"
      backgroundColor="#EEEEEE"
      transition={`box-shadow ${CommonTransitionStyle}, background-color ${CommonTransitionStyle}, border-color ${CommonTransitionStyle}`}
      border="1px dashed #DBDFE4"
      paddingY={4}
      borderRadius={4}
      display="flex"
      justifyContent="center"
      alignItems="center"
      outline="none"
      _hover={{
        boxShadow: "0 0 6px #cccccc",
        cursor: "pointer",
      }}
      {...(isDragActive && {
        boxShadow: "0 0 10px #cccccc",
        borderColor: AppColors.blue,
        backgroundColor: Color(AppColors.blue).lighten(0.6).hex(),
      })}
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      <Stack isInline spacing={2} alignItems="center">
        <AppIcon icon={faFileUpload} standardRightMargin size="2x" />
        <Text>Drop your file or click to select your file</Text>
      </Stack>
    </Box>
  );
}
