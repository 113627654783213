import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Stack,
  FormControl,
  FormLabel,
  Checkbox,
  Text,
  Box,
  Switch,
} from "@chakra-ui/react";
import { FindTasksFilter_ProductTaskTypeFragment, useFindTasksFilterQuery } from "../../generated/graphql";
import { CancelButton } from "../../components/buttons";
import { AppColors } from "../../core/custom-theme";
import { ProductTaskTypePair } from "./common";
import { PrimaryLinkButton } from "../../components/buttons/primary-link-button";
import { useViewer } from "../../core/auth-manager/auth-manager";

interface Props {
  isOpen: boolean;
  selectedProductTaskTypes: ProductTaskTypePair[];
  onProductTaskTypesChange: (productTaskTypes: ProductTaskTypePair[]) => void;
  selectedCategoryIds: number[];
  onCategoryIdsChange: (categoryIds: number[]) => void;
  selectedTaskTypeIds: string[];
  onTaskTypeIdsChange: (taskTypeIds: string[]) => void;
  onClose: () => void;
  isFulfilledByRCMWriter?: number;
  onIsRCMWriterChange: () => void;
}

export default function FilterDrawer(props: Props) {
  const {
    selectedCategoryIds,
    isOpen,
    onCategoryIdsChange,
    onClose,
    onProductTaskTypesChange,
    selectedProductTaskTypes,
    selectedTaskTypeIds,
    onTaskTypeIdsChange,
    isFulfilledByRCMWriter,
    onIsRCMWriterChange,
  } = props;

  const viewer = useViewer();
  const queryResult = useFindTasksFilterQuery({
    fetchPolicy: "cache-and-network",
  });

  const productTaskTypes = queryResult.data?.productTaskTypes.nodes ?? [];
  const categories = queryResult.data?.categories.nodes ?? [];
  const taskTypes = (queryResult.data?.taskTypes.nodes ?? []).filter((taskType) => !taskType.isFulfilledByCustomer);

  function addCategory(categoryId: number) {
    onCategoryIdsChange([...selectedCategoryIds, categoryId]);
  }

  function removeCategory(categoryId: number) {
    onCategoryIdsChange(selectedCategoryIds.filter((x) => x !== categoryId));
  }

  function addProductTaskType(productTaskType: FindTasksFilter_ProductTaskTypeFragment) {
    onProductTaskTypesChange([
      ...selectedProductTaskTypes,
      { productId: productTaskType.product.id, taskTypeId: productTaskType.taskType.id },
    ]);
  }

  function removeProductTaskType(productTaskType: FindTasksFilter_ProductTaskTypeFragment) {
    const newResult = selectedProductTaskTypes.filter(
      (x) => x.productId !== productTaskType.product.id || x.taskTypeId !== productTaskType.taskType.id
    );
    console.log("removeProductTaskType", { selectedProductTaskTypes, productTaskType, newResult });
    onProductTaskTypesChange(newResult);
  }

  function addTaskType(taskTypeId: string) {
    onTaskTypeIdsChange([...selectedTaskTypeIds, taskTypeId]);
  }

  function removeTaskType(taskTypeId: string) {
    onTaskTypeIdsChange(selectedTaskTypeIds.filter((x) => x !== taskTypeId));
  }

  function clearSelectedProductTaskTypes() {
    onProductTaskTypesChange([]);
  }

  function clearSelectedCategories() {
    onCategoryIdsChange([]);
  }

  function clearSelectedTaskTypes() {
    onTaskTypeIdsChange([]);
  }

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="lg">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader borderBottomWidth="1px">Filters</DrawerHeader>
        <DrawerBody>
          <Stack spacing={4}>
            <FormControl>
              <FormLabel>Task Types (all product types)</FormLabel>
              <Stack
                display="flex"
                flexDirection="column"
                maxHeight={200}
                p={2}
                overflowY="auto"
                border={`1px solid ${AppColors.borderColor}`}
                borderRadius={4}
              >
                {taskTypes.map((taskType) => (
                  <Checkbox
                    size="md"
                    key={taskType.id}
                    onChange={(e) => {
                      if (e.target.checked) {
                        addTaskType(taskType.id);
                      } else {
                        removeTaskType(taskType.id);
                      }
                    }}
                    isChecked={selectedTaskTypeIds.includes(taskType.id)}
                  >
                    <Text fontSize="sm" color={AppColors.textNormal}>
                      {taskType.name}
                    </Text>
                  </Checkbox>
                ))}
              </Stack>
              {selectedTaskTypeIds.length > 0 && (
                <Box>
                  <PrimaryLinkButton onClick={clearSelectedTaskTypes}>Clear Selection</PrimaryLinkButton>
                </Box>
              )}
            </FormControl>
            <FormControl>
              <FormLabel>Task Types (specific product types)</FormLabel>
              <Stack
                display="flex"
                flexDirection="column"
                maxHeight={200}
                p={2}
                overflowY="auto"
                border={`1px solid ${AppColors.borderColor}`}
                borderRadius={4}
              >
                {productTaskTypes.map((productTaskType) => (
                  <Checkbox
                    size="md"
                    key={productTaskType.id}
                    onChange={(e) => {
                      if (e.target.checked) {
                        addProductTaskType(productTaskType);
                      } else {
                        removeProductTaskType(productTaskType);
                      }
                    }}
                    isChecked={
                      !!selectedProductTaskTypes.find(
                        (x) =>
                          x.productId === productTaskType.product.id && x.taskTypeId === productTaskType.taskType.id
                      )
                    }
                  >
                    <Text fontSize="sm" color={AppColors.textNormal}>
                      {productTaskType.product.name}/{productTaskType.taskType.name}
                    </Text>
                  </Checkbox>
                ))}
              </Stack>
              {selectedProductTaskTypes.length > 0 && (
                <Box>
                  <PrimaryLinkButton onClick={clearSelectedProductTaskTypes}>Clear Selection</PrimaryLinkButton>
                </Box>
              )}
            </FormControl>
            <FormControl>
              <FormLabel>Category</FormLabel>
              <Stack
                display="flex"
                flexDirection="column"
                maxHeight={200}
                p={2}
                overflowY="auto"
                border={`1px solid ${AppColors.borderColor}`}
                borderRadius={4}
              >
                {categories.map((category) => (
                  <Checkbox
                    size="md"
                    key={category.id}
                    onChange={(e) => {
                      if (e.target.checked) {
                        addCategory(category.id);
                      } else {
                        removeCategory(category.id);
                      }
                    }}
                    isChecked={selectedCategoryIds.includes(category.id)}
                  >
                    <Text fontSize="sm" color={AppColors.textNormal}>
                      {category.name}
                    </Text>
                  </Checkbox>
                ))}
              </Stack>
              {selectedCategoryIds.length > 0 && (
                <Box>
                  <PrimaryLinkButton onClick={clearSelectedCategories}>Clear Selection</PrimaryLinkButton>
                </Box>
              )}
            </FormControl>
            {!!viewer?.creativesScaleUser?.isRCMWriter && (
              <FormControl>
                <FormLabel htmlFor="fulfilled-rcm-writer">Is fulfilled By RCM Writer</FormLabel>
                <Switch id="fulfilled-rcm-writer" isChecked={!!isFulfilledByRCMWriter} onChange={onIsRCMWriterChange} />
              </FormControl>
            )}
          </Stack>
        </DrawerBody>
        <DrawerFooter justifyContent="flex-start" borderTopWidth="1px">
          <CancelButton onClick={onClose}>Close</CancelButton>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}
