import { Heading, Stack, Text } from "@chakra-ui/react";
import { PanelBottom, PanelGroup, PanelTop } from "../../components/panels";
import { SecondaryButton } from "../../components/buttons";
import AppLink from "../../components/app-link";

interface Props {
  teamMemberId: number;
}

export function Tasks({ teamMemberId }: Props) {
  return (
    <PanelGroup>
      <PanelTop showBottomBorder>
        <Heading size="md" textAlign="left">
          Tasks
        </Heading>
      </PanelTop>
      <PanelBottom>
        <Stack spacing={4}>
          <Text as="h2" fontSize="xl">
            Completed & In-Progress Tasks
          </Text>
          <Text>
            {/*TODO: add description*/}
            {`Description`}
          </Text>
          <SecondaryButton as={AppLink} {...{ to: `/admin/team-members/${teamMemberId}/tasks` }} width="fit-content">
            View
          </SecondaryButton>
        </Stack>
      </PanelBottom>
    </PanelGroup>
  );
}
