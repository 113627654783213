import qs from "qs";
import { useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { isEqual } from "lodash";
import { getSearchParams, QS_STRINGIFY_OPTIONS } from "./common";

function decodeValue(value?: any): number[] | undefined {
  if (!value) {
    return undefined;
  }

  if (Array.isArray(value)) {
    return value.map((item) => parseInt(item));
  } else if (typeof value === "number") {
    return [value];
  } else if (typeof value === "string") {
    return [parseInt(value)];
  }

  return value;
}

export function useQueryParamIntArray(
  name: string,
  defaultValue: number[] = []
): [number[], (value: number[]) => void] {
  const navigate = useNavigate();
  const location = useLocation();
  const parsed = getSearchParams(location.search);
  const qsValue = decodeValue(parsed[name]) ?? defaultValue;
  const [value, setValue] = useState<number[]>(qsValue ? qsValue : defaultValue);

  function handleSetValue(newValue: number[]) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { [name]: current, ...rest } = getSearchParams(location.search);
    const newParams = qs.stringify({ ...rest, [name]: newValue }, QS_STRINGIFY_OPTIONS);
    navigate({ search: newParams });
  }

  useEffect(() => {
    if (!isEqual(value, qsValue)) {
      setValue(qsValue);
    }
  }, [qsValue, value]);

  return [value, handleSetValue];
}
