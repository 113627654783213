import { sortBy } from "lodash";
import { ChecklistItem } from "./checklist-item";
import { Stack, Heading } from "@chakra-ui/react";
import { PanelBottom, PanelGroup, PanelTop } from "../panels";
import AppIcon from "../app-icon";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { AppColors } from "../../core/custom-theme";

interface Props {
  isCollapsedByDefault?: boolean;
  isValid: boolean;
  checks: {
    id: string;
    name: string;
    helpText: string;
    isValid: boolean;
    actualValue: string;
    successCriteria: string;
    failureCriteria: string;
    isRequiredToPass: boolean;
  }[];
}

export function ValidationChecklistPanel(props: Props) {
  const { isValid, checks, isCollapsedByDefault } = props;

  const checkedCount = checks.filter((check) => check.isValid).length;

  return (
    <PanelGroup isCollapsible isCollapsedByDefault={isCollapsedByDefault ?? false}>
      <PanelTop isHeader>
        <Heading size="md">
          Checklist ({checkedCount}/{checks.length} Completed)
          {isValid && (
            <span style={{ color: AppColors.success }}>
              <AppIcon icon={faCheckCircle} standardLeftMargin />
            </span>
          )}
        </Heading>
      </PanelTop>
      <PanelBottom>
        <Stack spacing={4} shouldWrapChildren>
          {sortBy(checks, "isValid").map((check) => (
            <ChecklistItem
              key={check.id}
              description={check.helpText}
              isValid={check.isValid}
              name={check.name}
              actualValue={check.actualValue}
              isRequiredToPass={check.isRequiredToPass}
            />
          ))}
        </Stack>
      </PanelBottom>
    </PanelGroup>
  );
}
