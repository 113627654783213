import { useToast } from "@chakra-ui/react";
import { useFormik } from "formik";
import {
  ContentStrategyV2_OrderContentStrategyFragment,
  useContentStrategyV2_UpdateOrderContentStrategyMutation,
} from "../../../../generated/graphql";
import { handleGraphQLResponseError } from "../../../../util/error-helper";
import { processNullableString } from "../../../../util/form-helpers";

interface Options {
  isEditing: boolean;
  onStopEditing: () => void;
  orderContentStrategy: ContentStrategyV2_OrderContentStrategyFragment;
}

interface FormValues {
  topic: string | null;
  customerKeywordId: number | null;
  haloKeywordIds: number[];
  purposeOfContent: string | null;
  linkTo: string | null;
  voice: string | null;
  focusPoints: string | null;
  webPage: string | null;
  pageType: string | null;
  sampleContent: string | null;
}

export function useContentStrategyForm(options: Options) {
  const { orderContentStrategy, onStopEditing } = options;
  const toast = useToast();
  const [updateOrderContentStrategy] = useContentStrategyV2_UpdateOrderContentStrategyMutation();

  const formik = useFormik<FormValues>({
    initialValues: {
      topic: orderContentStrategy.topic ?? null,
      customerKeywordId: orderContentStrategy.customerKeywordId ?? null,
      haloKeywordIds: orderContentStrategy.orderHaloKeywords.map((x) => x.customerKeyword.id),
      purposeOfContent: orderContentStrategy.purposeOfContent ?? null,
      linkTo: orderContentStrategy.linkTo ?? null,
      voice: orderContentStrategy.voice ?? null,
      focusPoints: orderContentStrategy.focusPoints ?? null,
      webPage: orderContentStrategy.webPage ?? null,
      pageType: orderContentStrategy.pageType ?? null,
      sampleContent: orderContentStrategy.sampleContent ?? null,
    },
    enableReinitialize: true,
    onSubmit: async (values, helpers) => {
      try {
        const response = await updateOrderContentStrategy({
          variables: {
            input: {
              orderContentStrategyId: orderContentStrategy.id,
              customerKeywordId: values.customerKeywordId,
              focusPoints: processNullableString(values.focusPoints ?? ""),
              linkTo: processNullableString(values.linkTo ?? ""),
              pageType: processNullableString(values.pageType ?? ""),
              purposeOfContent: processNullableString(values.purposeOfContent ?? ""),
              topic: processNullableString(values.topic ?? ""),
              voice: processNullableString(values.voice ?? ""),
              webPage: processNullableString(values.webPage ?? ""),
              sampleContent: processNullableString(values.sampleContent ?? ""),
              orderHaloKeywords: values.haloKeywordIds,
            },
          },
        });

        if (!response.data?.updateOrderContentStrategy.ok) {
          throw new Error(
            response.data?.updateOrderContentStrategy.error?.message ??
              "Something went wrong while saving the Content Strategy. Please try again."
          );
        }
        onStopEditing();
      } catch (e: any) {
        handleGraphQLResponseError(e, toast, helpers.setErrors);
      }
    },
  });

  function onCancel() {
    formik.resetForm();
    onStopEditing();
  }

  return {
    formik,
    onCancel,
  };
}
