import { Tooltip, Badge } from "@chakra-ui/react";
import { CREATIVES_SCALE_TASK_STATUS } from "../../constants";

interface Props {
  csTaskStatus?: {
    id: string;
    name: string;
  } | null;
  assignedUser?: {
    id: number;
    fullName: string;
  } | null;
}

const COLORS = {
  [CREATIVES_SCALE_TASK_STATUS.APPROVED.id]: "green",
  [CREATIVES_SCALE_TASK_STATUS.AVAILABLE.id]: "blue",
  [CREATIVES_SCALE_TASK_STATUS.COMPLETED.id]: "green",
  [CREATIVES_SCALE_TASK_STATUS.EDITING.id]: "yellow",
  [CREATIVES_SCALE_TASK_STATUS.PAID.id]: "green",
  [CREATIVES_SCALE_TASK_STATUS.REJECTED.id]: "red",
  [CREATIVES_SCALE_TASK_STATUS.REVIEW.id]: "blue",
  [CREATIVES_SCALE_TASK_STATUS.REVISION.id]: "orange",
  [CREATIVES_SCALE_TASK_STATUS.WRITING.id]: "orange",
};

// TODO: Do we want to show review status as well?
export function TaskStatusIndicator(props: Props) {
  const { csTaskStatus, assignedUser } = props;
  if (!csTaskStatus) {
    return <Badge>Unknown</Badge>;
  }
  const color = COLORS[csTaskStatus.id];

  if (assignedUser) {
    const tooltipLabel = `Assigned to ${assignedUser?.fullName ?? "Unassigned"}`;
    return (
      <Tooltip label={tooltipLabel} aria-label={tooltipLabel}>
        <Badge colorScheme={color}>{csTaskStatus.name}</Badge>
      </Tooltip>
    );
  }

  return <Badge colorScheme={color}>{csTaskStatus.name}</Badge>;
}
