import { Select, FormLabel } from "@chakra-ui/react";
import { EMAIL_PREFERENCE } from "../../constants/user-email-preferences";

interface Props {
  emailPreference: string;
  handleChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
}

function EmailPreferenceSelector(props: Props) {
  const { emailPreference, handleChange } = props;

  return (
    <>
      <FormLabel htmlFor="emailpreferenceId">Email Preference</FormLabel>
      <Select onChange={handleChange} value={emailPreference} id="emailPreference" variant="flushed">
        {Object.values(EMAIL_PREFERENCE).map((option) => (
          <option value={option.id} key={option.id}>
            {option.name}
          </option>
        ))}
      </Select>
    </>
  );
}

export default EmailPreferenceSelector;
