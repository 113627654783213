import qs from "qs";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { QS_PARSE_OPTIONS, QS_STRINGIFY_OPTIONS } from "./common";

function getSearchParams() {
  return qs.parse(location.search, QS_PARSE_OPTIONS);
}

export function useQueryParamObject<T>(name: string): [T | undefined, (value: T | undefined) => void] {
  function encodeValue(value?: T) {
    if (!value) {
      return undefined;
    }

    console.log("useQueryParamObject.encodeValue", { value });
    const jsonified = JSON.stringify(value);
    console.log("useQueryParamObject.encodeValue", { jsonified });
    return btoa(jsonified);
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  function decodeValue(value?: any) {
    if (!value) {
      return undefined;
    }

    try {
      const result = atob(value);
      console.log("useQueryParamObject.decodeValue", { result });
      const parsed = JSON.parse(result) as T;
      console.log("useQueryParamObject.decodeValue", { parsed });
      return parsed;
    } catch (e: any) {
      return undefined;
    }
  }

  const navigate = useNavigate();
  const parsed = getSearchParams();
  const qsValue = decodeValue(parsed[name]);
  const [value, setValue] = useState<T | undefined>(qsValue);

  function handleSetValue(newValue?: T) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { [name]: current, ...rest } = getSearchParams();
    if (newValue === undefined) {
      const newParams = qs.stringify(rest, QS_STRINGIFY_OPTIONS);
      navigate({ search: newParams });
    } else {
      const encodedValue = encodeValue(newValue);
      console.log("useQueryParamObject.handleSetValue", { encodedValue });
      const newParams = qs.stringify({ ...rest, [name]: encodedValue }, QS_STRINGIFY_OPTIONS);
      navigate({ search: newParams });
    }
  }

  useEffect(() => {
    const calculatedValue = qsValue as string | undefined;
    console.log("useQueryParamObject calculatedValue", { calculatedValue });
    const decodedValue = decodeValue(calculatedValue);
    console.log("useQueryParamObject decodedValue", { decodedValue });
    if (decodedValue !== value) {
      setValue(decodedValue);
    }
  }, [decodeValue, qsValue, value]);

  return [qsValue, handleSetValue];
}
