import { useState, useEffect } from "react";
import { useToast } from "@chakra-ui/react";
import { useFormik } from "formik";
import * as yup from "yup";

import {
  useContentIdeationTaskWorkspaceQuery,
  useSaveTaskContentIdeationMutation,
} from "../../../../generated/graphql";
import { getDisplayMessageForError } from "../../../../util/error-helper";
import { useDebounce } from "../../../../util/use-debounce";
import { TaskWorkspaceCommonProps } from "../../task-workspace-common-props";

interface FormValues {
  idea: string;
}

const validationSchema = yup.object().shape({
  idea: yup.string().label("Idea").required().nullable(false).min(1),
});

export function useContentIdeation(options: TaskWorkspaceCommonProps) {
  const { onComplete, task } = options;
  const taskWorkspaceContentIdeationQueryResult = useContentIdeationTaskWorkspaceQuery({
    fetchPolicy: "no-cache",
    variables: {
      taskId: task.id,
    },
  });
  const [saveTaskContentIdeation] = useSaveTaskContentIdeationMutation();
  const toast = useToast();
  const [needsAutosave, setNeedsAutosave] = useState(false);
  const [lastAutosavedAt, setLastAutosavedAt] = useState<Date | null>(null);
  const [contentIdeationValues, setContentIdeationValues] = useState<{ idea: string }>({
    idea: "",
  });

  const taskQueryResult = taskWorkspaceContentIdeationQueryResult.data?.task ?? null;
  const contentIdeation = taskQueryResult?.contentIdeation ?? null;

  const formik = useFormik<FormValues>({
    initialValues: {
      idea: contentIdeation?.idea ?? "",
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (values) => {
      try {
        await saveTaskContentIdeation({
          variables: {
            input: {
              taskId: task.id,
              idea: values.idea,
            },
          },
        });
        onComplete();
      } catch (e: any) {
        toast({ title: "Unable to Complete Task", description: getDisplayMessageForError(e), status: "error" });
      }
    },
  });

  const debouncedContentIdeationValues = useDebounce(contentIdeationValues, 2000);

  useEffect(() => {
    if (contentIdeation && contentIdeation.idea !== contentIdeationValues.idea) {
      setContentIdeationValues({
        idea: contentIdeation.idea,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentIdeation]);

  useEffect(() => {
    if (task && needsAutosave) {
      saveTaskContentIdeation({
        variables: {
          input: {
            idea: debouncedContentIdeationValues.idea,
            taskId: task.id,
          },
        },
        // eslint-disable-next-line promise/prefer-await-to-then
      }).then(() => {
        setLastAutosavedAt(new Date());
        setNeedsAutosave(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedContentIdeationValues]);

  function onContentIdeationUpdate(idea: string) {
    setContentIdeationValues({ idea });
    setNeedsAutosave(true);
  }

  return {
    taskId: task.id,
    loading: taskWorkspaceContentIdeationQueryResult.loading,
    contentIdeation,
    formik,
    needsAutosave,
    lastAutosavedAt,
    task: taskQueryResult,
    onContentIdeationUpdate,
  };
}
