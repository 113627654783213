import { Tabs, TabList, Tab, TabPanels, TabPanel } from "@chakra-ui/react";

import { PageHeading } from "../../../../components/page-heading";
import { useContentStrategyV2 } from "./content-strategy-v2-hook";
import { ContentStrategyRoadmap } from "./content-strategy-v2-roadmap";
import { ContentStrategyWorkspace } from "./content-strategy-v2-workspace";
import { TaskWorkspaceCommonProps } from "../../task-workspace-common-props";
import { Container } from "../../../../components/layout/container";

export function ContentStrategyV2(props: TaskWorkspaceCommonProps) {
  const contentStrategyV2 = useContentStrategyV2(props);

  return (
    <Container>
      <PageHeading>Content Strategy</PageHeading>
      <Tabs variant="soft-rounded" p={0}>
        <TabList>
          <Tab>Strategy</Tab>
          <Tab>Roadmap</Tab>
        </TabList>
        <TabPanels>
          <TabPanel px={0}>
            <ContentStrategyWorkspace taskId={props.task.id} {...props} {...contentStrategyV2} />
          </TabPanel>
          <TabPanel px={0}>
            <ContentStrategyRoadmap customerId={contentStrategyV2.customer?.id ?? 0} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Container>
  );
}
