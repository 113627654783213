import { useContext, createContext, ReactNode } from "react";
import { AuthManager_ViewerFragment, useLogoutUserMutation, useAuthManagerQuery } from "../../generated/graphql";
import { ConnectivityContext } from "../connectivity-monitor";

export interface AuthContextValue {
  isLoading: boolean;
  isLoggedIn: boolean;
  refresh: () => Promise<any>;
  logout: () => Promise<any>;
  viewer: AuthManager_ViewerFragment | null;
}

interface Props {
  children: ReactNode;
}

export const AuthContext = createContext<AuthContextValue>({
  isLoading: true,
  isLoggedIn: false,
  refresh: async () => {
    // Do nothing
  },
  logout: async () => {
    // Do nothing
  },
  viewer: null,
});

export function AuthManager(props: Props) {
  const [logoutUserMutation] = useLogoutUserMutation();
  const queryResult = useAuthManagerQuery({ fetchPolicy: "network-only" });
  const contextValue: AuthContextValue = {
    isLoading: queryResult.loading,
    isLoggedIn: false,
    refresh: queryResult.refetch,
    logout: async () => {
      await logoutUserMutation();
      await queryResult.refetch();
    },
    viewer: null,
  };

  if (!queryResult.loading) {
    const { data, error } = queryResult;
    const viewer = data?.viewer ?? null;
    if (data) {
      contextValue.isLoggedIn = !error && !!viewer?.user;
      contextValue.viewer = viewer;
    }
  }

  function renderContent(contextValue: AuthContextValue, isConnected: boolean) {
    const { children } = props;
    if (!contextValue) {
      return null;
    }
    if (!isConnected) {
      // If API connectivity goes down, prevent redirects for an improved user experience.
      return null;
    }
    return children;
  }

  return (
    <AuthContext.Provider value={contextValue}>
      <ConnectivityContext.Consumer>
        {(connectivityContext) => renderContent(contextValue, connectivityContext.isConnected)}
      </ConnectivityContext.Consumer>
    </AuthContext.Provider>
  );
}

export function useViewer() {
  const authContext = useContext(AuthContext);
  const { viewer } = authContext;
  return viewer;
}
