import { Text, Link } from "@chakra-ui/react";
import { SecondaryButton } from "../../components/buttons";
import { PanelGroup, PanelTop, PanelBottom } from "../../components/panels";
import { AppColors } from "../../core/custom-theme";

export default function PerformanceStandards() {
  return (
    <PanelGroup>
      <PanelTop isHeader>
        <Text fontWeight="bold" fontSize="lg">
          Performance Standards
        </Text>
      </PanelTop>
      <PanelBottom>
        <Text fontSize="lg">Account Status</Text>
        <Text color={AppColors.textDarkGray} fontSize="md" my={2}>
          Making sure our clients receive great quality content from all our team members is one of our top priorities.
          Our creative levels and performance standards help you track your performance and let clients know with
          confidence that they’re getting the best content on the web.
        </Text>
        <SecondaryButton my={2}>
          <Link href="#" isExternal>
            Learn More
          </Link>
        </SecondaryButton>
      </PanelBottom>
    </PanelGroup>
  );
}
