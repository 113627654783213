import { Text, Box } from "@chakra-ui/react";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { PrimaryIconButton } from "../../components/buttons/primary-icon-button";

interface Props {
  product?: {
    id: string;
    name: string;
  } | null;
  taskType?: {
    id: string;
    name: string;
  } | null;
  onDelete: () => void;
}

export function WildcardPermissionItem(props: Props) {
  const { product, taskType, onDelete } = props;
  return (
    <Box display="flex" justifyContent="space-between" alignItems="center" _hover={{ backgroundColor: "#dfdfdf" }}>
      <Text fontSize="sm">
        {product?.name ?? "Any Product"}/{taskType?.name ?? "Any Task Type"}
      </Text>
      <PrimaryIconButton icon={faTrashAlt} onClick={onDelete} actionName="Remove" />
    </Box>
  );
}
