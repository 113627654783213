import { useContext } from "react";
import { Box, Text, Link } from "@chakra-ui/react";
import { PanelGroup, PanelTop, PanelBottom } from "../../components/panels";
import { AppColors } from "../../core/custom-theme";
import { AuthContext } from "../../core/auth-manager";
import { currencyFormatter } from "../../util/formatter";
import AppIcon from "../../components/app-icon";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";

export default function PendingBalance() {
  const authContext = useContext(AuthContext);
  const { viewer } = authContext;

  return (
    <PanelGroup>
      <PanelTop isHeader>
        <Box mb={4}>
          <Link target="_blank" fontSize="sm" color={AppColors.blue} href="/stripe-dashboard" isExternal>
            Open Stripe Dashboard
            <AppIcon icon={faExternalLinkAlt} standardLeftMargin />
          </Link>
        </Box>
        <Box display="flex" justifyContent="space-between">
          <Box>
            <Text fontWeight="bold" fontSize="lg">
              Pending Balance
            </Text>
            {/* TODO: add payout date */}
            <Text color={AppColors.textDarkGray} fontSize="md">
              As a freelancer, your billing period begins on Monday and ends on Sunday. Your funds are available 10 days
              later for all content marked as Approved within that timeframe.
            </Text>
          </Box>
          <Box>
            <Text color={AppColors.textDarkGreen} fontSize="lg">
              +{currencyFormatter.format(viewer?.creativesScaleUser?.unpaidBalance ?? 0)}
            </Text>
          </Box>
        </Box>
      </PanelTop>
      <PanelBottom>
        <Box display="flex" justifyContent="space-between">
          <Text fontWeight="bold" fontSize="lg">
            Total to Date:
          </Text>
          <Text color={AppColors.textDarkGreen} fontSize="lg">
            +{currencyFormatter.format(viewer?.creativesScaleUser?.allTimeEarnedAmount ?? 0)}
          </Text>
        </Box>
      </PanelBottom>
    </PanelGroup>
  );
}
