import { Stack, FormControl, FormLabel, Input, Textarea } from "@chakra-ui/react";
import { PanelBox } from "../../../../components/panels";
import { GbpWorkspace_TaskFragment } from "../../../../generated/graphql";

interface Props {
  task: GbpWorkspace_TaskFragment;
}

export function InformationPanel(props: Props) {
  const { task } = props;

  return (
    <PanelBox>
      <Stack spacing={4}>
        <FormControl>
          <FormLabel>Order Instructions</FormLabel>
          <Textarea variant="flushed" isDisabled value={task.order.instructions ?? "Not provided"} resize="none" />
        </FormControl>
        <FormControl>
          <FormLabel>Work Type</FormLabel>
          <Input variant="flushed" isDisabled value={task.order.partnerProduct.product.name} />
        </FormControl>
        <FormControl>
          <FormLabel>Company Name</FormLabel>
          <Input variant="flushed" isDisabled value={task.customer.name} />
        </FormControl>
        <FormControl>
          <FormLabel>Company URL</FormLabel>
          <Input variant="flushed" value={task.customer?.website ?? ""} isDisabled />
        </FormControl>
        <FormControl>
          <FormLabel>Gbp URL</FormLabel>
          <Input variant="flushed" value={task.customer?.gbpUrl ?? ""} isDisabled />
        </FormControl>
        <FormControl>
          <FormLabel>Service Area</FormLabel>
          <Textarea
            variant="flushed"
            value={task.customer?.customerQuestionnaire?.serviceArea ?? ""}
            isDisabled
            rows={7}
            resize="none"
          />
        </FormControl>
        <FormControl>
          <FormLabel>Primary Keywords</FormLabel>
          <Textarea
            variant="flushed"
            value={task.customer.customerKeywords.nodes.map((e) => e.name).join(", ") ?? ""}
            isDisabled
            rows={7}
            resize="none"
          />
        </FormControl>
      </Stack>
    </PanelBox>
  );
}
