import { useContext } from "react";
import { useLocation, useNavigate, Navigate } from "react-router-dom";
import { Global } from "@emotion/react";
import queryString from "qs";
import { Box, Menu, MenuList, MenuButton, MenuOptionGroup, MenuItemOption, Image } from "@chakra-ui/react";
import { ArrowUpDownIcon } from "@chakra-ui/icons";
import { getQueryParams, setParams } from "../../util/query-param-helper";
import { LOADER_TYPE, PAGE_LIMIT, TASK_STATUS } from "../../constants";
import { useProfileTasksQuery } from "../../generated/graphql";
import { AppColors } from "../../core/custom-theme";
import { ContentContainer } from "../../components/content-container";
import SearchBox from "../../components/search-box";
import { SecondaryButton } from "../../components/buttons";
import ProfileTaskTable from "./profile-task-table";
import FilterSVG from "../../assets/filter.svg";
import { AuthContext } from "../../core/auth-manager";
import { Loader } from "../../components/loader";
import { PanelBottom, PanelGroup, PanelTop } from "../../components/panels";
import { PageHeading } from "../../components/page-heading";
import { MainNav } from "../../components/main-nav";

export function ProfileTasks() {
  const location = useLocation();
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);

  const queryParams = getQueryParams(location.search, queryString) || {};
  const pageOffset = queryParams?.offset ? parseInt(queryParams.offset, 10) : 0;

  if (authContext.isLoading) {
    return <Loader type={LOADER_TYPE.fullView} />;
  }

  if (!authContext.viewer?.user?.id) {
    return <Navigate to="/login" />;
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const queryResult = useProfileTasksQuery({
    variables: {
      limit: PAGE_LIMIT,
      offset: pageOffset,
      assignedUserIds: [authContext.viewer?.user?.id],
      statusIds: [TASK_STATUS.COMPLETED],
    },
    fetchPolicy: "network-only",
  });

  function onSearch(value: string) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { q, ...remainingQueryParams } = queryParams;
    const params = setParams(remainingQueryParams, "q", value);
    navigate({ search: `?${params}` });
  }

  function onNextPage() {
    const { offset, ...remainingQueryParams } = queryParams;
    const newOffset = parseInt(offset ?? "0", 10) + PAGE_LIMIT;
    const params = setParams(remainingQueryParams, "offset", newOffset.toString());
    navigate({ search: `?${params}` });
  }

  function onPreviousPage() {
    const { offset, ...remainingQueryParams } = queryParams;
    const newOffset = Math.max(parseInt(offset ?? "0", 10) - PAGE_LIMIT, 0);
    const params = setParams(remainingQueryParams, "offset", newOffset.toString());
    navigate({ search: `?${params}` });
  }

  // TODO: Implement search, sort and filter
  return (
    <>
      <Global styles={{ body: { backgroundColor: AppColors.body } }} />
      <MainNav />
      <ContentContainer centerHorizontally>
        <PageHeading>Task History</PageHeading>
        <PanelGroup>
          <PanelTop>
            <Box display="flex" justifyContent="space-between" p={4}>
              <Box>
                <SearchBox onChange={onSearch} placeholder="Search" />
              </Box>
              <Box display="flex">
                <Box marginX={2}>
                  <Menu>
                    <MenuButton as={SecondaryButton}>
                      Sort <ArrowUpDownIcon ml={2} />
                    </MenuButton>
                    <MenuList>
                      <MenuOptionGroup defaultValue="asc" title="Name" type="radio">
                        <MenuItemOption value="asc">Ascending</MenuItemOption>
                        <MenuItemOption value="desc">Descending</MenuItemOption>
                      </MenuOptionGroup>
                    </MenuList>
                  </Menu>
                </Box>
                <Box marginX={2}>
                  <Menu>
                    <MenuButton as={SecondaryButton}>
                      <Box display="flex">
                        Filter <Image src={FilterSVG} ml={2} />
                      </Box>
                    </MenuButton>
                    <MenuList>
                      <MenuOptionGroup type="checkbox">
                        <MenuItemOption value="filter">Filter</MenuItemOption>
                      </MenuOptionGroup>
                    </MenuList>
                  </Menu>
                </Box>
              </Box>
            </Box>
          </PanelTop>
          <PanelBottom containsTable>
            <ProfileTaskTable queryResult={queryResult} onPreviousPage={onPreviousPage} onNextPage={onNextPage} />
          </PanelBottom>
        </PanelGroup>
      </ContentContainer>
    </>
  );
}
