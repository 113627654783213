import { Box, FormControl, FormLabel, Stack, Textarea } from "@chakra-ui/react";
import { CancelButton, DangerButton, PrimaryButton } from "../../../../components/buttons";
import { HelpText } from "../../../../components/help-text";
import { PanelBottom, PanelGroup, PanelMiddle } from "../../../../components/panels";
import { useSummary } from "./summary-hook";

interface Props {
  customerProposalId: number;
  summary?: string | null;
  onAbandon: () => void;
  handleComplete: () => void;
  isDisabled: boolean;
}

export function Summary(props: Props) {
  const { formik, handleCancel } = useSummary(props);
  const { isDisabled } = props;
  return (
    <PanelGroup>
      <PanelMiddle>
        <form onSubmit={formik.handleSubmit}>
          <Stack spacing={4}>
            <FormControl isDisabled={isDisabled}>
              <FormLabel>
                Summary (for Client)
                <HelpText standardLeftMargin>Summarize your strategy and choices for the client</HelpText>{" "}
              </FormLabel>
              <Textarea id="summary" value={formik.values.summary} onChange={formik.handleChange} />
            </FormControl>
            {formik.dirty && (
              <Stack isInline spacing={2}>
                <PrimaryButton isLoading={formik.isSubmitting} type="submit">
                  Save
                </PrimaryButton>
                <CancelButton onClick={handleCancel}>Cancel</CancelButton>
              </Stack>
            )}
          </Stack>
        </form>
      </PanelMiddle>
      <PanelBottom showTopBorder>
        <Stack isInline spacing={4} justifyContent="space-between" shouldWrapChildren alignItems="center">
          <Box textAlign="right">
            <PrimaryButton onClick={props.handleComplete} isDisabled={isDisabled}>
              Submit
            </PrimaryButton>
          </Box>
          <Box>
            <DangerButton onClick={props.onAbandon}>Unassign Task</DangerButton>
            <HelpText standardLeftMargin>
              &quot;Unassign Task&quot; will unassign this task from you and return it to the queue.{" "}
              <strong>This cannot be undone and may result in lost work.</strong>
            </HelpText>
          </Box>
        </Stack>
      </PanelBottom>
    </PanelGroup>
  );
}
