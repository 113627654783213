import { Text, Box } from "@chakra-ui/react";
import { AppColors } from "../../core/custom-theme";

interface Props {
  name: string;
  isSelected: boolean;
  isDisabled: boolean;
  onToggle: () => void;
}

function InterestItem(props: Props) {
  const { name, isSelected, isDisabled, onToggle } = props;

  return (
    <Box
      bg={isSelected ? AppColors.blue : "white"}
      opacity={isDisabled ? 0.7 : 1}
      color={isSelected ? "white" : "inherit"}
      onClick={isDisabled ? undefined : onToggle}
      height="150px"
      display="flex"
      alignItems="center"
      justifyContent="center"
      cursor={isDisabled ? "not-allowed" : "pointer"}
      padding={4}
    >
      <Text as="b">{name}</Text>
    </Box>
  );
}

export default InterestItem;
