import { DateTime } from "luxon";
import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Stat,
  StatLabel,
  StatNumber,
  Text,
  PopoverArrow,
  Button,
} from "@chakra-ui/react";
import parse from "html-react-parser";
import StarRatings from "react-star-ratings";
import { faBan } from "@fortawesome/free-solid-svg-icons";

import { LayoutBase } from "../../components/layout/layout-base";
import { PageHeading } from "../../components/page-heading";
import { PanelBottom, PanelGroup, PanelTop } from "../../components/panels";
import {
  Table,
  TableBody,
  TableCell,
  TableFoot,
  TableHead,
  TableHeaderCell,
  TablePagination,
  TableRow,
} from "../../components/table";
import { DATE_TIME_FORMAT } from "../../constants";
import { useAdminQaViewTasks } from "./use-admin-qa-view";
import { AppColors } from "../../core/custom-theme";
import AppLink from "../../components/app-link";
import { UserSelectorV2 } from "../../common/user-selector-v2";
import { CustomerSelector } from "../../common/customer-selector";
import AppIcon from "../../components/app-icon";

export function AdminQaViewRoute() {
  const {
    loading,
    tasks,
    pagination,
    totalCount,
    handleSearchChange,
    search,
    onUserSelected,
    userId,
    onCustomerSelected,
    clientIds,
    filterCount,
    clearFilters,
  } = useAdminQaViewTasks();

  return (
    <LayoutBase pageTitle="Tasks (Admin)">
      <PageHeading>Content Review Tasks</PageHeading>
      <PanelGroup>
        <PanelTop showBottomBorder>
          <Flex justifyContent="space-between">
            <HStack spacing="8px" justify="left" justifyContent="flex-start" alignItems="flex-end" shouldWrapChildren>
              <FormControl>
                <FormLabel fontSize="sm">Search (Task ID)</FormLabel>
                <Input value={search} onChange={handleSearchChange} minW={150} />
              </FormControl>
              <FormControl>
                <FormLabel fontSize="sm">User</FormLabel>
                <UserSelectorV2 onUserSelected={onUserSelected} selectedUserId={userId} />
              </FormControl>
              <FormControl>
                <FormLabel fontSize="sm">Clients</FormLabel>
                <CustomerSelector
                  onCustomerSelected={onCustomerSelected}
                  isMulti
                  selectedCustomerIds={clientIds as number[]}
                />
              </FormControl>
              <Box>
                {filterCount > 0 && (
                  <Button onClick={clearFilters}>
                    <AppIcon icon={faBan} standardRightMargin />
                    Clear Filters
                  </Button>
                )}
              </Box>
            </HStack>
            <Box>
              <Stat>
                <StatLabel>Tasks Found</StatLabel>
                <StatNumber>{totalCount}</StatNumber>
              </Stat>
            </Box>
          </Flex>
        </PanelTop>
        <PanelBottom containsTable>
          <Table isEmpty={tasks.length === 0} isLoading={loading}>
            <TableHead>
              <TableRow key="header">
                <TableHeaderCell>ID</TableHeaderCell>
                <TableHeaderCell>Date Completed</TableHeaderCell>
                <TableHeaderCell>Writer</TableHeaderCell>
                <TableHeaderCell>Client Name</TableHeaderCell>
                <TableHeaderCell>Reviewer</TableHeaderCell>
                <TableHeaderCell>Topic</TableHeaderCell>
                <TableHeaderCell># Rejected</TableHeaderCell>
                <TableHeaderCell>Star Value</TableHeaderCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tasks.map((task) => {
                return (
                  <TableRow key={task.id}>
                    <TableCell>
                      <AppLink to={`/tasks/${task.id}/view?showRevision=1`}>{task.id}</AppLink>
                    </TableCell>
                    <TableCell>
                      {!!task.completedAt && (
                        <Text fontSize="sm">{`Writing: ${DateTime.fromJSDate(task.completedAt).toFormat(
                          DATE_TIME_FORMAT
                        )}`}</Text>
                      )}
                      {!!task.reviewTasksByQa.nodes?.[0]?.completedAt && (
                        <Text fontSize="sm">{`QA: ${DateTime.fromJSDate(
                          task.reviewTasksByQa.nodes[0].completedAt
                        ).toFormat(DATE_TIME_FORMAT)}`}</Text>
                      )}
                    </TableCell>
                    <TableCell>{task.assignedUser?.fullName}</TableCell>
                    <TableCell>{task.customer.name}</TableCell>
                    <TableCell>
                      {task.reviewTasksByQa.nodes.map((qaTask) => {
                        return <Text key={qaTask.id}>{qaTask.assignedUser?.fullName ?? "-"}</Text>;
                      })}
                    </TableCell>
                    <TableCell>{task.order.topic}</TableCell>
                    <TableCell>
                      <Text>QA : {task.reviewTasksQaCount.totalCount}</Text>
                      <Text>Client : {task.reviewTasksByCustomer.totalCount}</Text>
                    </TableCell>
                    <TableCell>
                      {task.contentItem?.feedbackContentItems?.[0] ? (
                        <Popover trigger="hover">
                          <PopoverTrigger>
                            <span>
                              <StarRatings
                                rating={task.contentItem.feedbackContentItems[0].rating}
                                starRatedColor={AppColors.blue}
                                starDimension="20px"
                                starSpacing="2px"
                              />
                            </span>
                          </PopoverTrigger>
                          <PopoverContent maxW={200} zIndex={500}>
                            <PopoverArrow bg={AppColors.textNormal} />
                            <Box
                              backgroundColor={AppColors.textNormal}
                              color={AppColors.white}
                              p={2}
                              maxW={200}
                              fontSize="sm"
                            >
                              {parse(task.contentItem.feedbackContentItems[0].feedback ?? "-")}
                            </Box>
                          </PopoverContent>
                        </Popover>
                      ) : (
                        "-"
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
            <TableFoot>
              <TablePagination {...pagination} totalCount={totalCount} />
            </TableFoot>
          </Table>
        </PanelBottom>
      </PanelGroup>
    </LayoutBase>
  );
}
