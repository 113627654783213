import { Global } from "@emotion/react";
import { Box, Image, Text, Divider } from "@chakra-ui/react";
import CSLogo from "../../assets/creatives-scale-logo-color.png";
import { AppColors } from "../../core/custom-theme";

interface Props {
  email: string;
}

export function SuccessfulSignup(props: Props) {
  const { email } = props;
  return (
    <>
      <Global styles={{ body: { backgroundColor: AppColors.body } }} />
      <Box
        width="100%"
        height="100vh"
        display="flex"
        alignItems={{ base: "flex-start", lg: "center" }}
        justifyContent="center"
        paddingBottom={10}
      >
        <Box width="640px">
          <Box justifyContent="center" display="flex">
            <Image src={CSLogo} alt="Creatives Scale" marginBottom={12} marginTop={12} maxWidth="250px" />
          </Box>
          <Box textAlign="center" marginBottom={8}>
            <Text as="h1" fontSize="3xl" fontWeight="bold">
              Confirm your email
            </Text>
          </Box>
          <Box textAlign="center" color="#979797">
            <Text textAlign="center">
              We&apos;ve sent an email to <strong style={{ color: "#333333" }}>{email}</strong>
            </Text>
            <Text textAlign="center">
              Click the link in the email to confirm your address and start creating today!
            </Text>
          </Box>
          <Divider marginTop={12} marginBottom={12} />
          <Box textAlign="center" color="#979797">
            <Text fontSize="sm">
              Can&apos;t see the email? Check your spam folder or login to resend the confirmation email.
            </Text>
          </Box>
        </Box>
      </Box>
    </>
  );
}
