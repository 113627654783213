import { Box, Switch } from "@chakra-ui/react";
import { ContentContainer } from "../../components/content-container";
import { SecondaryButton } from "../../components/buttons";
import { PanelGroup, PanelTop, PanelBottom } from "../../components/panels";
import { PageHeading } from "../../components/page-heading";
import { LayoutBase } from "../../components/layout/layout-base";
import {
  Table,
  TableHead,
  TableRow,
  TableHeaderCell,
  TableBody,
  TableCell,
  TableFoot,
  TablePagination,
} from "../../components/table";
import { PrimaryIconButton } from "../../components/buttons/primary-icon-button";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { useAdminWritingSampleTopics } from "./use-admin-writing-sample-topics";
import { TruncatedText } from "../../components/truncated-text";
import { TopicDrawer } from "./topic-drawer";

export function AdminWritingSampleTopicsRoute() {
  const {
    loading,
    handleAddTopic,
    handleEditTopic,
    selectedWritingSampleTopic,
    isDrawerOpen,
    handleCancelDrawer,
    handleDrawerSaved,
    writingSampleTopics,
    pagination,
    totalCount,
    handleIsActiveChange,
  } = useAdminWritingSampleTopics();

  return (
    <LayoutBase pageTitle="Writing Sample Topics">
      <TopicDrawer
        isOpen={isDrawerOpen}
        onClose={handleCancelDrawer}
        onSaved={handleDrawerSaved}
        writingSampleTopic={selectedWritingSampleTopic}
      />
      <ContentContainer centerHorizontally>
        <PageHeading>Writing Sample Topics</PageHeading>
        <PanelGroup>
          <PanelTop showBottomBorder>
            <Box display="flex" justifyContent="space-between">
              <Box></Box>
              <SecondaryButton onClick={handleAddTopic}>Add Topic</SecondaryButton>
            </Box>
          </PanelTop>
          <PanelBottom containsTable>
            <Table isLoading={loading} isEmpty={writingSampleTopics.length === 0}>
              <TableHead>
                <TableRow key="head">
                  <TableHeaderCell>Topic</TableHeaderCell>
                  <TableHeaderCell>Category</TableHeaderCell>
                  <TableHeaderCell>Active</TableHeaderCell>
                  <TableHeaderCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {writingSampleTopics.map((writingSampleTopic) => (
                  <TableRow key={writingSampleTopic.id}>
                    <TableCell>
                      <TruncatedText maxLength={60}>{writingSampleTopic.topic}</TruncatedText>
                    </TableCell>
                    <TableCell>{writingSampleTopic.category?.name ?? "-"}</TableCell>
                    <TableCell>
                      <Switch
                        isChecked={writingSampleTopic.isActive}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          handleIsActiveChange(writingSampleTopic.id, e.target.checked);
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <Box textAlign="right">
                        <PrimaryIconButton
                          onClick={() => {
                            handleEditTopic(writingSampleTopic.id);
                          }}
                          icon={faPencilAlt}
                          actionName="Edit"
                        />
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableFoot>
                <TablePagination totalCount={totalCount} {...pagination} />
              </TableFoot>
            </Table>
          </PanelBottom>
        </PanelGroup>
      </ContentContainer>
    </LayoutBase>
  );
}
