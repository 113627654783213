import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { DelimitedArrayParam, NumberParam, StringParam, useQueryParam, withDefault } from "use-query-params";

import { useTeamMembersQuery } from "../../generated/graphql";
import { ONBOARDING_STATUS, PAGE_LIMIT } from "../../constants";

export function useAdminTeamMembers() {
  const location = useLocation();

  const [searchValue, setSearchValueParam] = useQueryParam("search", StringParam);
  const [pageOffset, setPageOffset] = useQueryParam("pageOffset", withDefault(NumberParam, 0));
  const [filter, setFilter] = useQueryParam("filter", withDefault(DelimitedArrayParam, []));
  const [onboardingStatusIds, setOnboardingStatusIds] = useQueryParam(
    "onboardingStatusIds",
    withDefault(DelimitedArrayParam, [])
  );

  const queryResult = useTeamMembersQuery({
    variables: {
      limit: PAGE_LIMIT,
      offset: pageOffset,
      search: searchValue,
      isAdmin: (filter as string[]).includes("isAdmin") ? true : undefined,
      onboardingStatusIds: onboardingStatusIds as string[],
    },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (location.search === "") {
      setOnboardingStatusIds([ONBOARDING_STATUS.COMPLETED.id]);
    }
  }, [location.search, setOnboardingStatusIds]);

  function onSearch(value: string) {
    if (value === "") {
      setSearchValueParam(undefined);
    } else {
      setSearchValueParam(value);
    }
  }

  function onNextPage() {
    setPageOffset(pageOffset + PAGE_LIMIT);
  }

  function onPreviousPage() {
    setPageOffset(Math.max(pageOffset - PAGE_LIMIT, 0));
  }

  function onFilter(value: any) {
    setPageOffset(0);
    setFilter(value);
  }

  function onFilterOnboardingStatusId(value: any) {
    if ((onboardingStatusIds as string[])[0] === value) {
      setOnboardingStatusIds([]);
    } else {
      setOnboardingStatusIds([value]);
    }

    setPageOffset(0);
  }

  return {
    onSearch,
    onNextPage,
    onPreviousPage,
    onFilter,
    onFilterOnboardingStatusId,
    queryResult,
    searchValue,
    filter: filter as string[],
    onboardingStatusIds: onboardingStatusIds as string[],
  };
}
