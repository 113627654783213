import { Text } from "@chakra-ui/react";
import React from "react";
import { CopyLinkButton } from "../copy-link-button";
import { TableCell, TableRow } from "../table";

interface Props {
  label: string;
  value: React.ReactNode;
  copyValue?: string;
}

export function DataDisplayRow(props: Props) {
  const { label, value, copyValue } = props;
  return (
    <TableRow key={label}>
      <TableCell>
        <Text fontSize="sm">
          <strong>{label}</strong>
        </Text>
      </TableCell>
      <TableCell>{value}</TableCell>
      <TableCell>{copyValue && <CopyLinkButton valueToCopy={copyValue} />}</TableCell>
    </TableRow>
  );
}
