import { useToast } from "@chakra-ui/react";
import { useFormik } from "formik";
import { useContentStrategyV2_UpdateCustomerProposalMutation } from "../../../../generated/graphql";
import { handleGraphQLResponseError } from "../../../../util/error-helper";
import { processNullableString } from "../../../../util/form-helpers";

interface Options {
  customerProposalId: number;
  summary?: string | null;
}

interface FormValues {
  summary: string;
}

export function useSummary(options: Options) {
  const { customerProposalId, summary } = options;

  const [updateCustomerProposal] = useContentStrategyV2_UpdateCustomerProposalMutation();
  const toast = useToast();
  const formik = useFormik<FormValues>({
    initialValues: {
      summary: summary ?? "",
    },
    enableReinitialize: true,
    onSubmit: async (values, helpers) => {
      try {
        const response = await updateCustomerProposal({
          variables: {
            input: {
              customerProposalId,
              summary: processNullableString(values.summary),
            },
          },
        });
        if (!response.data?.updateCustomerProposal.ok) {
          throw new Error(
            response.data?.updateCustomerProposal.error?.message ??
              "Something went wrong while saving. Please try again."
          );
        }
      } catch (e: any) {
        handleGraphQLResponseError(e, toast, helpers.setErrors);
      }
    },
  });

  function handleCancel() {
    formik.resetForm();
  }

  return {
    customerProposalId,
    formik,
    handleCancel,
  };
}
