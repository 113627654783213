import { Box, Image, Text, Tooltip } from "@chakra-ui/react";
import { Global } from "@emotion/react";
import FilterSVG from "../../assets/filter.svg";
import { StripeActionBar } from "../../common/stripe-action-bar";
import { PrimaryButton, SecondaryButton } from "../../components/buttons";
import { ContentContainer } from "../../components/content-container";
import { MainNav } from "../../components/main-nav";
import { PageHeading } from "../../components/page-heading";
import { PanelBottom, PanelGroup, PanelTop } from "../../components/panels";
import SearchBox from "../../components/search-box";
import {
  Table,
  TableBody,
  TableCell,
  TableFoot,
  TableHead,
  TableHeaderCell,
  TablePagination,
  TableRow,
} from "../../components/table";
import { AppColors } from "../../core/custom-theme";
import { currencyFormatter } from "../../util/formatter";
import FilterDrawer from "./filter-drawer";
import { useFindTasks } from "./find-tasks-hook";
import { Loader } from "../../components/loader";
import { LOADER_TYPE } from "../../constants";

export function FindTasksRoute() {
  const {
    loading,
    pagination,
    onCategoryIdsChange,
    onCloseFilterDrawer,
    onProductTaskTypesChange,
    onTaskTypeIdsChange,
    onSearch,
    onShowFilterDrawer,
    showFilterDrawer,
    tasks,
    totalCount,
    claimTaskId,
    claimTaskLoading,
    onClaimTask,
    categoryIds,
    productTaskTypes,
    taskTypeIds,
    isFulfilledByRCMWriter,
    onIsRCMWriterChange,
  } = useFindTasks();

  return (
    <>
      <Global styles={{ body: { backgroundColor: AppColors.body } }} />
      <MainNav />
      <ContentContainer centerHorizontally>
        <StripeActionBar />
        <PageHeading>Find Tasks</PageHeading>
        <PanelGroup>
          <PanelTop>
            <Box display="flex" justifyContent="space-between">
              <Box>
                <SearchBox onChange={onSearch} placeholder="Search" />
              </Box>
              <Box display="flex">
                <Box marginX={2}>
                  <SecondaryButton onClick={onShowFilterDrawer}>
                    <Box display="flex">
                      Filters <Image src={FilterSVG} ml={2} />
                    </Box>
                  </SecondaryButton>
                </Box>
              </Box>
            </Box>
          </PanelTop>
          <PanelBottom containsTable>
            {loading && <Loader type={LOADER_TYPE.table} />}
            {!loading && (
              <Table isLoading={loading} isEmpty={tasks.length === 0}>
                <TableHead>
                  <TableRow key="head">
                    <TableHeaderCell>Task</TableHeaderCell>
                    <TableHeaderCell>Topic</TableHeaderCell>
                    <TableHeaderCell>Category</TableHeaderCell>
                    <TableHeaderCell>Company Name</TableHeaderCell>
                    <TableHeaderCell>Earnings</TableHeaderCell>
                    <TableHeaderCell>Min Word Count</TableHeaderCell>
                    <TableHeaderCell>Order ID</TableHeaderCell>
                    <TableHeaderCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tasks.map((task) => (
                    <TableRow key={task.id}>
                      <TableCell>
                        <Text isTruncated>{`${task.taskType.name}`}</Text>
                        <Text fontSize="sm">({task.product.name})</Text>
                      </TableCell>
                      <TableCell>
                        <Tooltip label={task.order.topic}>
                          <Text isTruncated maxW={{ base: 100, sm: 200, lg: 400 }}>
                            {task.order.topic}
                          </Text>
                        </Tooltip>
                      </TableCell>
                      <TableCell>
                        <Text isTruncated>{task.category?.name ?? "-"}</Text>
                      </TableCell>
                      <TableCell>
                        <Text isTruncated>{task.customer?.name ?? "-"}</Text>
                      </TableCell>
                      <TableCell>{task.paymentAmount ? currencyFormatter.format(task.paymentAmount) : "-"}</TableCell>
                      <TableCell>{task.product?.minWordCount ?? "-"}</TableCell>
                      <TableCell>{task.order.id}</TableCell>
                      <TableCell>
                        <Box textAlign="right">
                          <PrimaryButton
                            isLoading={claimTaskId === task.id && claimTaskLoading}
                            isDisabled={claimTaskLoading}
                            mr={6}
                            onClick={() => {
                              onClaimTask(task.id);
                            }}
                          >
                            Claim
                          </PrimaryButton>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                <TableFoot>
                  <TablePagination totalCount={totalCount} {...pagination} />
                </TableFoot>
              </Table>
            )}
          </PanelBottom>
        </PanelGroup>
        <FilterDrawer
          onCategoryIdsChange={onCategoryIdsChange}
          onProductTaskTypesChange={onProductTaskTypesChange}
          onTaskTypeIdsChange={onTaskTypeIdsChange}
          selectedCategoryIds={categoryIds}
          selectedProductTaskTypes={productTaskTypes ?? []}
          selectedTaskTypeIds={taskTypeIds}
          isOpen={showFilterDrawer}
          onClose={onCloseFilterDrawer}
          isFulfilledByRCMWriter={isFulfilledByRCMWriter}
          onIsRCMWriterChange={onIsRCMWriterChange}
        />
      </ContentContainer>
    </>
  );
}
