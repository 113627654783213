import { Badge } from "@chakra-ui/react";
import { CREATIVES_SCALE_USER_PAYMENT_STATUS } from "../../constants";

interface Props {
  status: {
    id: string;
    name: string;
  };
}

const COLORS = {
  [CREATIVES_SCALE_USER_PAYMENT_STATUS.SUBMITTED]: "yellow",
  [CREATIVES_SCALE_USER_PAYMENT_STATUS.PENDING]: "yellow",
  [CREATIVES_SCALE_USER_PAYMENT_STATUS.COMPLETED]: "green",
};

export function TaskPaymentStatusIndicator(props: Props) {
  const { status } = props;
  const color = COLORS[status.id];
  return <Badge colorScheme={color}>{status.name}</Badge>;
}
