import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  Stack,
  FormControl,
  FormLabel,
  Textarea,
  Input,
  Switch,
  Box,
  FormErrorMessage,
} from "@chakra-ui/react";
import { CancelButton, PrimaryButton } from "../../components/buttons";
import { useTopicDrawer } from "./use-topic-drawer";
import { AdminWritingSampleTopicFragment } from "../../generated/graphql";
import { CategorySelector } from "../../common/category-selector";

interface Props {
  writingSampleTopic: AdminWritingSampleTopicFragment | null;
  isOpen: boolean;
  onSaved: () => void;
  onClose: () => void;
}

export function TopicDrawer(props: Props) {
  const { isOpen } = props;
  const { handleClose, formik } = useTopicDrawer(props);
  return (
    <Drawer isOpen={isOpen} placement="right" onClose={handleClose} size="lg">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader borderBottomWidth="1px">Topic Details</DrawerHeader>
        <DrawerBody>
          <Stack spacing={6}>
            <FormControl isInvalid={formik.touched.topic && !!formik.errors.topic}>
              <FormLabel>Topic</FormLabel>
              <Input id="topic" value={formik.values.topic} onChange={formik.handleChange} />
              <FormErrorMessage>{formik.errors.topic}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={formik.touched.categoryId && !!formik.errors.categoryId}>
              <FormLabel>Category</FormLabel>
              <CategorySelector
                showNoCategoryOption
                onCategorySelected={(categoryId) => {
                  formik.setFieldValue("categoryId", categoryId);
                }}
                selectedCategoryId={formik.values.categoryId === 0 ? null : formik.values.categoryId}
              />
              <FormErrorMessage>{formik.errors.categoryId}</FormErrorMessage>
            </FormControl>
            <FormControl>
              <FormLabel>Is Active</FormLabel>
              <Box>
                <Switch id="isActive" isChecked={formik.values.isActive} onChange={formik.handleChange} />
              </Box>
            </FormControl>
            <FormControl isInvalid={formik.touched.primaryKeyword && !!formik.errors.primaryKeyword}>
              <FormLabel>Primary Keyword</FormLabel>
              <Input id="primaryKeyword" value={formik.values.primaryKeyword} onChange={formik.handleChange} />
              <FormErrorMessage>{formik.errors.primaryKeyword}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={formik.touched.voice && !!formik.errors.voice}>
              <FormLabel>Voice</FormLabel>
              <Input id="voice" value={formik.values.voice} onChange={formik.handleChange} />
              <FormErrorMessage>{formik.errors.voice}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={formik.touched.website && !!formik.errors.website}>
              <FormLabel>Website</FormLabel>
              <Input id="website" value={formik.values.website} onChange={formik.handleChange} />
              <FormErrorMessage>{formik.errors.website}</FormErrorMessage>
            </FormControl>
            <FormControl>
              <FormLabel>Additional Information</FormLabel>
              <Textarea
                id="additionalInformation"
                value={formik.values.additionalInformation}
                onChange={formik.handleChange}
                resize="none"
                placeholder="(optional)"
              />
            </FormControl>
          </Stack>
        </DrawerBody>
        <DrawerFooter justifyContent="flex-start" borderTopWidth="1px">
          <Stack isInline spacing={2}>
            <PrimaryButton
              onClick={() => {
                formik.handleSubmit();
              }}
              isLoading={formik.isSubmitting}
            >
              Save
            </PrimaryButton>
            <CancelButton isLoading={formik.isSubmitting} onClick={handleClose}>
              Cancel
            </CancelButton>
          </Stack>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}
