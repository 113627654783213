import { useContext } from "react";
import { Alert, Switch, useToast, Badge, Tooltip } from "@chakra-ui/react";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { DateTime } from "luxon";
import { TeamMemberListFragment, TeamMembersQueryResult, useUpdateTeamMemberMutation } from "../../generated/graphql";
import AppIcon from "../../components/app-icon";
import {
  Table,
  TableHead,
  TableRow,
  TableHeaderCell,
  TableBody,
  TablePagination,
  TableCell,
  TableFoot,
} from "../../components/table";
import { AuthContext } from "../../core/auth-manager";
import AppLink from "../../components/app-link";
import { DATE_TIME_FORMAT, ONBOARDING_STATUS, PAGE_LIMIT } from "../../constants";

interface Props {
  queryResult: TeamMembersQueryResult;
  onPreviousPage: () => void;
  onNextPage: () => void;
}

export default function TeamMemberTable(props: Props) {
  const { queryResult, onNextPage, onPreviousPage } = props;
  const { loading, data, error, refetch } = queryResult;
  const toast = useToast();
  const authContext = useContext(AuthContext);

  const [updateTeamMember] = useUpdateTeamMemberMutation();

  const totalCount = data?.creativesScaleUsers?.totalCount ?? 0;
  const teamMembers = data?.creativesScaleUsers?.nodes ?? [];

  async function onChange(teamMember: TeamMemberListFragment, input: "isEligibleForPayments" | "isAdmin", event: any) {
    const isChecked = event.target.checked;
    const response = await updateTeamMember({
      variables: {
        input: {
          id: teamMember.id,
          [input]: isChecked,
        },
      },
    });

    let title = "";
    if (input === "isEligibleForPayments") {
      title = `${teamMember.user.fullName} is now ${isChecked ? "eligible" : "ineligible"} for payments`;
    } else if (input === "isAdmin") {
      title = `Admin permissions for ${teamMember.user.fullName} have been ${isChecked ? "granted" : "revoked"}`;
    }

    if (response.data?.updateCreativesScaleUser.ok) {
      toast({
        title,
        status: "info",
      });
      await refetch();
    }
  }

  if (error) {
    return (
      <Alert status="error">
        <AppIcon icon={faExclamationTriangle} wideRightMargin />
        An error occurred while retrieving the team members.
      </Alert>
    );
  }

  return (
    <Table isLoading={loading} isEmpty={teamMembers.length === 0}>
      <TableHead>
        <TableRow key="head">
          <TableHeaderCell>Name</TableHeaderCell>
          <TableHeaderCell>Account Status</TableHeaderCell>
          <TableHeaderCell>Email Address</TableHeaderCell>
          <TableHeaderCell>Sign Up Date</TableHeaderCell>
          <TableHeaderCell>Admin</TableHeaderCell>
          <TableHeaderCell>Eligible for Payments</TableHeaderCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {teamMembers.map((teamMember) => {
          const tooltipContent = `ID: ${teamMember.id} / Email: ${teamMember.user.email}`;
          return (
            <TableRow key={teamMember.id}>
              <TableCell>
                <Tooltip aria-label={tooltipContent} label={tooltipContent} placement="top-start" hasArrow>
                  <span>
                    <AppLink to={`/admin/team-members/${teamMember.id}`}>{teamMember.user.fullName}</AppLink>
                  </span>
                </Tooltip>
              </TableCell>
              <TableCell>
                <Badge
                  colorScheme={teamMember.onboardingStatus.id === ONBOARDING_STATUS.COMPLETED.id ? "green" : undefined}
                >
                  {teamMember.onboardingStatus.name}
                </Badge>
              </TableCell>
              <TableCell>{teamMember.user.email}</TableCell>
              <TableCell>{DateTime.fromJSDate(teamMember.createdAt).toFormat(DATE_TIME_FORMAT)}</TableCell>
              <TableCell>
                <Switch
                  isDisabled={teamMember.user?.id === authContext.viewer?.user?.id}
                  isChecked={teamMember.isAdmin}
                  onChange={onChange.bind(null, teamMember, "isAdmin")}
                />
              </TableCell>
              <TableCell>
                <Switch
                  isChecked={teamMember.isEligibleForPayments}
                  onChange={onChange.bind(null, teamMember, "isEligibleForPayments")}
                />
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
      <TableFoot>
        <TablePagination
          totalCount={totalCount}
          limit={queryResult.variables?.limit ?? PAGE_LIMIT}
          offset={queryResult.variables?.offset ?? 0}
          onNextPage={onNextPage}
          onPreviousPage={onPreviousPage}
        />
      </TableFoot>
    </Table>
  );
}
