import { faUser } from "@fortawesome/free-solid-svg-icons";
import { useCustomersForSelectorQuery } from "../../generated/graphql";
import { CustomMultiSelect, CustomSingleSelect } from "../custom-selector";

interface CommonProps {
  emptyValueLabel?: string;
  isMulti?: true;
  partnerIds?: number[];
}

interface MultiProps extends CommonProps {
  selectedCustomerIds: number[];
  onCustomerSelected: (customerIds: number[]) => void;
}

interface SingleProps extends CommonProps {
  selectedCustomerId?: number | null;
  onCustomerSelected: (customerId: number) => void;
}

/** Defaults to SingleSelect */
export function CustomerSelector(props: MultiProps | SingleProps) {
  const { emptyValueLabel, partnerIds } = props;

  // type-guard
  function isMulti(props: SingleProps | MultiProps): props is MultiProps {
    return !!props.isMulti;
  }

  const { data } = useCustomersForSelectorQuery({
    variables: {
      partnerIds,
    },
    fetchPolicy: "network-only",
  });

  function onSelectedValueChange(values: string[] | string | null) {
    if (isMulti(props)) {
      props.onCustomerSelected((values as string[]).map((value) => parseInt(value, 10)));
    } else {
      props.onCustomerSelected(parseInt(values as string, 10));
    }
  }

  const items =
    data?.customers.nodes.map((node) => ({
      value: `${node.id}`,
      displayText: `${node.name}`,
    })) ?? [];

  if (isMulti(props)) {
    return (
      <CustomMultiSelect
        onChange={onSelectedValueChange}
        value={props.selectedCustomerIds.map((id) => id.toString())}
        items={items}
        selectorIcon={faUser}
        emptyValueTitle={emptyValueLabel}
        showSelectAll
        pluralizedItemName="Clients"
      />
    );
  } else {
    return (
      <CustomSingleSelect
        onChange={onSelectedValueChange}
        value={props.selectedCustomerId?.toString() ?? null}
        items={items}
        selectorIcon={faUser}
        emptyValueTitle={emptyValueLabel}
        allowEmpty
      />
    );
  }
}
