import { Box, Text, Image, Flex } from "@chakra-ui/react";
import AppLink from "../../components/app-link";
import { Global } from "@emotion/react";
import { AppColors } from "../../core/custom-theme";
import Logo from "../../assets/creatives-scale-logo-color.png";
import { PrimaryButton } from "../../components/buttons";

export function NotFoundRoute() {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems={{ base: "flex-start", lg: "center" }}
      height="100vh"
      paddingTop={4}
    >
      <Global styles={{ body: { backgroundColor: AppColors.body } }} />
      <Box p={4} mt={1} width={{ base: 350, lg: 675 }} borderRadius={4}>
        <Flex justifyContent="center" marginBottom={4}>
          <Image justifyContent="center" src={Logo} alt="Creatives Scale" maxW="250px" />
        </Flex>
        <Box marginBottom={2} textAlign="center">
          <Text as="h1" fontSize="4xl" fontWeight="bold">
            Uh oh...
          </Text>
        </Box>
        <Box textAlign="center">
          <Text>Looks like you might be lost. You have tried to reach a page that doesn&apos;t exist.</Text>
          <Box marginTop={10}>
            <AppLink to="/">
              <PrimaryButton>Back to App</PrimaryButton>
            </AppLink>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
