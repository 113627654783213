import { Global } from "@emotion/react";
import { Navigate } from "react-router-dom";
import {
  Box,
  Image,
  Grid,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Select,
  Checkbox,
  Flex,
  Text,
  Link,
  FormErrorMessage,
  Alert,
  AlertIcon,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import CSLogo from "../../assets/creatives-scale-logo-color.png";
import { PrimaryButton } from "../../components/buttons";
import AppLink from "../../components/app-link";
import { AppColors } from "../../core/custom-theme";
import { SuccessfulSignup } from "./successful-signup";
import { PanelBox } from "../../components/panels";
import { useSignup } from "./use-signup";
import { DangerIconButton } from "../../components/buttons/danger-icon-button";
import { FileDropUpload } from "../../components/file-drop-upload";

export function Signup() {
  const {
    formik,
    existingUser,
    isExistingCSUser,
    isExistingUser,
    wasSuccessful,
    resumeFile,
    onFileSelected,
    removeFile,
  } = useSignup();

  if (isExistingCSUser) {
    return <Navigate to="/" />;
  }

  if (wasSuccessful) {
    if (existingUser?.isEmailConfirmed) {
      return <Navigate to="/" />;
    }
    return <SuccessfulSignup email={formik.values.email} />;
  }

  return (
    <form onSubmit={formik.handleSubmit} noValidate>
      <Global styles={{ body: { backgroundColor: AppColors.body } }} />
      <Box width="100%" display="flex" justifyContent="center" paddingBottom={10}>
        <Box width="640px">
          <Box justifyContent="center" display="flex">
            <Image src={CSLogo} alt="Creatives Scale" marginY={12} width="250px" height="100px" objectFit="contain" />
          </Box>
          <PanelBox>
            <Stack spacing={6}>
              {isExistingUser && (
                <Alert status="info">
                  <AlertIcon />
                  <Stack spacing={2}>
                    <Text>
                      Looks like you&apos;re logged in as a Red Canyon Media User. We&apos;ve pre-filled some fields for
                      you. If you would like to signup using a different email address, please{" "}
                      <AppLink to="/logout">logout</AppLink> first.
                    </Text>
                  </Stack>
                </Alert>
              )}
              {!isExistingUser && (
                <Stack spacing={6}>
                  <Grid templateColumns="repeat(2, 1fr)" gap={6} paddingTop={4}>
                    <Box>
                      <FormControl isRequired isInvalid={!!formik.errors.firstName && formik.touched.firstName}>
                        <FormLabel>First name</FormLabel>
                        <Input
                          type="text"
                          variant="flushed"
                          id="firstName"
                          value={formik.values.firstName}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.firstName && formik.errors.firstName && (
                          <FormErrorMessage>{formik.errors.firstName}</FormErrorMessage>
                        )}
                      </FormControl>
                    </Box>
                    <Box>
                      <FormControl isRequired isInvalid={!!formik.errors.lastName && formik.touched.lastName}>
                        <FormLabel>Last name</FormLabel>
                        <Input
                          type="text"
                          variant="flushed"
                          id="lastName"
                          value={formik.values.lastName}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.lastName && formik.errors.lastName && (
                          <FormErrorMessage>{formik.errors.lastName}</FormErrorMessage>
                        )}
                      </FormControl>
                    </Box>
                  </Grid>
                </Stack>
              )}
              <FormControl
                isRequired
                isInvalid={!!formik.errors.email && formik.touched.email}
                isDisabled={isExistingUser}
              >
                <FormLabel>Email</FormLabel>
                <Input
                  type="email"
                  variant="flushed"
                  id="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.email && formik.errors.email && (
                  <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
                )}
              </FormControl>
              {!existingUser?.isPasswordSet && (
                <FormControl isRequired isInvalid={!!formik.errors.password && formik.touched.password}>
                  <FormLabel>Password</FormLabel>
                  <Input
                    type="password"
                    variant="flushed"
                    id="password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.password && formik.errors.password && (
                    <FormErrorMessage>{formik.errors.password}</FormErrorMessage>
                  )}
                </FormControl>
              )}
              {!existingUser?.isPasswordSet && (
                <FormControl isRequired isInvalid={!!formik.errors.confirmPassword && formik.touched.confirmPassword}>
                  <FormLabel>Password Confirmation</FormLabel>
                  <Input
                    type="password"
                    variant="flushed"
                    id="confirmPassword"
                    value={formik.values.confirmPassword}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.confirmPassword && formik.errors.confirmPassword && (
                    <FormErrorMessage>{formik.errors.confirmPassword}</FormErrorMessage>
                  )}
                </FormControl>
              )}
              <FormControl isInvalid={!!formik.touched.linkedInProfileUrl && !!formik.errors.linkedInProfileUrl}>
                <FormLabel>LinkedIn Profile URL</FormLabel>
                <Input
                  value={formik.values.linkedInProfileUrl ?? ""}
                  variant="flushed"
                  onChange={formik.handleChange}
                  id="linkedInProfileUrl"
                />
                <FormErrorMessage>{formik.errors.linkedInProfileUrl}</FormErrorMessage>
              </FormControl>
              <Box>
                <FileDropUpload
                  acceptedFileExtensions={{
                    "application/msword": [".doc", ".docx"],
                    "application/pdf": [".pdf"],
                  }}
                  onFileUpload={onFileSelected}
                />
                <Box textAlign="center" marginTop={1} marginBottom={2}>
                  <Text fontSize="sm" color={AppColors.textGray}>
                    .doc, .docx, and .pdf files accepted (Max size 10MB)
                  </Text>
                </Box>
                {resumeFile && (
                  <InputGroup>
                    <Input variant="outline" value={resumeFile.name} />
                    <InputRightElement>
                      <DangerIconButton actionName="Remove" icon={faTimes} onClick={removeFile} />
                    </InputRightElement>
                  </InputGroup>
                )}
              </Box>
              <FormControl isRequired>
                <FormLabel>Are you authorized to work in the United States?</FormLabel>
                <Select
                  variant="flushed"
                  id="isAuthorizedToDoWorkInUS"
                  value={formik.values.isAuthorizedToDoWorkInUS}
                  onChange={formik.handleChange}
                >
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                </Select>
              </FormControl>
              <FormControl marginTop={10} isInvalid={!!formik.errors.isTermsAccepted && formik.touched.isTermsAccepted}>
                <Checkbox
                  isChecked={formik.values.isTermsAccepted}
                  id="isTermsAccepted"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                >
                  <Text fontSize="sm" color="black">
                    I have read and agreed to Creatives Scale{" "}
                    <Link color={AppColors.blue} isExternal href="https://www.creativesscale.com/terms-and-conditions/">
                      Terms and Conditions
                    </Link>
                    ,{" "}
                    <Link color={AppColors.blue} isExternal href="https://www.creativesscale.com/privacy-policy/">
                      Privacy Policy
                    </Link>
                    , and{" "}
                    <Link color={AppColors.blue} isExternal href="https://www.creativesscale.com/services-agreement/">
                      Services Agreement
                    </Link>
                    .
                  </Text>
                </Checkbox>
                {formik.touched.isTermsAccepted && formik.errors.isTermsAccepted && (
                  <FormErrorMessage>{formik.errors.isTermsAccepted}</FormErrorMessage>
                )}
              </FormControl>
              <Flex justifyContent="center">
                <Box textAlign="center">
                  <PrimaryButton
                    paddingX={20}
                    type="submit"
                    isDisabled={formik.isSubmitting}
                    isLoading={formik.isSubmitting}
                    loadingText="Signing up..."
                  >
                    Let&apos;s get started
                  </PrimaryButton>
                  {!isExistingUser && (
                    <Text fontSize="sm" color={AppColors.textGray} marginTop={2}>
                      Already have an account? <AppLink to="/login">Login here</AppLink>
                    </Text>
                  )}
                </Box>
              </Flex>
            </Stack>
          </PanelBox>
        </Box>
      </Box>
    </form>
  );
}
