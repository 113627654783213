import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useToast } from "@chakra-ui/react";
import * as yup from "yup";
import { TaskWorkspaceCommonProps } from "../../task-workspace-common-props";
import { useGbpWorkspace_SaveTaskGbpItemMutation, useGbpWorkspaceQuery, File } from "../../../../generated/graphql";
import { getDisplayMessageForError } from "../../../../util/error-helper";
import { useDebounce } from "../../../../util/use-debounce";
import { DEBOUNCE } from "../../../../constants";
import { UploadFile } from "../../../../common/upload-file-modal/use-upload-file-modal";

type ServerFile = Pick<File, "id" | "name" | "url">;

interface FormValues {
  content: string;
  readMoreUrl?: string | null;
  file?: ServerFile | null;
}

export function useGbp(options: TaskWorkspaceCommonProps) {
  const { onComplete } = options;
  const [saveTaskGbpItem] = useGbpWorkspace_SaveTaskGbpItemMutation();
  const queryResult = useGbpWorkspaceQuery({
    fetchPolicy: "no-cache",
    variables: {
      taskId: options.task.id,
      ignoreUserTaskPermission: true,
    },
  });
  const toast = useToast();
  const [showThumbnailUpload, setShowThumbnailUpload] = useState(false);
  const [lastAutosavedAt, setLastAutosavedAt] = useState<Date | null>(null);
  const [needsAutosave, setNeedsAutosave] = useState(false);
  const [gbpItemValues, setGbpItemValues] = useState<{
    readMoreUrl?: string | null;
    content: string;
    file?: ServerFile | null;
  }>({
    readMoreUrl: "",
    content: "",
    file: undefined,
  });

  const task = queryResult.data?.task ?? null;

  const formik = useFormik<FormValues>({
    initialValues: {
      content: task?.gbpItem?.content ?? "",
      readMoreUrl: task?.gbpItem?.readMoreUrl ?? "",
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        await saveTaskGbpItem({
          variables: {
            input: {
              taskId: options.task.id,
              content: values.content,
              readMoreUrl: values.readMoreUrl,
              thumbnailS3FileId: values.file?.id,
            },
          },
        });
      } catch (e: any) {
        toast({ title: "Unable to Save Gbp", description: getDisplayMessageForError(e), status: "error" });
        return;
      }
      try {
        await onComplete();
      } catch (e: any) {
        toast({ title: "Unable to Complete Task", description: getDisplayMessageForError(e), status: "error" });
        return;
      }
    },
    validationSchema: yup.object().shape({
      content: yup.string().label("Content").required().min(150).nullable(false),
    }),
  });

  const debouncedGbpItemValues = useDebounce(gbpItemValues, DEBOUNCE);

  useEffect(() => {
    async function autoSaveGbp() {
      if (task && needsAutosave) {
        try {
          const response = await saveTaskGbpItem({
            variables: {
              input: {
                taskId: task.id,
                content: debouncedGbpItemValues.content,
                readMoreUrl: debouncedGbpItemValues.readMoreUrl,
                thumbnailS3FileId: debouncedGbpItemValues.file?.id,
              },
            },
          });
          if (response.data?.saveTaskGbpItem.error) {
            throw new Error("Auto save content error");
          }
          setLastAutosavedAt(new Date());
          setNeedsAutosave(false);
        } catch (e: any) {
          toast({ title: "Unable to Auto Save Gbp", description: getDisplayMessageForError(e), status: "error" });
          return;
        }
      }
    }
    autoSaveGbp();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedGbpItemValues]);

  function onGbpItemUpdate(content: string, readMoreUrl?: string | null, file?: ServerFile | null) {
    setGbpItemValues({ readMoreUrl, content, file });
    setNeedsAutosave(true);
  }

  function onThumbnailUploaded(fileId: string | null, file?: UploadFile) {
    if (fileId && file && fileId !== formik.values.file?.id) {
      const uploadedFile = { id: fileId, name: file?.name, url: file?.url };
      onGbpItemUpdate(formik.values.content, formik.values.readMoreUrl, uploadedFile);
      formik.setFieldValue("file", uploadedFile);
    }
    setShowThumbnailUpload(false);
  }

  function onThumbnailRemove() {
    onGbpItemUpdate(formik.values.content, formik.values.readMoreUrl, null);
    formik.setFieldValue("file", null);
  }

  function onThumbnailUpload() {
    setShowThumbnailUpload(true);
  }

  function onThumbnailUploadCancel() {
    setShowThumbnailUpload(false);
  }

  return {
    loading: queryResult.loading,
    task,
    formik,
    lastAutosavedAt,
    onGbpItemUpdate,
    needsAutosave,
    showThumbnailUpload,
    onThumbnailRemove,
    onThumbnailUpload,
    onThumbnailUploaded,
    onThumbnailUploadCancel,
  };
}
