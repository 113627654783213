import { BoxProps, Box, GridProps, Grid, Flex } from "@chakra-ui/react";

export function JourneyStepIconBox(props: BoxProps) {
  return <Box width="40px" {...props} />;
}

export function JourneyStepContainer(props: GridProps) {
  return (
    <Flex justifyContent="center">
      <Grid
        templateColumns={{ base: "1fr", md: "350px 180px 180px" }}
        width="100%"
        gap={{ base: 2, md: 6 }}
        alignItems="start"
        marginLeft={{ base: 0, md: 32 }}
        {...props}
      />
    </Flex>
  );
}
