import { GraphQLError } from "graphql";
import { ApolloError } from "@apollo/client";
import { FormikErrors } from "formik";
import { UseToastOptions } from "@chakra-ui/react";

interface GraphQLResponseInputFieldError {
  fieldName: string;
  message: string;
}

interface GraphQLResponseError {
  message: string;
  inputFieldErrors: GraphQLResponseInputFieldError[];
}

export function getDisplayMessageForError(err: Error): string {
  console.dir(err);
  const errorMessages: string[] = [];

  if ((err as any).extensions) {
    const graphQLError = err as GraphQLError;
    const validationErrors = (graphQLError.extensions?.exception as any)?.errors;
    if (validationErrors && validationErrors.length > 0) {
      errorMessages.push(...validationErrors);
    } else if (graphQLError.message) {
      errorMessages.push(graphQLError.message);
    }
  } else if ((err as any).graphQLErrors) {
    const graphQLError = err as ApolloError;
    const validationErrors = (graphQLError.graphQLErrors[0]?.extensions?.exception as any)?.errors;
    if (validationErrors && validationErrors.length > 0) {
      errorMessages.push(...validationErrors);
    } else if (graphQLError.graphQLErrors[0]?.message) {
      errorMessages.push(graphQLError.graphQLErrors[0].message);
    }
  }

  if (errorMessages.length === 0) {
    return err.message;
  } else {
    return errorMessages.join(" and ");
  }
}

export function handleGraphQLResponseError(
  responseError: GraphQLResponseError,
  toast: (props: UseToastOptions) => void,
  formikSetErrors?: (errors: FormikErrors<unknown>) => void
): void {
  if (!formikSetErrors || responseError.inputFieldErrors.length === 0) {
    toast({ title: "Error", description: responseError.message, status: "error" });
    return;
  }
  const formikErrors = responseError.inputFieldErrors.reduce(
    (prev, inputFieldError) => ({ ...prev, [inputFieldError.fieldName]: inputFieldError.message }),
    {}
  );
  formikSetErrors(formikErrors);
}
