import ReactHelmet from "react-helmet";
import { Global } from "@emotion/react";
import { AppColors } from "../../core/custom-theme";
import { MainNav } from "../main-nav";
import { ContentContainer } from "../content-container";
import { BoxProps } from "@chakra-ui/react";

interface Props extends BoxProps {
  pageTitle: string;
}

export function LayoutBase(props: Props) {
  const { pageTitle, ...rest } = props;
  return (
    <>
      <ReactHelmet>
        <title>{pageTitle}</title>
      </ReactHelmet>
      <Global styles={{ body: { backgroundColor: AppColors.body } }} />
      <MainNav />
      <ContentContainer centerHorizontally marginBottom={8} {...rest} />
    </>
  );
}
