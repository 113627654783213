import { StringParam, useQueryParam } from "use-query-params";

import { useAdminTasksQuery } from "../../generated/graphql";
import { usePagination } from "../../util/use-pagination";
import { CS_VENDOR_ID, PAGE_LIMIT, TASK_STATUS, TASK_TYPES } from "../../constants";

export function useAdminTasks() {
  const [search, setSearch] = useQueryParam("search", StringParam);
  const pagination = usePagination(PAGE_LIMIT);
  const queryResult = useAdminTasksQuery({
    fetchPolicy: "network-only",
    variables: {
      limit: pagination.limit,
      offset: pagination.offset,
      statusIds: [TASK_STATUS.ASSIGNED],
      vendorIds: [CS_VENDOR_ID],
      taskTypeIds: [TASK_TYPES.CONTENT],
      search: search && search.length > 0 ? search : undefined,
    },
  });
  const tasks = queryResult.data?.tasks.nodes ?? [];

  function handleSearchChange(e: React.ChangeEvent<HTMLInputElement>) {
    setSearch(e.target.value);
    pagination.resetOffset();
  }

  return {
    loading: queryResult.loading,
    tasks,
    pagination,
    totalCount: queryResult.data?.tasks.totalCount ?? 0,
    handleSearchChange,
    search: search ?? "",
  };
}
