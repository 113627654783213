import { extendTheme } from "@chakra-ui/react";

const customTheme = extendTheme({
  fontSizes: {
    xs: ".625rem", // ~10px
    sm: ".75rem", // ~12px
    md: ".875rem", // ~14px
    lg: "1.125rem", // ~18px
    xl: "1.5rem", // ~24px
    "2xl": "2rem", // ~32px
    "3xl": "3rem", // ~48px
  },
  fonts: {
    body: `-apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif`,
    heading: `-apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif`,
    mono: `-apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif`,
  },
});

export const AppColors = {
  body: "#F5F6F8",
  blue: "#2F80ED",
  logoTurquoise: "#29E9BA",
  green: "#008000",
  white: "#FFFFFF",
  logoOrange: "#FF7901",
  logoPurple: "#D301FF",
  textOrange: "#D69E2E",
  textNormal: "#333333",
  textGray: "#BCBCBC",
  textPink: "#D200FF",
  borderColor: "#BDBDBD",
  textDarkGray: "#828282",
  textDarkGreen: "#38A169",
  error: "#EB5757",
  warning: "#DD6B20",
  success: "#38A169",
  info: "#3182CE",
  bgGray: "#D1D6DB",
};

export default customTheme;
