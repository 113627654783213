import { useContext } from "react";
import * as yup from "yup";
import { Global } from "@emotion/react";
import {
  Box,
  Image,
  FormControl,
  FormLabel,
  Input,
  Stack,
  FormErrorMessage,
  useToast,
  FormHelperText,
  Text,
} from "@chakra-ui/react";
import CSLogo from "../../assets/creatives-scale-logo-color.png";
import { useFormik, FormikHelpers } from "formik";
import { AppColors } from "../../core/custom-theme";
import { PrimaryButton } from "../../components/buttons";
import { useLoginMutation, LoginMutationResult } from "../../generated/graphql";
import { AuthContext } from "../../core/auth-manager";
import { Navigate } from "react-router-dom";
import AppLink from "../../components/app-link";
import { PanelBox } from "../../components/panels";

interface FormValues {
  email: string;
  password: string;
}

const loginSchema = yup.object().shape({
  email: yup.string().label("Email").required().email().max(255),
  password: yup.string().label("Password").required(),
});

export function Login() {
  const toast = useToast();
  const authContext = useContext(AuthContext);
  const [loginMutation] = useLoginMutation();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async function handleFormSubmit(values: FormValues, _formikHelpers: FormikHelpers<FormValues>) {
    const loginResponse = (await loginMutation({
      variables: { input: { email: values.email, password: values.password } },
    })) as LoginMutationResult;
    if (loginResponse.data?.loginUser.isLoggedIn === true) {
      await authContext.refresh();
    } else {
      toast({ title: "Login", description: "Invalid login. Please try again.", status: "error" });
    }
  }

  const formik = useFormik<FormValues>({
    initialValues: {
      email: "",
      password: "",
    },
    validateOnBlur: true,
    validationSchema: loginSchema,
    onSubmit: handleFormSubmit,
  });

  if (authContext.isLoggedIn && !!authContext.viewer?.creativesScaleUser) {
    return <Navigate to="/" />;
  }

  return (
    <>
      <Global styles={{ body: { backgroundColor: AppColors.body } }} />
      <Box
        width="100%"
        display="flex"
        justifyContent="center"
        paddingBottom={10}
        alignItems={{ base: "flex-start", lg: "center" }}
        height="100vh"
      >
        <form onSubmit={formik.handleSubmit}>
          <Box width="400px">
            <Box justifyContent="center" display="flex">
              <Image src={CSLogo} alt="Creatives Scale" marginY={8} width="250px" height="100px" objectFit="contain" />
            </Box>
            <PanelBox>
              <Stack spacing={6}>
                <FormControl isRequired isInvalid={!!formik.errors.email && formik.touched.email}>
                  <FormLabel>Email</FormLabel>
                  <Input type="email" id="email" value={formik.values.email} onChange={formik.handleChange} autoFocus />
                  {formik.touched.email && formik.errors.email && (
                    <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
                  )}
                </FormControl>
                <FormControl isRequired isInvalid={!!formik.errors.password && formik.touched.password}>
                  <FormLabel>Password</FormLabel>
                  <Input type="password" id="password" value={formik.values.password} onChange={formik.handleChange} />
                  {formik.touched.password && formik.errors.password && (
                    <FormErrorMessage>{formik.errors.password}</FormErrorMessage>
                  )}
                  <FormHelperText>
                    <AppLink to="/forgot-password">Forgot Password?</AppLink>
                  </FormHelperText>
                </FormControl>
                <Box>
                  <PrimaryButton
                    width="100%"
                    type="submit"
                    isDisabled={formik.isSubmitting}
                    isLoading={formik.isSubmitting}
                    loadingText="Logging In..."
                  >
                    Login
                  </PrimaryButton>
                </Box>
                <Text fontSize="sm" textAlign="center">
                  Not a Creatives Scale user yet? <AppLink to="/signup">Get Started</AppLink>
                </Text>
              </Stack>
            </PanelBox>
          </Box>
        </form>
      </Box>
    </>
  );
}
