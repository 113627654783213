import { useToast } from "@chakra-ui/react";

import { LayoutBase } from "../../components/layout/layout-base";
import { useTaskDetails } from "./use-task-details";
import { Loader } from "../../components/loader";
import { LOADER_TYPE } from "../../constants";
import { Navigate } from "react-router-dom";
import { TaskWorkspace } from "./task-workspace";

export function TaskDetailsRoute() {
  const { loading, task } = useTaskDetails();
  const toast = useToast();
  if (loading) {
    return (
      <LayoutBase pageTitle="Task Workspace">
        <Loader type={LOADER_TYPE.fullView} />
      </LayoutBase>
    );
  }

  if (!task) {
    toast({ title: "Invalid Task", description: "The specified task was invalid. Please try again." });
    return <Navigate to="/" />;
  }

  return (
    <LayoutBase pageTitle="Task Workspace">
      <TaskWorkspace task={task} />
    </LayoutBase>
  );
}
