import { useToast } from "@chakra-ui/react";
import { TasksDetails_TaskFragment, useTaskDetails_UpdateTaskMutation } from "../../generated/graphql";
import { TASK_STATUS, TASK_TYPES } from "../../constants";
import { Navigate, useNavigate } from "react-router-dom";
import { ContentReview } from "./workspaces/content-review";
import { TaskWorkspaceCommonProps } from "./task-workspace-common-props";
import { processNullableString } from "../../util/form-helpers";
import { getDisplayMessageForError } from "../../util/error-helper";
import { Content } from "./workspaces/content";
import { ContentStrategyV2 } from "./workspaces/content-strategy-v2";
import ContentIdeation from "./workspaces/content-ideation";
import { TitleMetaDescription } from "./workspaces/title-meta-description/title-meta-description";
import { Gbp } from "./workspaces/gbp-update";

interface Props {
  task: TasksDetails_TaskFragment;
}

export function TaskWorkspace(props: Props) {
  const { task } = props;
  const toast = useToast();
  const navigate = useNavigate();
  const [updateTaskMutation] = useTaskDetails_UpdateTaskMutation();

  if (task.status.id === TASK_STATUS.COMPLETED) {
    toast({
      title: "Completed",
      description: "This task has already been completed.",
    });
    return <Navigate to="/" />;
  }

  async function onComplete(isApproved?: boolean, reviewComment?: string) {
    await updateTaskMutation({
      variables: {
        input: {
          taskId: task.id,
          status: "completed",
          isApproved,
          reviewComment: processNullableString(reviewComment ?? ""),
          ignoreUserTaskPermission: true,
        },
      },
    });
    toast({ title: "Success", description: "Task completed.", status: "success" });
    navigate("/");
  }

  async function onAbandon() {
    try {
      await updateTaskMutation({
        variables: {
          input: {
            taskId: task.id,
            assignedUserId: null,
            ignoreUserTaskPermission: true,
          },
        },
      });
      toast({
        title: "Task Unassigned",
        description: "The task has been unassigned and returned to the queue.",
        status: "info",
      });
      navigate(-1);
    } catch (e: any) {
      toast({ title: "Unable to Unassign Task", description: getDisplayMessageForError(e), status: "error" });
    }
  }

  const commonProps: TaskWorkspaceCommonProps = {
    task,
    onComplete,
    onAbandon,
  };

  switch (task.taskType.id) {
    case TASK_TYPES.CONTENT:
      return <Content {...commonProps} />;
    case TASK_TYPES.AI_EDITED_CONTENT:
      return <Content {...commonProps} isAI={true} />;
    case TASK_TYPES.CONTENT_REVIEW:
      return <ContentReview {...commonProps} />;
    case TASK_TYPES.CONTENT_STRATEGY:
      return <ContentStrategyV2 {...commonProps} />;
    case TASK_TYPES.CONTENT_IDEATION:
      return <ContentIdeation {...commonProps} />;
    case TASK_TYPES.TITLE_TAG_META_DESCRIPTION:
      return <TitleMetaDescription {...commonProps} />;
    case TASK_TYPES.GBP_UPDATE:
      return <Gbp {...commonProps} />;
    default:
      toast({
        title: "Invalid Task",
        description: "The task specified was invalid. Please try again.",
        status: "warning",
      });
      return <Navigate to="/" />;
  }
}
