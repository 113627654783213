import { useEffect } from "react";
import { Loader } from "../../components/loader";
import { LOADER_TYPE } from "../../constants";
import { useGenerateUrlForStripeDashboardMutation } from "../../generated/graphql";
import { useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

export function StripeDashboardRoute() {
  const toast = useToast();
  const navigate = useNavigate();
  const [generateUrlForStripeDashboardMutation] = useGenerateUrlForStripeDashboardMutation();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  async function processRedirect() {
    const response = await generateUrlForStripeDashboardMutation();

    if (response.data?.generateUrlForStripeDashboard.ok && response.data.generateUrlForStripeDashboard.url) {
      window.location.assign(response.data.generateUrlForStripeDashboard.url);
    } else {
      const errorMessage =
        response.data?.generateUrlForStripeDashboard.error?.message ??
        "Something unexpectedly went wrong while redirecting you to Stripe. Please try again.";
      toast({
        title: "Something went wrong",
        description: errorMessage,
        status: "error",
      });
      navigate("/");
    }
  }

  useEffect(() => {
    processRedirect();
  }, [processRedirect]);

  return <Loader type={LOADER_TYPE.fullView} message="Redirecting to Stripe..." />;
}
