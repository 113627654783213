import { TaskWorkspaceCommonProps } from "../../task-workspace-common-props";
import {
  useContentValidationCheckSummaryQuery,
  useContentWorkspace_SaveTaskContentItemMutation,
  useContentWorkspaceQuery,
} from "../../../../generated/graphql";
import { useFormik } from "formik";
import { useToast } from "@chakra-ui/react";
import { getDisplayMessageForError } from "../../../../util/error-helper";
import { useEffect, useState } from "react";
import { useDebounce } from "../../../../util/use-debounce";
import { DEBOUNCE } from "../../../../constants";

interface FormValues {
  title: string;
  content: string;
}

export function useContent(options: TaskWorkspaceCommonProps) {
  const { onComplete } = options;
  const [saveTaskContentItemMutation] = useContentWorkspace_SaveTaskContentItemMutation();
  const queryResult = useContentWorkspaceQuery({
    fetchPolicy: "no-cache",
    variables: {
      taskId: options.task.id,
      ignoreUserTaskPermission: true,
    },
  });
  const validationQueryResult = useContentValidationCheckSummaryQuery({
    fetchPolicy: "no-cache",
    variables: {
      taskId: options.task.id,
      ignoreUserTaskPermission: true,
    },
  });
  const toast = useToast();
  const [lastAutosavedAt, setLastAutosavedAt] = useState<Date | null>(null);
  const [needsAutosave, setNeedsAutosave] = useState(false);
  const [contentItemValues, setContentItemValues] = useState<{ title: string; content: string }>({
    title: "",
    content: "",
  });
  const [selectedChecklistItems, setSelectedChecklistItems] = useState<string[]>([]);
  const [unselectedChecklistItems, setUnselectedChecklistItems] = useState<string[]>([]);

  const task = queryResult.data?.task ?? null;

  const formik = useFormik<FormValues>({
    initialValues: {
      content: task?.contentItem?.content ?? "",
      title: task?.contentItem?.title ?? "",
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      if (unselectedChecklistItems.length > 0) {
        toast({
          title: "Unable to Submit",
          description: "Please make sure all items on the checklist are checked before submitting.",
          status: "warning",
        });
        return;
      }

      try {
        await saveTaskContentItemMutation({
          variables: {
            input: {
              content: values.content,
              taskId: options.task.id,
              title: values.title,
              ignoreUserTaskPermission: true,
            },
          },
        });
      } catch (e: any) {
        toast({ title: "Unable to Save Content", description: getDisplayMessageForError(e), status: "error" });
        return;
      }
      try {
        await onComplete();
      } catch (e: any) {
        toast({ title: "Unable to Complete Task", description: getDisplayMessageForError(e), status: "error" });
        return;
      }
    },
  });

  const debouncedContentItemValues = useDebounce(contentItemValues, DEBOUNCE);

  useEffect(() => {
    async function autoSaveContent() {
      if (task && needsAutosave) {
        console.log("Autosaving");
        try {
          const response = await saveTaskContentItemMutation({
            variables: {
              input: {
                content: debouncedContentItemValues.content,
                title: debouncedContentItemValues.title,
                taskId: task.id,
                ignoreUserTaskPermission: true,
              },
            },
          });
          if (response.errors) {
            console.log("Errors in auto save", { errors: response.errors });
            throw new Error("Auto save content error");
          }
          setLastAutosavedAt(new Date());
          setNeedsAutosave(false);
          await validationQueryResult.refetch();
        } catch (e: any) {
          toast({ title: "Unable to Auto Save Content", description: getDisplayMessageForError(e), status: "error" });
          return;
        }
      }
    }
    autoSaveContent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedContentItemValues]);

  function onContentItemUpdate(title: string, content: string) {
    setContentItemValues({ title, content });
    setNeedsAutosave(true);
  }

  function onChecklistItemSelected(selectedItems: string[], unselectedItems: string[]) {
    setSelectedChecklistItems(selectedItems);
    setUnselectedChecklistItems(unselectedItems);
  }

  return {
    loading: queryResult.loading || validationQueryResult.loading,
    task,
    validationCheckSummary: validationQueryResult.data?.task?.validationCheckSummary,
    formik,
    lastAutosavedAt,
    onContentItemUpdate,
    needsAutosave,
    selectedChecklistItems,
    unselectedChecklistItems,
    onChecklistItemSelected,
  };
}
