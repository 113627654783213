import { Box, Text, Image, Flex } from "@chakra-ui/react";
import { PrimaryButton } from "../../components/buttons";
import { JourneyStepIconBox, JourneyStepContainer } from "../journey-step-common";
import CakeSVG from "../../assets/cake.svg";
import { useNavigate } from "react-router-dom";
import { AppColors } from "../../core/custom-theme";
import { ONBOARDING_STATUS } from "../../constants";

interface Props {
  onboardingStatusId: string;
}

export function JourneyStepGrabATask(props: Props) {
  const { onboardingStatusId } = props;
  const navigate = useNavigate();

  const canBegin = onboardingStatusId === ONBOARDING_STATUS.COMPLETED.id;

  async function onBegin() {
    navigate("/");
  }

  function renderActionArea() {
    return (
      <Box marginTop={{ base: 0, md: 2 }}>
        <PrimaryButton onClick={onBegin} isDisabled={!canBegin} width="100%">
          Begin
        </PrimaryButton>
      </Box>
    );
  }

  return (
    <JourneyStepContainer>
      <Box>
        <Flex>
          <JourneyStepIconBox>
            <Image src={CakeSVG} alt="Grab a task" marginTop={{ base: 1, md: 2 }} />
          </JourneyStepIconBox>
          <Text fontWeight="bold" fontSize={{ base: "md", md: "xl" }}>
            Grab a task
          </Text>
        </Flex>
        <Text marginLeft="40px" color={AppColors.textDarkGray}>
          You’re all set! Now let’s find you a task to begin your creative journey.
        </Text>
      </Box>
      {renderActionArea()}
    </JourneyStepContainer>
  );
}
