import { PanelBox } from "../../../../components/panels";
import { ContentWorkspace_TaskFragment } from "../../../../generated/graphql";
import { Stack, FormControl, FormLabel, Input, Textarea } from "@chakra-ui/react";
import { GUEST_ARTICLE_PRODUCTS, WEB_COPY_PRODUCTS, BLOG_POST_PRODUCTS } from "../../../../constants/products";

interface Props {
  task: ContentWorkspace_TaskFragment;
}

export function InformationPanel(props: Props) {
  const { task } = props;

  let businessType = task.customer.customerQuestionnaire?.customerMarketSize ?? "Not provided";
  if (task.customer.customerQuestionnaire?.customerMarketSize === "Local") {
    businessType = `${businessType} (${task.customer.customerQuestionnaire.targetArea ?? "Not specified"})`;
  }

  const isWebCopy = WEB_COPY_PRODUCTS.includes(task.order.partnerProduct.product.id);
  const isGuestArticle = GUEST_ARTICLE_PRODUCTS.includes(task.order.partnerProduct.product.id);
  const isBlogPost = BLOG_POST_PRODUCTS.includes(task.order.partnerProduct.product.id);

  return (
    <PanelBox>
      <Stack spacing={4}>
        <FormControl>
          <FormLabel>Client Instructions</FormLabel>
          <Textarea variant="flushed" isDisabled value={task.customer.instructions ?? "Not provided"} resize="none" />
        </FormControl>
        <FormControl>
          <FormLabel>Order Instructions</FormLabel>
          <Textarea variant="flushed" isDisabled value={task.order.instructions ?? "Not provided"} resize="none" />
        </FormControl>
        <FormControl>
          <FormLabel>Work Type</FormLabel>
          <Input variant="flushed" isDisabled value={task.order.partnerProduct.product.name} />
        </FormControl>
        <FormControl>
          <FormLabel>Word Count Range</FormLabel>
          <Input
            variant="flushed"
            isDisabled
            value={`${task.order.partnerProduct.product.minWordCount} - ${task.order.partnerProduct.product.maxWordCount}`}
          />
        </FormControl>
        <FormControl>
          <FormLabel>Topic</FormLabel>
          <Input variant="flushed" isDisabled value={task.order.topic ?? "Not provided"} />
        </FormControl>
        <FormControl>
          <FormLabel>Primary Keyword</FormLabel>
          <Input
            variant="flushed"
            isDisabled
            value={task.order.activeOrderContentStrategy?.customerKeyword?.name ?? "Not provided"}
          />
        </FormControl>
        <FormControl>
          <FormLabel fontWeight={700}>Landing Page URL</FormLabel>
          <Input
            variant="flushed"
            isDisabled
            value={
              task.order.activeOrderContentStrategy?.customerKeyword?.landingPageUrl ??
              task.customer.website ??
              "Not provided"
            }
          />
        </FormControl>
        <FormControl>
          <FormLabel>Halo Keyword</FormLabel>
          <Input
            variant="flushed"
            isDisabled
            value={
              (task.order.activeOrderContentStrategy?.orderHaloKeywords ?? []).length > 0
                ? task.order.activeOrderContentStrategy?.orderHaloKeywords
                    .map((orderHaloKeyword) => orderHaloKeyword.customerKeyword.name)
                    .join(", ")
                : "Not provided"
            }
          />
        </FormControl>
        {(isWebCopy || isBlogPost) && (
          <FormControl>
            <FormLabel>Call to Action Link</FormLabel>
            <Input
              variant="flushed"
              isDisabled
              value={task.order.activeOrderContentStrategy?.linkTo ?? "Not provided"}
            />
          </FormControl>
        )}
        <FormControl>
          <FormLabel>Company Name</FormLabel>
          <Input variant="flushed" isDisabled value={task.customer.name} />
        </FormControl>
        <FormControl>
          <FormLabel>Website URL</FormLabel>
          <Input variant="flushed" isDisabled value={task.customer.website ?? "Not provided"} />
        </FormControl>
        <FormControl>
          <FormLabel>About Company</FormLabel>
          <Textarea
            variant="flushed"
            isDisabled
            value={task.customer.customerQuestionnaire?.businessDescription ?? "Not provided"}
          />
        </FormControl>
        <FormControl>
          <FormLabel>Business Category</FormLabel>
          <Input variant="flushed" isDisabled value={task.category?.name ?? "Not Provided"} />
        </FormControl>
        <FormControl>
          <FormLabel>Business Type</FormLabel>
          <Input variant="flushed" isDisabled value={businessType} />
        </FormControl>
        {(isGuestArticle || isBlogPost) && (
          <FormControl>
            <FormLabel>Target Audience</FormLabel>
            <Input
              variant="flushed"
              isDisabled
              value={task.customer.customerQuestionnaire?.targetAudience ?? "Not provided"}
            />
          </FormControl>
        )}
        <FormControl>
          <FormLabel>Purpose of Content</FormLabel>
          <Input
            variant="flushed"
            isDisabled
            value={task.order.activeOrderContentStrategy?.purposeOfContent ?? "Not provided"}
          />
        </FormControl>
        <FormControl>
          <FormLabel>Voice</FormLabel>
          <Input variant="flushed" isDisabled value={task.order.activeOrderContentStrategy?.voice ?? "Not provided"} />
        </FormControl>
        {isWebCopy && (
          <FormControl>
            <FormLabel>Page Type</FormLabel>
            <Input
              variant="flushed"
              isDisabled
              value={task.order.activeOrderContentStrategy?.pageType ?? "Not provided"}
            />
          </FormControl>
        )}
        {isWebCopy && (
          <FormControl>
            <FormLabel>Web Page</FormLabel>
            <Input
              variant="flushed"
              isDisabled
              value={task.order.activeOrderContentStrategy?.focusPoints ?? "Not provided"}
            />
          </FormControl>
        )}
        {(isGuestArticle || isBlogPost) && (
          <FormControl>
            <FormLabel>Sample Content</FormLabel>
            <Input
              variant="flushed"
              isDisabled
              value={task.order.activeOrderContentStrategy?.sampleContent ?? "Not provided"}
            />
          </FormControl>
        )}
        {(isGuestArticle || isBlogPost) && (
          <FormControl>
            <FormLabel>Competitors / Not Permitted</FormLabel>
            <Input
              variant="flushed"
              isDisabled
              value={task.customer.customerQuestionnaire?.competitorWebsites ?? "Not provided"}
            />
          </FormControl>
        )}
      </Stack>
    </PanelBox>
  );
}
