import Color from "color";
import { DateTime } from "luxon";
import { faInfoCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import {
  Grid,
  Stack,
  Flex,
  Box,
  Text,
  Input,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Button,
  Alert,
  AlertIcon,
  Heading,
  Image,
  FormLabel,
  FormControl,
  FormErrorMessage,
} from "@chakra-ui/react";
import { Navigate } from "react-router-dom";
import { TaskWorkspaceCommonProps } from "../../task-workspace-common-props";
import { useGbp } from "./use-gbp-update";
import { Loader } from "../../../../components/loader";
import { PageHeading } from "../../../../components/page-heading";
import { InformationPanel } from "./information-panel";
import { PanelGroup, PanelTop, PanelMiddle, PanelBottom } from "../../../../components/panels";
import { PrimaryButton, PrimaryIconButton, SecondaryButton } from "../../../../components/buttons";
import { DangerTextButton } from "../../../../components/buttons/danger-text-button";
import { AppColors } from "../../../../core/custom-theme";
import { ContentEditor } from "../../../../components/content-editor";
import { LOADER_TYPE, HOUR_MINUTE_FORMAT, DATE_MINUTE_AT_FORMAT } from "../../../../constants";

import AppIcon from "../../../../components/app-icon";
import { UploadFileDialog } from "../../../../common/upload-file-modal";

export function Gbp(props: TaskWorkspaceCommonProps) {
  const { onAbandon } = props;
  const {
    loading,
    formik,
    task,
    lastAutosavedAt,
    onGbpItemUpdate,
    needsAutosave,
    showThumbnailUpload,
    onThumbnailRemove,
    onThumbnailUploaded,
    onThumbnailUpload,
    onThumbnailUploadCancel,
  } = useGbp(props);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  if (loading) {
    return <Loader type={LOADER_TYPE.fullView} />;
  }

  if (!task) {
    toast({ title: "Invalid Task", description: "The specified task was invalid. Please try again." });
    return <Navigate to="/" />;
  }

  function renderCountDown(expiresAt: Date) {
    return (
      <Alert status="warning" marginY={2} variant="left-accent">
        <AlertIcon />
        <Box display="flex">
          <Text>
            This task will expire on <strong>{DateTime.fromJSDate(expiresAt).toFormat(DATE_MINUTE_AT_FORMAT)}</strong>.
            Please submit for approval before then to avoid a penalty to your account.
          </Text>
        </Box>
      </Alert>
    );
  }

  if (!!task?.expiresAt && task.expiresAt < new Date()) {
    toast({
      title: "Task Expired",
      description: "The specified task is expired. This task will be in available queue.",
    });
    return <Navigate to="/" />;
  }

  return (
    <>
      <UploadFileDialog
        isOpen={showThumbnailUpload}
        onFileUploaded={onThumbnailUploaded}
        onCancel={onThumbnailUploadCancel}
        instructionText="Thumbnail must be a png or jpeg. Recommended size: 600px x 400px or smaller."
      />
      <PageHeading>Gbp</PageHeading>
      <Alert status="error" marginBottom={2}>
        <AlertIcon />
        {`We understand that AI can help aid in writing content, however anyone caught using AI for the actual content will be immediately terminated from CreativesScale.`}
      </Alert>
      {!!task?.expiresAt && renderCountDown(task.expiresAt)}
      <Grid gridTemplateColumns="1fr 1.5fr" gridGap={4}>
        <Stack spacing={4}>
          <InformationPanel task={task} />
        </Stack>
        <PanelGroup>
          <PanelMiddle>
            <Stack spacing={4}>
              <FormControl isInvalid={formik.touched.content && !!formik.errors.content}>
                <FormLabel>Content</FormLabel>
                <ContentEditor
                  onChange={(newValue) => {
                    formik.setFieldValue("content", newValue);
                    if (newValue !== formik.values.content) {
                      onGbpItemUpdate(newValue, formik.values.readMoreUrl, formik.values.file);
                    }
                  }}
                  height={500}
                  min_height={500}
                  initialValue={task.gbpItem?.content ?? ""}
                />
                <FormErrorMessage>{formik.errors.content}</FormErrorMessage>
              </FormControl>
              <FormControl>
                <FormLabel>Read More URL</FormLabel>
                <Input
                  size="lg"
                  id="readMoreUrl"
                  value={formik.values.readMoreUrl ?? ""}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    formik.handleChange(e);
                    onGbpItemUpdate(formik.values.content, e.target.value, formik.values.file);
                  }}
                />
              </FormControl>
            </Stack>
          </PanelMiddle>
          <PanelMiddle>
            <PanelTop>
              <Flex justifyContent="space-between" alignItems="center">
                <Box>
                  <Heading size="md">Thumbnail</Heading>
                  <Text fontSize="sm" color={AppColors.textGray}>
                    Recommended Size: 600px x 400px
                  </Text>
                </Box>
                <SecondaryButton onClick={onThumbnailUpload}>Upload</SecondaryButton>
              </Flex>
            </PanelTop>
            <PanelBottom>
              {formik.values.file && (
                <Box display="inline-flex" position="relative">
                  <Image
                    htmlHeight={400}
                    htmlWidth={600}
                    objectFit="cover"
                    src={formik.values.file.url}
                    alt={formik.values.file.name}
                    crossOrigin=""
                  />
                  <PrimaryIconButton
                    position="absolute"
                    top="-7px"
                    right="-15px"
                    justifyContent="flex-end"
                    paddingLeft={0}
                    paddingRight={0}
                    marginLeft={0}
                    marginRight={0}
                    height="auto"
                    backgroundColor="white"
                    outline="none"
                    minWidth="auto"
                    borderRadius="50%"
                    icon={faTimesCircle}
                    actionName="Remove Image"
                    onClick={onThumbnailRemove}
                    zIndex={1}
                  />
                </Box>
              )}
              {!formik.values.file && (
                <Text>
                  <AppIcon icon={faInfoCircle} standardRightMargin />
                  Not provided.
                </Text>
              )}
            </PanelBottom>
          </PanelMiddle>
          <PanelBottom showTopBorder>
            <Flex justifyContent="space-between" alignItems="center">
              <Box marginLeft={2}>
                {!needsAutosave && !!lastAutosavedAt && (
                  <Text fontSize="sm" color={AppColors.textGray}>
                    Last Saved {DateTime.fromJSDate(lastAutosavedAt).toFormat(HOUR_MINUTE_FORMAT)}
                  </Text>
                )}
              </Box>
              <Stack isInline spacing={2} justifyContent="flex-end">
                <DangerTextButton onClick={onOpen}>Unassign Task</DangerTextButton>
                <PrimaryButton isLoading={formik.isSubmitting} onClick={formik.submitForm}>
                  Submit
                </PrimaryButton>
              </Stack>
            </Flex>
          </PanelBottom>
        </PanelGroup>
      </Grid>
      <Modal onClose={onClose} size="xl" isOpen={isOpen} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Unassign Task?</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Unassigning a task comes with a penalty. Excessive penalties could result in restricted access or banning
            your account.
          </ModalBody>
          <ModalFooter>
            <Button fontSize="14px" paddingX="30px" paddingY="10px" marginRight="20px" onClick={onClose}>
              Cancel
            </Button>
            <Button
              color={"white"}
              backgroundColor={AppColors.error}
              _hover={{
                bg: Color(AppColors.error).darken(0.2).hex(),
              }}
              _active={{
                bg: Color(AppColors.error).darken(0.4).hex(),
              }}
              fontSize="14px"
              paddingX="30px"
              paddingY="10px"
              onClick={onAbandon}
            >
              Unassign
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
