import { PanelBox } from "../../../../components/panels";
import { ContentReviewTaskWorkspace_TaskFragment } from "../../../../generated/graphql";
import { Stack, FormControl, FormLabel, Input, Textarea, Select } from "@chakra-ui/react";

interface Props {
  task: ContentReviewTaskWorkspace_TaskFragment;
}

export function InformationPanel(props: Props) {
  const { task } = props;

  let businessType = task.customer.customerQuestionnaire?.customerMarketSize ?? "Not provided";
  if (task.customer.customerQuestionnaire?.customerMarketSize === "Local") {
    businessType = `${businessType} (${task.customer.customerQuestionnaire.targetArea ?? "Not specified"})`;
  }

  return (
    <PanelBox>
      <Stack spacing={4}>
        <FormControl>
          <FormLabel>Review Task ID</FormLabel>
          <Input variant="flushed" isDisabled value={task.reviewTargetTask?.id ?? "Not provided"} />
        </FormControl>
        <FormControl>
          <FormLabel>AI Probability</FormLabel>
          <Input
            variant="flushed"
            isDisabled
            value={
              task.reviewTargetTask?.contentItem?.probability !== null &&
              task.reviewTargetTask?.contentItem?.probability !== undefined
                ? `${Math.round(task.reviewTargetTask.contentItem.probability * 100 * 100) / 100}%`
                : "Not provided"
            }
          />
          {/*As probability is between 0-1 to make it in 100 and show 2 decimal 2 times multiply by 100 and divide by 100*/}
        </FormControl>
        <FormControl>
          <FormLabel>Client Instructions</FormLabel>
          <Textarea variant="flushed" isDisabled value={task.customer.instructions ?? "Not provided"} resize="none" />
        </FormControl>
        <FormControl>
          <FormLabel>Order Instructions</FormLabel>
          <Textarea variant="flushed" isDisabled value={task.order.instructions ?? "Not provided"} resize="none" />
        </FormControl>
        <FormControl>
          <FormLabel>Writer Name</FormLabel>
          <Input variant="flushed" isDisabled value={task.reviewTargetTask?.assignedUser?.fullName ?? "Not provided"} />
        </FormControl>
        <FormControl>
          <FormLabel>Writer Email</FormLabel>
          <Input variant="flushed" isDisabled value={task.reviewTargetTask?.assignedUser?.email ?? "Not provided"} />
        </FormControl>
        <FormControl>
          <FormLabel>Work Type</FormLabel>
          <Input variant="flushed" isDisabled value={task.order.partnerProduct.product.name} />
        </FormControl>
        <FormControl>
          <FormLabel>Company Name</FormLabel>
          <Input variant="flushed" isDisabled value={task.customer.name} />
        </FormControl>
        <FormControl>
          <FormLabel>Website URL</FormLabel>
          <Input variant="flushed" isDisabled value={task.customer.website ?? "Not provided"} />
        </FormControl>
        <FormControl>
          <FormLabel>About Company</FormLabel>
          <Textarea
            variant="flushed"
            isDisabled
            value={task.customer.customerQuestionnaire?.businessDescription ?? "Not provided"}
          />
        </FormControl>
        <FormControl>
          <FormLabel>Business Type</FormLabel>
          <Input variant="flushed" isDisabled value={businessType} />
        </FormControl>
        <FormControl>
          <FormLabel>Target Audience</FormLabel>
          <Input
            variant="flushed"
            isDisabled
            value={task.customer.customerQuestionnaire?.targetAudience ?? "Not provided"}
          />
        </FormControl>
        <FormControl>
          <FormLabel>Primary Keyword</FormLabel>
          <Input variant="flushed" isDisabled value={task.keyword ?? "Not provided"} />
        </FormControl>
        <FormControl>
          <FormLabel fontWeight={700}>Landing Page URL</FormLabel>
          <Input variant="flushed" isDisabled value={task.landingPageUrl ?? task.customer.website ?? "Not provided"} />
        </FormControl>
        <FormControl>
          <FormLabel>Topic</FormLabel>
          <Input variant="flushed" isDisabled value={task.order.topic ?? "Not provided"} />
        </FormControl>
        <FormControl>
          <FormLabel>Voice</FormLabel>
          <Input
            variant="flushed"
            isDisabled
            value={task.customer.customerQuestionnaire?.preferredVoice ?? "Not provided"}
          />
        </FormControl>
        <FormControl>
          <FormLabel>Would you like a featured image provided with onsite content?</FormLabel>
          <Select
            id="isAddImage"
            value={
              task?.customer?.customerQuestionnaire?.isAddImage !== null
                ? task?.customer?.customerQuestionnaire?.isAddImage
                  ? "1"
                  : "0"
                : ""
            }
            placeholder="Not provided"
            variant="flushed"
            isDisabled
          >
            <option value="1" key="true">
              Yes
            </option>
            <option value="0" key="false">
              No
            </option>
          </Select>
        </FormControl>
      </Stack>
    </PanelBox>
  );
}
