import {
  useContentReviewTaskWorkspaceQuery,
  useContentReview_SaveTaskContentItemMutation,
  File,
  useCreateFeedbackContentItemMutation,
  useUpdateFeedbackContentItemMutation,
  useContentReviewValidationCheckSummaryQuery,
} from "../../../../generated/graphql";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { TaskWorkspaceCommonProps } from "../../task-workspace-common-props";
import { useToast } from "@chakra-ui/react";
import { getDisplayMessageForError } from "../../../../util/error-helper";
import { useDebounce } from "../../../../util/use-debounce";
import { CLIENT_REVIEW_PRODUCTS, DEBOUNCE } from "../../../../constants";
import { UploadFile } from "../../../../common/upload-file-modal/use-upload-file-modal";
import { useViewer } from "../../../../core/auth-manager/auth-manager";

type ServerFile = Pick<File, "id" | "name" | "url">;

interface FormValues {
  title: string;
  content: string;
  file?: ServerFile | null;
  comments: string;
  isRejected: boolean;
}

interface FeedbackFormValues {
  rating: number;
  feedback: string;
}

const feedbackValidationSchema = yup.object().shape({
  rating: yup.number().label("Rating").min(0).max(5).required().nullable(false),
  feedback: yup.string().label("Feedback").notRequired().nullable(true),
});

export function useContentReview(options: TaskWorkspaceCommonProps) {
  const { onComplete } = options;
  const viewer = useViewer();
  const queryResult = useContentReviewTaskWorkspaceQuery({
    fetchPolicy: "no-cache",
    variables: {
      taskId: options.task.id,
      ignoreUserTaskPermission: true,
    },
  });
  const validationCheckSummaryQueryResult = useContentReviewValidationCheckSummaryQuery({
    fetchPolicy: "no-cache",
    variables: {
      taskId: options.task.id,
      ignoreUserTaskPermission: true,
    },
  });
  const [selectedChecklistItems, setSelectedChecklistItems] = useState<string[]>([]);
  const [unselectedChecklistItems, setUnselectedChecklistItems] = useState<string[]>([]);
  const [showThumbnailUpload, setShowThumbnailUpload] = useState(false);
  const toast = useToast();
  const [saveContentItemMutation] = useContentReview_SaveTaskContentItemMutation();
  const [lastAutosavedAt, setLastAutosavedAt] = useState<Date | null>(null);
  const [needsAutosave, setNeedsAutosave] = useState(false);
  const [contentItemValues, setContentItemValues] = useState<{
    title: string;
    content: string;
    file?: ServerFile | null;
  }>({
    title: "",
    content: "",
    file: undefined,
  });
  const [createFeedbackContentItemMutation] = useCreateFeedbackContentItemMutation();
  const [updateFeedbackContentItemMutation] = useUpdateFeedbackContentItemMutation();

  const task = queryResult.data?.task ?? null;
  const customer = task?.customer ?? null;
  const reviewTargetTask = task?.reviewTargetTask;
  const contentItem = reviewTargetTask?.contentItem;
  const productId = task?.order.partnerProduct.product.id ?? "";
  const disableReject = CLIENT_REVIEW_PRODUCTS.includes(productId);
  const reviewTasks = (reviewTargetTask?.reviewTasks.nodes ?? []).filter((reviewTask) => !!reviewTask.reviewComment);
  const haveEditAccess = !!viewer?.user?.userTaskPermissions?.find(
    (permission) =>
      (permission.productId === task?.order.partnerProduct.product.id || permission.productId === null) &&
      (permission.taskTypeId === reviewTargetTask?.taskTypeId || permission.taskTypeId === null)
  );

  const formik = useFormik<FormValues>({
    initialValues: {
      comments: "",
      content: task?.reviewTargetTask?.contentItem?.content ?? "",
      file: task?.reviewTargetTask?.contentItem?.thumbnailFile,
      isRejected: false,
      title: task?.reviewTargetTask?.contentItem?.title ?? "",
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      if (values.isRejected) {
        try {
          await onComplete(false, values.comments);
          return;
        } catch (e: any) {
          toast({ title: "Unable to Submit Rejection", description: getDisplayMessageForError(e), status: "error" });
          return;
        }
      }

      // Approval Process
      if (unselectedChecklistItems.length > 0) {
        toast({
          title: "Unable to Approve",
          description: "Please make sure all items on the checklist are checked before approving.",
          status: "warning",
        });
        return;
      }

      // Only save the content item if it has been updated
      if (
        contentItem?.title !== values.title ||
        contentItem.content !== values.content ||
        contentItem.thumbnailFile?.id !== values.file?.id
      ) {
        try {
          const reviewTargetTask = task?.reviewTargetTask;
          if (!reviewTargetTask) {
            throw new Error("Unable to find original content task.");
          }
          // TODO: Check for permission being revoked
          // // User who has revoked user permission for content review but has this task assigned to them can not save content
          // if (!haveEditAccess) {
          //   throw new Error("As your access has been revoked. You can't edit content.");
          // }
          await saveContentItemMutation({
            variables: {
              input: {
                content: values.content,
                taskId: reviewTargetTask.id,
                title: values.title,
                thumbnailS3FileId: values.file?.id ?? null,
                ignoreContentTaskPermission: true,
              },
            },
          });
        } catch (e: any) {
          toast({ title: "Unable to Save Changes", description: getDisplayMessageForError(e), status: "error" });
          return;
        }
      }

      // Submit feedback if there is rating or feedback is given
      if (feedbackContentItemFormik.values.rating > 0 || feedbackContentItemFormik.values.feedback) {
        feedbackContentItemFormik.handleSubmit();
      }

      try {
        await onComplete(true);
      } catch (e: any) {
        toast({ title: "Unable to Submit Approval", description: getDisplayMessageForError(e), status: "error" });
      }
    },
  });

  function onChecklistItemSelected(selectedItems: string[], unselectedItems: string[]) {
    setSelectedChecklistItems(selectedItems);
    setUnselectedChecklistItems(unselectedItems);
  }

  function onReject() {
    formik.setFieldValue("isRejected", true);
  }

  function cancelReject() {
    formik.setFieldValue("comments", "");
    formik.setFieldValue("isRejected", false);
  }

  const debouncedContentItemValues = useDebounce(contentItemValues, DEBOUNCE);

  useEffect(() => {
    if (reviewTargetTask && needsAutosave) {
      // User who has revoked user permission can not save content
      saveContentItemMutation({
        variables: {
          input: {
            content: debouncedContentItemValues.content,
            title: debouncedContentItemValues.title,
            thumbnailS3FileId: debouncedContentItemValues.file?.id ?? null,
            taskId: reviewTargetTask.id,
          },
        },
        // eslint-disable-next-line promise/prefer-await-to-then
      }).then(() => {
        setLastAutosavedAt(new Date());
        setNeedsAutosave(false);
        validationCheckSummaryQueryResult.refetch();
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedContentItemValues]);

  function onContentItemUpdate(title: string, content: string, file?: ServerFile | null) {
    setContentItemValues({ title, content, file });
    setNeedsAutosave(true);
  }

  function onThumbnailUpload() {
    setShowThumbnailUpload(true);
  }

  function onThumbnailUploadCancel() {
    setShowThumbnailUpload(false);
  }

  function onThumbnailUploaded(fileId: string | null, file?: UploadFile) {
    if (fileId && file && fileId !== formik.values.file?.id) {
      const uploadedFile = { id: fileId, name: file?.name, url: file?.url };
      onContentItemUpdate(formik.values.title, formik.values.content, uploadedFile);
      formik.setFieldValue("file", uploadedFile);
    }
    setShowThumbnailUpload(false);
  }

  function onThumbnailRemove() {
    onContentItemUpdate(formik.values.title, formik.values.content, null);
    formik.setFieldValue("file", null);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async function handleSubmitFeedback(values: FeedbackFormValues, _helpers: any) {
    if (!reviewTargetTask) {
      toast({ title: "Unable to Complete Task", status: "error" });
      return;
    }
    try {
      if (contentItem?.feedbackContentItems && contentItem?.feedbackContentItems.length > 0) {
        await updateFeedbackContentItemMutation({
          variables: {
            input: {
              feedbackContentItemId: contentItem?.feedbackContentItems[0].id ?? 0,
              rating: values.rating,
              feedback: values.feedback,
            },
          },
        });
      } else {
        await createFeedbackContentItemMutation({
          variables: {
            input: {
              feedback: values.feedback,
              rating: values.rating,
              contentItemId: contentItem?.id ?? 0,
            },
          },
        });
      }
    } catch (e: any) {
      toast({ title: "Unable to Log Feedback for Task", description: getDisplayMessageForError(e), status: "error" });
    }
  }

  const feedbackContentItemFormik = useFormik<FeedbackFormValues>({
    initialValues: {
      rating: contentItem?.feedbackContentItems?.[0]?.rating ?? 0,
      feedback: contentItem?.feedbackContentItems?.[0]?.feedback ?? "",
    },
    enableReinitialize: true,
    validationSchema: feedbackValidationSchema,
    onSubmit: handleSubmitFeedback,
  });

  return {
    loading: queryResult.loading,
    task,
    validationCheckSummary: validationCheckSummaryQueryResult.data?.task?.reviewTargetTask?.validationCheckSummary,
    customer,
    selectedChecklistItems,
    unselectedChecklistItems,
    onChecklistItemSelected,
    onReject,
    cancelReject,
    formik,
    reviewTasks,
    lastAutosavedAt,
    onContentItemUpdate,
    needsAutosave,
    showThumbnailUpload,
    onThumbnailUpload,
    onThumbnailUploadCancel,
    onThumbnailUploaded,
    onThumbnailRemove,
    feedbackContentItemFormik,
    haveEditAccess,
    disableReject,
  };
}
