import { useEffect } from "react";
import {
  ResponseError,
  usePaymentIdentityReminderQuery,
  useUnsubscribePaymentIdentityReminder_UpdateCsUserMutation,
} from "../../generated/graphql";
import { Loader } from "../../components/loader";
import { LOADER_TYPE } from "../../constants";
import { handleGraphQLResponseError } from "../../util/error-helper";
import { useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

export function UnsubscribePaymentIdentityReminder() {
  const { data, loading: loadingCSUser } = usePaymentIdentityReminderQuery();
  const [updateCSUser] = useUnsubscribePaymentIdentityReminder_UpdateCsUserMutation();
  const navigate = useNavigate();
  const toast = useToast();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const graphqlErrorHandler = (errors: ResponseError) =>
    handleGraphQLResponseError(
      errors ?? {
        message: "Something went wrong. Please try again.",
        inputFieldErrors: [],
      },
      toast
    );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const redirectToProfile = () => navigate("/profile");

  useEffect(() => {
    // IIFE function to update stripe reminder flag.
    (async function () {
      if (!loadingCSUser && data?.viewer.creativesScaleUser) {
        if (data.viewer.creativesScaleUser.isStripeOnboardingReminderEnabled) {
          const response = await updateCSUser({
            variables: {
              input: {
                isStripeOnboardingReminderEnabled: false,
                id: data?.viewer.creativesScaleUser?.id,
              },
            },
          });

          if (!response.data?.updateCreativesScaleUser?.ok && response.data?.updateCreativesScaleUser.error) {
            graphqlErrorHandler(response.data?.updateCreativesScaleUser?.error);
          } else {
            toast({
              title: "Reminders Disabled",
              description:
                "We will no longer remind you about completing your identity and payment verification. Please note that we are unable to process payments for work you complete until you complete it.",
              status: "info",
            });
            redirectToProfile();
          }
        } else {
          redirectToProfile();
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.viewer.creativesScaleUser, loadingCSUser]);

  return <Loader type={LOADER_TYPE.fullView} />;
}
