import { useContext } from "react";
import { AuthContext } from "../../core/auth-manager";
import { Loader } from "../../components/loader";
import { Navigate } from "react-router-dom";
import { LOADER_TYPE, ONBOARDING_STATUS } from "../../constants";

export function DefaultRoute() {
  const authContext = useContext(AuthContext);

  if (authContext.isLoading) {
    return <Loader type={LOADER_TYPE.fullView} />;
  }

  if (!authContext.viewer || !authContext.viewer.creativesScaleUser) {
    return <Navigate to="/login" />;
  }

  switch (authContext.viewer.creativesScaleUser.onboardingStatusId) {
    case ONBOARDING_STATUS.WAITING_FOR_EMAIL_CONFIRM.id:
      return <Navigate to="/email-confirmation-needed" />;
    case ONBOARDING_STATUS.COMPLETED.id:
      if (authContext.viewer.creativesScaleUser.isAdmin) {
        return <Navigate to="/admin/writing-samples" />;
      }
      return <Navigate to="/my-tasks" />;
    case ONBOARDING_STATUS.WAITING_FOR_INTEREST_SELECTION.id:
      return <Navigate to="/welcome" />;
    case ONBOARDING_STATUS.COUNTRY_NOT_YET_SUPPORTED.id:
      return <Navigate to="/unsupported-country" />;
    case ONBOARDING_STATUS.INTERESTS_NOT_YET_SUPPORTED.id:
      return <Navigate to="/not-ready-yet" />;
    default:
      return <Navigate to="/journey" />;
  }
}
