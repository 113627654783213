export const STRIPE_ACCOUNT_STATUS = {
  NOT_STARTED: {
    id: "not-started",
    name: "Onboarding Not Started",
    helpText: "Click Begin to get setup payments using Stripe.",
  },
  IN_PROGRESS: {
    id: "in-progress",
    name: "Onboarding In Progress",
    helpText: "Some information has been provided, but setup has not been completed.",
  },
  PENDING_VALIDATION: {
    id: "waiting-on-stripe-validation",
    name: "Waiting on Stripe Validation",
    helpText: "Stripe is reviewing the information provided. Should only take a minute or two.",
  },
  ADDITIONAL_INFO_NEEDED: {
    id: "additional-info-needed",
    name: "Additional Information Needed",
    helpText: "In order to complete setup, additional information is required.",
  },
  COMPLETED: {
    id: "completed",
    name: "Onboarding Completed",
    helpText: "Stripe is setup and enabled for receiving payments.",
  },
};
