import { Stack, FormControl, FormLabel, Input, Textarea } from "@chakra-ui/react";

import { PanelBox } from "../../../../components/panels";
import { ContentIdeationTaskWorkspace_TaskFragment } from "../../../../generated/graphql";

interface Props {
  task: ContentIdeationTaskWorkspace_TaskFragment;
}

export function InformationPanel(props: Props) {
  const { task } = props;

  let businessType = task.customer.customerQuestionnaire?.customerMarketSize ?? "Not provided";
  if (task.customer.customerQuestionnaire?.customerMarketSize === "Local") {
    businessType = `${businessType} (${task.customer.customerQuestionnaire.targetArea ?? "Not specified"})`;
  }

  return (
    <PanelBox>
      <Stack spacing={4}>
        <FormControl>
          <FormLabel>Client Instructions</FormLabel>
          <Textarea variant="flushed" isDisabled value={task.customer.instructions ?? "Not provided"} resize="none" />
        </FormControl>
        <FormControl>
          <FormLabel>Order Instructions</FormLabel>
          <Textarea variant="flushed" isDisabled value={task.order.instructions ?? "Not provided"} resize="none" />
        </FormControl>
        <FormControl>
          <FormLabel>Work Type</FormLabel>
          <Input variant="flushed" isDisabled value={task.order.partnerProduct.product.name} />
        </FormControl>
        <FormControl>
          <FormLabel>Topic</FormLabel>
          <Input variant="flushed" isDisabled value={task.order.topic ?? "Not provided"} />
        </FormControl>
        <FormControl>
          <FormLabel>Company Name</FormLabel>
          <Input variant="flushed" isDisabled value={task.customer.name} />
        </FormControl>
        <FormControl>
          <FormLabel>Website URL</FormLabel>
          <Input variant="flushed" isDisabled value={task.customer.website ?? "Not provided"} />
        </FormControl>
        <FormControl>
          <FormLabel>About Company</FormLabel>
          <Textarea
            variant="flushed"
            isDisabled
            value={task.customer.customerQuestionnaire?.businessDescription ?? "Not provided"}
          />
        </FormControl>
        <FormControl>
          <FormLabel>Business Type</FormLabel>
          <Input variant="flushed" isDisabled value={businessType} />
        </FormControl>
      </Stack>
    </PanelBox>
  );
}
