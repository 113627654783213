import { useContext } from "react";
import { Navigate } from "react-router-dom";
import { Global } from "@emotion/react";
import { Box, Text, Checkbox, Image, Flex, FormControl, Select, FormLabel, useToast } from "@chakra-ui/react";

import { AppColors } from "../../core/custom-theme";
import Header from "../../components/header";
import { AuthContext } from "../../core/auth-manager";
import { ONBOARDING_STATUS } from "../../constants";
import { ContentContainer } from "../../components/content-container";
import WeAreSorry from "../../assets/no-data.svg";
import {
  UpdateCreativeScaleUserInput,
  useUnsupportedCountryQuery,
  useUnsupportedCountry_UpdateCsUserMutation,
} from "../../generated/graphql";
import { handleGraphQLResponseError } from "../../util/error-helper";
import { PrimaryButtonLink } from "../../components/buttons/primary-button-link";

export function OnboardingUnsupportedCountryRoute() {
  const authContext = useContext(AuthContext);
  const [updateCSUser] = useUnsupportedCountry_UpdateCsUserMutation();
  const toast = useToast();
  const queryResult = useUnsupportedCountryQuery({
    fetchPolicy: "network-only",
  });

  const optInDate = queryResult.data?.viewer.creativesScaleUser?.optedInForUpdatesAt ?? null;
  const countryOfResidence = queryResult.data?.viewer.creativesScaleUser?.countryOfResidence ?? "";

  async function updateCreativeUser(input: UpdateCreativeScaleUserInput) {
    const response = await updateCSUser({
      variables: {
        input,
      },
    });

    queryResult.refetch();

    if (!response.data?.updateCreativesScaleUser?.ok && response.data?.updateCreativesScaleUser.error) {
      handleGraphQLResponseError(
        response.data?.updateCreativesScaleUser?.error ?? {
          message: "Something went wrong. Please try again.",
          inputFieldErrors: [],
        },
        toast
      );
    }
  }

  async function onOptsInUpdateChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (!authContext.viewer?.creativesScaleUser) {
      return;
    }
    const isOptedIn = event.target.checked;
    const input = {
      id: authContext.viewer?.creativesScaleUser?.id,
      isOptingInForUpdates: isOptedIn,
    };
    await updateCreativeUser(input);
    toast({
      title: "Preferences Saved",
      description: isOptedIn
        ? "We'll make sure to keep you updated with product and service updates."
        : "We won't send you product and service updates.",
      status: "success",
    });
  }

  async function onCountryOfResidenceChange(event: React.ChangeEvent<HTMLSelectElement>) {
    if (!authContext.viewer?.creativesScaleUser) {
      return;
    }
    const input = {
      id: authContext.viewer?.creativesScaleUser?.id,
      countryOfResidence: event.target.value,
    };
    await updateCreativeUser(input);
  }

  if (
    !authContext.isLoading &&
    authContext.viewer?.creativesScaleUser?.onboardingStatusId !== ONBOARDING_STATUS.COUNTRY_NOT_YET_SUPPORTED.id
  ) {
    return <Navigate to="/" />;
  }

  return (
    <>
      <Global styles={{ body: { backgroundColor: AppColors.body } }} />
      <Header />
      <ContentContainer centerHorizontally>
        <Box p={8}>
          <Flex justifyContent="center">
            <Image alignItems="center" src={WeAreSorry} alt="Creatives Scale" />
          </Flex>
          <Box textAlign="center">
            <Text as="h1" fontSize="4xl" fontWeight="bold" marginBottom={2}>
              We’re Sorry
            </Text>
          </Box>
          <Flex justifyContent="center">
            <Text maxWidth={675} textAlign="center">
              At this time, we are only accepting applicants who are authorized to work within the United States. If you
              would like to receive an email when your country is added to our list, please verify your country and
              agree to the terms below. Thanks again for your interest!
            </Text>
          </Flex>
          <Flex my={6} direction="column" align="center">
            <FormLabel as="legend">Country of Residence</FormLabel>
            <FormControl isRequired>
              <Select id="countryOfResidence" onChange={onCountryOfResidenceChange} value={countryOfResidence}>
                {authContext.viewer?.creativesScaleUser?.countryOfResidenceCountries?.map((country) => (
                  <option key={country.id} value={country.id}>
                    {country.name}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Flex>
          <Box textAlign="center" my={6}>
            <Checkbox id="isOptingInForUpdates" onChange={onOptsInUpdateChange} isChecked={!!optInDate}>
              <Text fontSize="sm" color={AppColors.textNormal}>
                I would like to receive product and service updates from Creatives Scale.
              </Text>
            </Checkbox>
          </Box>
          <Box textAlign="center" my={8}>
            <PrimaryButtonLink href="https://www.creativesscale.com">Back to Homepage</PrimaryButtonLink>
          </Box>
        </Box>
      </ContentContainer>
    </>
  );
}
