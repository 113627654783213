import { DateTime } from "luxon";
import { Navigate } from "react-router-dom";
import Color from "color";
import {
  Stack,
  Grid,
  Box,
  useToast,
  Alert,
  AlertIcon,
  Text,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalFooter,
  ModalBody,
  Button,
  useDisclosure,
} from "@chakra-ui/react";

import { PanelGroup, PanelBottom, PanelMiddle } from "../../../../components/panels";
import { useContentIdeation } from "./use-content-ideation";
import { ContentEditor } from "../../../../components/content-editor";
import { PrimaryButton } from "../../../../components/buttons";
import { PageHeading } from "../../../../components/page-heading";
import { DATE_MINUTE_AT_FORMAT, HOUR_MINUTE_FORMAT, LOADER_TYPE } from "../../../../constants";
import { InformationPanel } from "./information-panel";
import { AppColors } from "../../../../core/custom-theme";
import { DangerTextButton } from "../../../../components/buttons/danger-text-button";
import { Loader } from "../../../../components/loader";
import { TaskWorkspaceCommonProps } from "../../task-workspace-common-props";

export function ContentIdeation(props: TaskWorkspaceCommonProps) {
  const { onAbandon } = props;
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { loading, contentIdeation, formik, lastAutosavedAt, onContentIdeationUpdate, task } =
    useContentIdeation(props);

  if (loading) {
    return <Loader type={LOADER_TYPE.fullView} />;
  }

  if (!task) {
    toast({ title: "Invalid Task", description: "The specified task was invalid. Please try again." });
    return <Navigate to="/" />;
  }

  function renderCountDown(expiresAt: Date) {
    return (
      <Alert status="warning" marginY={2} variant="left-accent">
        <AlertIcon />
        <Box display="flex">
          <Text>
            This task will expire on <strong>{DateTime.fromJSDate(expiresAt).toFormat(DATE_MINUTE_AT_FORMAT)}</strong>.
            Please submit for approval before then to avoid a penalty to your account.
          </Text>
        </Box>
      </Alert>
    );
  }

  if (!!task?.expiresAt && task.expiresAt < new Date()) {
    toast({
      title: "Task Expired",
      description: "The specified task is expired. This task will be in available queue.",
    });
    return <Navigate to="/" />;
  }

  return (
    <>
      <PageHeading>Content Ideation Task</PageHeading>
      {!!task?.expiresAt && renderCountDown(task.expiresAt)}
      <Grid gridTemplateColumns="1fr 1.5fr" gridGap={4}>
        <Stack spacing={4}>
          <InformationPanel task={task} />
        </Stack>
        <Stack spacing={4}>
          <PanelGroup>
            <PanelMiddle>
              <ContentEditor
                onChange={(newValue) => {
                  formik.setFieldValue("idea", newValue);
                  if (newValue !== formik.values.idea) {
                    onContentIdeationUpdate(newValue);
                  }
                }}
                height={500}
                min_height={500}
                initialValue={contentIdeation?.idea ?? ""}
              />
            </PanelMiddle>
            <PanelBottom showTopBorder>
              <Flex justifyContent="space-between" alignItems="center">
                <Box marginLeft={2}>
                  {!!lastAutosavedAt && (
                    <Text fontSize="sm" color={AppColors.textGray}>
                      Last Saved {DateTime.fromJSDate(lastAutosavedAt).toFormat(HOUR_MINUTE_FORMAT)}
                    </Text>
                  )}
                </Box>
                <Stack isInline spacing={2} justifyContent="flex-end">
                  <DangerTextButton onClick={onOpen}>Unassign Task</DangerTextButton>
                  <PrimaryButton isLoading={formik.isSubmitting} onClick={formik.submitForm}>
                    Submit
                  </PrimaryButton>
                </Stack>
              </Flex>
            </PanelBottom>
          </PanelGroup>
        </Stack>
      </Grid>
      <Modal onClose={onClose} size="xl" isOpen={isOpen} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Unassign Task?</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Unassigning a task comes with a penalty. Excessive penalties could result in restricted access or banning
            your account.
          </ModalBody>
          <ModalFooter>
            <Button fontSize="14px" paddingX="30px" paddingY="10px" marginRight="20px" onClick={onClose}>
              Cancel
            </Button>
            <Button
              color={"white"}
              backgroundColor={AppColors.error}
              _hover={{
                bg: Color(AppColors.error).darken(0.2).hex(),
              }}
              _active={{
                bg: Color(AppColors.error).darken(0.4).hex(),
              }}
              fontSize="14px"
              paddingX="30px"
              paddingY="10px"
              onClick={onAbandon}
            >
              Unassign
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
