import { useToast } from "@chakra-ui/react";
import { useFormik } from "formik";
import { useGrammarTestQuestionQuery, useSaveGrammarTestQuestionMutation } from "../../generated/graphql";
import { getDisplayMessageForError } from "../../util/error-helper";

interface Options {
  grammarTestQuestionId: number | null;
  onSaved: () => void;
  onClose: () => void;
}

interface FormValues {
  prompt: string;
  isActive: boolean;
  answers: {
    id?: number;
    content: string;
    isCorrect?: boolean | null;
  }[];
}

export function useQuestionDrawer(options: Options) {
  const { grammarTestQuestionId, onClose, onSaved } = options;

  const queryResult = useGrammarTestQuestionQuery({
    fetchPolicy: "network-only",
    variables: {
      id: grammarTestQuestionId ?? 0,
    },
    skip: !grammarTestQuestionId,
  });
  const [saveGrammarTestQuestionMutation] = useSaveGrammarTestQuestionMutation();
  const toast = useToast();

  const grammarTestQuestion = queryResult.data?.grammarTestQuestion ?? null;

  const formik = useFormik<FormValues>({
    initialValues: {
      isActive: true,
      answers: grammarTestQuestion?.answers ?? [{ content: "", isCorrect: true }, { content: "" }],
      prompt: grammarTestQuestion?.prompt ?? "",
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      console.log(values);
      try {
        const response = await saveGrammarTestQuestionMutation({
          variables: {
            input: {
              answers: values.answers.map((x) => ({ id: x.id, content: x.content, isCorrect: x.isCorrect ?? false })),
              grammarTestQuestionId: grammarTestQuestionId,
              isActive: values.isActive,
              prompt: values.prompt,
            },
          },
        });
        if (response.data?.saveGrammarTestQuestion.ok) {
          onSaved();
          onClose();
          formik.resetForm();
        } else {
          throw new Error(
            response.data?.saveGrammarTestQuestion.error?.message ?? "Double check all the fields and try saving again."
          );
        }
      } catch (e: any) {
        toast({
          title: "Unable to Save Question",
          description: getDisplayMessageForError(e),
          status: "error",
        });
      }
    },
  });

  function handleClose() {
    onClose();
    formik.resetForm();
  }

  function addAdditionalAnswer() {
    if (formik.values.answers.length < 4) {
      formik.setFieldValue("answers", [...formik.values.answers, { content: "" }]);
    }
  }

  return {
    handleClose,
    formik,
    addAdditionalAnswer,
  };
}
