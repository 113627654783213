import { ReactNode, useContext } from "react";
import { AuthContext } from "../core/auth-manager";
import { Loader } from "./loader";
import { LOADER_TYPE } from "../constants";
import { Navigate } from "react-router-dom";

interface Props {
  children: ReactNode;
}

export function CSAdminRequired(props: Props) {
  const authContext = useContext(AuthContext);

  if (authContext.isLoading) {
    return <Loader type={LOADER_TYPE.fullView} />;
  }

  if (!authContext.viewer?.creativesScaleUser?.isAdmin) {
    return <Navigate to="/" />;
  }

  return <>{props.children}</>;
}
