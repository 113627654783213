import { ButtonProps, Button } from "@chakra-ui/react";
import Color from "color";

export function DangerTextButton(props: ButtonProps) {
  return (
    <Button
      variant="ghost"
      color="#FF0000"
      _hover={{
        bg: Color("red").lighten(0.9).hex(),
        color: Color("red").darken(0.2).hex(),
      }}
      _active={{
        bg: Color("red").lighten(0.8).hex(),
        color: Color("red").darken(0.3).hex(),
      }}
      fontSize="14px"
      {...props}
    />
  );
}
