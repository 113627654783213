import { Global } from "@emotion/react";
import { Box } from "@chakra-ui/react";
import { AppColors } from "../../core/custom-theme";
import { Loader } from "../../components/loader";
import { LOADER_TYPE } from "../../constants";
import { ContentContainer } from "../../components/content-container";
import { useProfileQuery } from "../../generated/graphql";
import Settings from "./settings";
import PendingBalance from "./pending-balance";
// import Security from "./security";
import PerformanceStandards from "./performance-standards";
import { PageHeading } from "../../components/page-heading";
import { MainNav } from "../../components/main-nav";
import { StripeActionBar } from "../../common/stripe-action-bar";

export function ProfileRoute() {
  const { data, loading } = useProfileQuery();

  const user = data?.viewer.user;
  return (
    <>
      <Global styles={{ body: { backgroundColor: AppColors.body } }} />
      <MainNav />
      {loading && <Loader type={LOADER_TYPE.fullView} />}
      {!loading && (
        <ContentContainer centerHorizontally>
          <StripeActionBar />
          <PageHeading>Profile</PageHeading>
          <Box display="flex" justifyContent="space-between">
            <Box width="50%" marginRight={6}>
              <Settings
                user={{
                  id: user?.id ?? 0,
                  firstName: user?.firstName ?? "",
                  lastName: user?.lastName ?? "",
                  email: user?.email ?? "",
                  timezone: user?.timezone ?? "",
                  password: "",
                  confirmPassword: "",
                  linkedInProfileUrl: user?.userProfessional?.linkedInProfileUrl ?? "",
                  resumeFile: user?.userProfessional?.resumeFile,
                  age: 0,
                  gender: "",
                  location: "",
                  emailPreference: user?.emailPreference ?? "",
                }}
              />
            </Box>
            <Box width="50%">
              <Box>
                <PendingBalance />
              </Box>
              {/**
               * 
              <Box marginTop={6}>
                <Security enabled2FA={true} />
              </Box>
               */}
              <Box marginTop={6}>
                {/* TODO: Add link to learn more and do modification in UI of Learn More */}
                <PerformanceStandards />
              </Box>
            </Box>
          </Box>
        </ContentContainer>
      )}
    </>
  );
}
