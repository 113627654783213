import { useState, useEffect } from "react";
import { BoxProps, Text } from "@chakra-ui/react";
import { DateTime, Duration } from "luxon";

interface Props extends BoxProps {
  date: Date;
  format?: string;
  expiredText?: string;
}

export function CountdownText(props: Props) {
  const { date, format, expiredText, ...rest } = props;
  const [value, setValue] = useState("");

  // eslint-disable-next-line react-hooks/exhaustive-deps
  function updateValue() {
    const currentDate = DateTime.now();
    const futureDate = DateTime.fromJSDate(date);
    const duration = futureDate.diff(currentDate).toObject().milliseconds ?? -1;
    if (duration < 0) {
      setValue(expiredText ?? "");
      return;
    }
    if (format) {
      setValue(Duration.fromMillis(duration).toFormat(format));
    } else {
      const durationObj = futureDate.diff(currentDate, ["hours", "minutes", "seconds"]);
      if (durationObj.hours) {
        setValue(durationObj.toFormat("hh:mm:ss"));
      } else {
        setValue(durationObj.toFormat("mm:ss"));
      }
    }
  }

  useEffect(() => {
    const interval = setInterval(updateValue, 1000);

    updateValue();

    return () => {
      clearInterval(interval);
    };
  }, [updateValue]);

  return <Text {...rest}>{value}</Text>;
}
