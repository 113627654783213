import { useContext } from "react";
import { Box, Image, Text, Flex, Menu, Button, MenuButton, MenuList, MenuItem } from "@chakra-ui/react";
import LogoForDesktop from "../assets/creatives-scale-logo-color-white-text.png";
import IconForMobile from "../assets/creatives-scale-icon-color-white-text.png";
import { AuthContext } from "../core/auth-manager";
import { useMediaQuery } from "../util/use-media-query";
import AppLink from "./app-link";
import AppIcon from "./app-icon";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";

export const HEADER_HEIGHT = "64px";

function Header() {
  const authContext = useContext(AuthContext);

  const isMobile = useMediaQuery("(max-width:600px)");

  return (
    <Flex
      bg="#333333"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      paddingY={4}
      paddingX={4}
      position="sticky"
      top={0}
      zIndex={999}
      height={HEADER_HEIGHT}
    >
      <Box display="flex" alignItems="center">
        <AppLink to="/">
          {!isMobile && <Image src={LogoForDesktop} alt="Creatives Scale" maxHeight="32px" />}
          {isMobile && <Image src={IconForMobile} alt="Creatives Scale" maxHeight="24px" />}
        </AppLink>
      </Box>
      <Box display="flex" justifyContent="flex-end" alignItems="center" color="white">
        <Text fontSize="14px">{authContext.viewer?.user?.fullName}</Text>
        <Menu autoSelect={false}>
          <Button
            variant="ghost"
            as={MenuButton}
            color="white"
            backgroundColor="#333333"
            _hover={{ backgroundColor: "#333333" }}
            _active={{ backgroundColor: "#333333" }}
          >
            <AppIcon icon={faEllipsisV} />
          </Button>
          <MenuList color="black">
            <MenuItem color="black" as={AppLink} {...{ to: "/welcome" }}>
              Modify Work Interests
            </MenuItem>
            <MenuItem color="black" as={AppLink} {...{ to: "/logout" }}>
              Logout
            </MenuItem>
          </MenuList>
        </Menu>
      </Box>
    </Flex>
  );
}

export default Header;
