import { Global } from "@emotion/react";
import { AppColors } from "../../core/custom-theme";
import Header from "../../components/header";
import { ContentContainer } from "../../components/content-container";
import { Text, Box, Flex, Image, Stack } from "@chakra-ui/react";
import { PrimaryButton } from "../../components/buttons";
import { useNavigate } from "react-router-dom";
import GrammarTestPassSVG from "../../assets/grammar-test-pass.svg";

export function GrammarTestPass() {
  const navigate = useNavigate();
  return (
    <>
      <Global styles={{ body: { backgroundColor: AppColors.body } }} />
      <Header />
      <ContentContainer centerHorizontally>
        <Stack spacing={8} marginTop={16}>
          <Flex justifyContent="center">
            <Image src={GrammarTestPassSVG} alt="failed-test" />
          </Flex>
          <Text as="h2" fontSize="4xl" textAlign="center" my={4} fontWeight="bold">
            All Done!
          </Text>
          <Text textAlign="center">
            {`You’ve passed the grammar portion of our test, congrats! Click the button to continue on your creative journey.`}
          </Text>
          <Box textAlign="center">
            <PrimaryButton
              onClick={() => {
                navigate("/");
              }}
              paddingX={24}
            >
              Next
            </PrimaryButton>
          </Box>
        </Stack>
      </ContentContainer>
    </>
  );
}
