import { Grid, Badge, Text, Stack, Box } from "@chakra-ui/react";
import { HelpText } from "../help-text";

interface Props {
  isValid: boolean;
  name: string;
  description: string;
  actualValue: string;
  isRequiredToPass: boolean;
}

export function ChecklistItem(props: Props) {
  const { description, isValid, name, actualValue, isRequiredToPass } = props;

  let color = "blue";
  let label = "Info";
  if (isRequiredToPass) {
    if (isValid) {
      label = "Great!";
      color = "green";
    } else {
      label = "Review";
      color = "red";
    }
  }
  return (
    <Grid gridTemplateColumns="100px 1fr">
      <Box>
        <Badge colorScheme={color}>{label}</Badge>
      </Box>
      <Stack spacing={1}>
        <Text fontSize="lg" fontWeight="bold">
          {name}
          <HelpText standardLeftMargin>{actualValue}</HelpText>
        </Text>
        <Text>{description}</Text>
      </Stack>
    </Grid>
  );
}
