import { Navigate } from "react-router-dom";
import { DateTime } from "luxon";
import { Box, Image } from "@chakra-ui/react";
import { Global } from "@emotion/react";
import { useWritingSampleQuery } from "../../generated/graphql";
import SelectTopic from "./select-topic";
import { WritingSampleWorkspace } from "./writing-sample-workspace";
import { AppColors } from "../../core/custom-theme";
import Header from "../../components/header";
import { Loader } from "../../components/loader";
import { LOADER_TYPE } from "../../constants";
import { ContentContainer } from "../../components/content-container";
import TimerSVG from "../../assets/timer.svg";
import { CountdownText } from "../../components/countdown-text";
import { useExpiration } from "../../util/use-expiration";
import { PanelBox } from "../../components/panels";

export function OnboardingWritingSampleRoute() {
  const { data, loading, refetch } = useWritingSampleQuery({
    fetchPolicy: "network-only",
  });
  const endsAt = data?.viewer.creativesScaleUser?.activeWritingSample?.endsAt;
  const [isExpired] = useExpiration(endsAt ?? DateTime.now().plus({ minutes: 60 }).toJSDate());

  if (isExpired) {
    return <Navigate to="/" />;
  }

  const writingSample = data?.viewer.creativesScaleUser?.activeWritingSample;
  const writingSampleTopics = data?.viewer.creativesScaleUser?.writingSampleTopics ?? [];

  // TODO: add proper message screen
  if (loading) {
    return <Loader type={LOADER_TYPE.fullView} />;
  }

  if (!writingSample) {
    return <Navigate to="/" />;
  }

  return (
    <>
      <Global styles={{ body: { backgroundColor: AppColors.body } }} />
      <Header />
      {loading && <Loader type={LOADER_TYPE.fullView} />}
      {!loading && (
        <ContentContainer centerHorizontally>
          <Box justifyContent="flex-end" display={{ base: "none", md: "flex" }} marginBottom={4}>
            <PanelBox padding={4} color={AppColors.textOrange} display="flex">
              <Image src={TimerSVG} />
              <CountdownText date={writingSample.endsAt} paddingLeft={4} width="75px" />
            </PanelBox>
          </Box>
          <Box>
            {writingSample?.writingSampleTopic ? (
              <WritingSampleWorkspace writingSample={writingSample} refetch={refetch} />
            ) : (
              <SelectTopic writingSampleTopics={writingSampleTopics} refetch={refetch} writingSample={writingSample} />
            )}
          </Box>
        </ContentContainer>
      )}
    </>
  );
}
