import { Heading } from "@chakra-ui/react";
import React from "react";
import { PanelBottom, PanelBox, PanelGroup, PanelTop } from "../../../../components/panels";

interface Props {
  children: React.ReactNode;
  isRevisionRequested: boolean;
}

export function ContentStrategyItemWrapper(props: Props) {
  const { children, isRevisionRequested } = props;

  if (isRevisionRequested) {
    return (
      <PanelGroup status="error">
        <PanelTop>
          <Heading size="sm">Revision Requested</Heading>
        </PanelTop>
        <PanelBottom>{children}</PanelBottom>
      </PanelGroup>
    );
  }

  return <PanelBox>{children}</PanelBox>;
}
