import { useEffect, useMemo } from "react";
import { Heading, Text, Checkbox, Stack } from "@chakra-ui/react";

import { BLOG_POST_PRODUCTS, GUEST_ARTICLE_PRODUCTS, WEB_COPY_PRODUCTS } from "../../constants/products";
import { BLOG_CHECKLIST_ITEMS, GUEST_ARTICLE_CHECKLIST_ITEMS } from "../../constants/content";
import { PanelGroup, PanelTop, PanelBottom } from "../../components/panels";
import { AppColors } from "../../core/custom-theme";

interface Props {
  productId: string;
  onChange: (selectedItems: string[], unselectedItems: string[]) => void;
  selectedItems: string[];
}

export function ChecklistPanel(props: Props) {
  const { productId, selectedItems, onChange } = props;

  const { panelTitle, checklistItems } = useMemo(() => {
    let panelTitle = "";
    const checklistItems: string[] = [];

    if (BLOG_POST_PRODUCTS.includes(productId)) {
      panelTitle = "Blog Post Checklist";
      checklistItems.push(...BLOG_CHECKLIST_ITEMS);
    } else if (GUEST_ARTICLE_PRODUCTS.includes(productId)) {
      panelTitle = "Guest Article Checklist";
      checklistItems.push(...GUEST_ARTICLE_CHECKLIST_ITEMS);
    } else if (WEB_COPY_PRODUCTS.includes(productId)) {
      panelTitle = "Website Checklist";
      checklistItems.push(...BLOG_CHECKLIST_ITEMS);
    }
    return { panelTitle, checklistItems };
  }, [productId]);

  useEffect(() => {
    onChange([], checklistItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (checklistItems.length === 0) {
    return null;
  }

  return (
    <PanelGroup>
      <PanelTop showBottomBorder>
        <Stack spacing={2}>
          <Heading size="md">{panelTitle}</Heading>
          <Text fontSize="sm" color={AppColors.textGray}>
            Check each box as it pertains to the content. Any unchecked items will automatically populate as a revision
            request when rejecting content.
          </Text>
        </Stack>
      </PanelTop>
      <PanelBottom>
        <Stack spacing={4}>
          {checklistItems.map((item, index) => (
            <Checkbox
              key={index}
              isChecked={selectedItems.includes(item)}
              onChange={(e) => {
                e.persist();
                const newItems: string[] = [];
                if (e.target.checked) {
                  newItems.push(...selectedItems, item);
                } else {
                  newItems.push(...selectedItems.filter((x) => x !== item));
                }
                const unselectedItems = checklistItems.filter((x) => !newItems.includes(x));
                onChange(newItems, unselectedItems);
              }}
            >
              {item}
            </Checkbox>
          ))}
        </Stack>
      </PanelBottom>
    </PanelGroup>
  );
}
