import { NumberParam, withDefault, useQueryParam } from "use-query-params";

export function usePagination(limit: number) {
  const [offset, setOffset] = useQueryParam("offset", withDefault(NumberParam, 0));

  function onNextPage() {
    const newOffset = (offset ?? 0) + limit;
    setOffset(newOffset);
  }

  function onPreviousPage() {
    const newOffset = Math.max((offset ?? 0) - limit, 0);
    setOffset(newOffset);
  }

  function resetOffset() {
    setOffset(undefined);
  }

  return {
    onNextPage,
    onPreviousPage,
    offset: offset ?? 0,
    limit,
    resetOffset,
  };
}
