import { useLastGrammarTestResultQuery } from "../../generated/graphql";
import { Loader } from "../../components/loader";
import { LOADER_TYPE } from "../../constants";
import { Navigate } from "react-router-dom";
import { GrammarTestPass } from "./grammar-test-pass";
import { GrammarTestFail } from "./grammar-test-fail";

export function OnboardingGrammarTestResultRoute() {
  const queryResult = useLastGrammarTestResultQuery({ fetchPolicy: "network-only" });

  if (queryResult.loading) {
    return <Loader type={LOADER_TYPE.fullView} />;
  }

  const lastGrammarTest = queryResult.data?.viewer.creativesScaleUser?.latestUserGrammarTest;

  if (!lastGrammarTest) {
    return <Navigate to="/" />;
  }

  if (lastGrammarTest.result === "pass") {
    return <GrammarTestPass />;
  } else if (lastGrammarTest.result === "fail") {
    return <GrammarTestFail />;
  } else {
    return <Navigate to="/" />;
  }
}
