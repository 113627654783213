import { useState } from "react";
import { useToast } from "@chakra-ui/react";
import { uniqBy } from "lodash";
import {
  TeamMemberProfile_ProductTaskTypeFragment,
  TeamMemberProfile_UserTaskPermissionFragment,
  useTeamMemberProfile_CreatePermissionMutation,
  useTeamMemberProfile_DeletePermissionMutation,
} from "../../generated/graphql";
import { getDisplayMessageForError } from "../../util/error-helper";
import { CS_VENDOR_ID } from "../../constants";

interface Options {
  productTaskTypes: TeamMemberProfile_ProductTaskTypeFragment[];
  userTaskPermissions: TeamMemberProfile_UserTaskPermissionFragment[];
  onPermissionsUpdated: () => void;
  userId: number;
}

export function usePermissions(options: Options) {
  const { onPermissionsUpdated, productTaskTypes, userTaskPermissions } = options;
  const [createUserTaskPermissionMutation] = useTeamMemberProfile_CreatePermissionMutation();
  const [deleteUserTaskPermissionMutation] = useTeamMemberProfile_DeletePermissionMutation();
  const toast = useToast();
  const [wildcardProductId, setWildcardProductId] = useState("");
  const [wildcardTaskTypeId, setWildcardTaskTypeId] = useState("");

  async function createUserTaskPermission(productId: string | null, taskTypeId: string | null) {
    try {
      await createUserTaskPermissionMutation({
        variables: {
          input: {
            userId: options.userId,
            vendorId: CS_VENDOR_ID,
            productId,
            taskTypeId,
          },
        },
      });
      onPermissionsUpdated();
    } catch (e: any) {
      toast({ title: "Unable to Save", description: getDisplayMessageForError(e), status: "error" });
    }
  }

  async function deleteUserTaskPermission(userTaskPermissionId: number) {
    try {
      await deleteUserTaskPermissionMutation({
        variables: {
          input: {
            userTaskPermissionId,
          },
        },
      });
      onPermissionsUpdated();
    } catch (e: any) {
      toast({ title: "Unable to Delete", description: getDisplayMessageForError(e), status: "error" });
    }
  }

  const wildcardUserTaskPermissions = userTaskPermissions.filter((x) => !x.product || !x.taskType);

  const products = uniqBy(
    productTaskTypes.map((x) => x.product),
    "id"
  );

  const taskTypes = uniqBy(
    productTaskTypes.map((x) => x.taskType),
    "id"
  );

  function handleWildcardProductChange(productId: string) {
    setWildcardProductId(productId);
    setWildcardTaskTypeId("");
  }

  function handleWildcardTaskTypeChange(taskTypeId: string) {
    setWildcardProductId("");
    setWildcardTaskTypeId(taskTypeId);
  }

  async function createWildcardUserPermission() {
    await createUserTaskPermission(
      wildcardProductId === "" ? null : wildcardProductId,
      wildcardTaskTypeId === "" ? null : wildcardTaskTypeId
    );
    setWildcardProductId("");
    setWildcardTaskTypeId("");
  }

  return {
    userTaskPermissions,
    productTaskTypes,
    wildcardUserTaskPermissions,
    createUserTaskPermission,
    deleteUserTaskPermission,
    products,
    taskTypes,
    wildcardProductId,
    wildcardTaskTypeId,
    handleWildcardProductChange,
    handleWildcardTaskTypeChange,
    createWildcardUserPermission,
  };
}
