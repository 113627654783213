import * as yup from "yup";
import { useToast } from "@chakra-ui/react";
import { useFormik } from "formik";
import {
  AdminWritingSampleTopicFragment,
  useAdminWritingSampleTopics_CreateMutation,
  useAdminWritingSampleTopics_UpdateMutation,
} from "../../generated/graphql";
import { getDisplayMessageForError } from "../../util/error-helper";
import { processNullableString } from "../../util/form-helpers";

interface Options {
  writingSampleTopic: AdminWritingSampleTopicFragment | null;
  onSaved: () => void;
  onClose: () => void;
}

interface FormValues {
  topic: string;
  isActive: boolean;
  categoryId: number;
  primaryKeyword: string;
  voice: string;
  website: string;
  additionalInformation: string;
}

export function useTopicDrawer(options: Options) {
  const { onClose, onSaved, writingSampleTopic } = options;
  const [createWritingSampleTopicMutation] = useAdminWritingSampleTopics_CreateMutation();
  const [updateWritingSampleTopicMutation] = useAdminWritingSampleTopics_UpdateMutation();
  const toast = useToast();

  const formik = useFormik<FormValues>({
    initialValues: {
      additionalInformation: "",
      categoryId: writingSampleTopic?.category?.id ?? 0,
      isActive: writingSampleTopic?.isActive ?? true,
      primaryKeyword: writingSampleTopic?.primaryKeyword ?? "",
      topic: writingSampleTopic?.topic ?? "",
      voice: writingSampleTopic?.voice ?? "",
      website: writingSampleTopic?.website ?? "",
    },
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      topic: yup.string().label("Topic").required().nullable(false),
      categoryId: yup.number().label("Category").required().nullable(false).min(1, "Category is a required field"),
      primaryKeyword: yup.string().label("Primary Keyword").required().nullable(false),
      voice: yup.string().label("Voice").required().nullable(false),
      website: yup.string().label("Website").required().nullable(false),
    }),
    onSubmit: async (values) => {
      console.log("topic drawer submit", { values });
      try {
        if (writingSampleTopic) {
          await updateWritingSampleTopicMutation({
            variables: {
              input: {
                writingSampleTopicId: writingSampleTopic.id,
                additionalInformation: processNullableString(values.additionalInformation),
                categoryId: values.categoryId,
                isActive: values.isActive,
                primaryKeyword: values.primaryKeyword,
                topic: values.topic,
                voice: values.voice,
                website: values.website,
              },
            },
          });
        } else {
          await createWritingSampleTopicMutation({
            variables: {
              input: {
                additionalInformation: processNullableString(values.additionalInformation),
                categoryId: values.categoryId,
                isActive: values.isActive,
                primaryKeyword: values.primaryKeyword,
                topic: values.topic,
                voice: values.voice,
                website: values.website,
              },
            },
          });
        }

        onSaved();
        onClose();
        formik.resetForm();
      } catch (e: any) {
        toast({
          title: "Unable to Save",
          description: getDisplayMessageForError(e),
          status: "warning",
        });
      }
    },
  });

  function handleClose() {
    formik.resetForm();
    onClose();
  }

  return {
    formik,
    handleClose,
  };
}
