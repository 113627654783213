import {
  Alert,
  Badge,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputRightAddon,
  Stack,
  Tag,
  TagLabel,
  Text,
} from "@chakra-ui/react";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { DateTime } from "luxon";
import AppIcon from "../../components/app-icon";
import { Loader } from "../../components/loader";
import { PanelBottom, PanelGroup, PanelTop } from "../../components/panels";
import { DATE_TIME_FORMAT, LOADER_TYPE, ONBOARDING_STATUS } from "../../constants";
import { AppColors } from "../../core/custom-theme";
import { TeamMemberProfileQueryResult } from "../../generated/graphql";

interface Props {
  queryResult: TeamMemberProfileQueryResult;
}

export function OnboardingStatus({ queryResult }: Props) {
  const { loading, error, data } = queryResult;
  const { creativesScaleUser } = data ?? {};

  return (
    <PanelGroup>
      <PanelTop showBottomBorder>
        <Flex justifyContent="space-between" alignItems="center">
          <Heading size="md" textAlign="left">
            Onboarding Status
          </Heading>
          <Badge
            colorScheme={
              creativesScaleUser?.onboardingStatus?.id === ONBOARDING_STATUS.COMPLETED.id ? "green" : undefined
            }
            height="fit-content"
          >
            {creativesScaleUser?.onboardingStatus.name ?? ""}
          </Badge>
        </Flex>
        <Text fontSize="sm" color={AppColors.textDarkGray}>
          {creativesScaleUser?.onboardingStatusLastUpdatedAt
            ? DateTime.fromJSDate(creativesScaleUser.onboardingStatusLastUpdatedAt).toFormat(DATE_TIME_FORMAT)
            : ""}
        </Text>
      </PanelTop>
      <PanelBottom>
        {loading && <Loader type={LOADER_TYPE.content} />}
        {error && (
          <Alert status="error">
            <AppIcon icon={faExclamationTriangle} wideRightMargin />
            An error occurred while retrieving profile.
          </Alert>
        )}
        {!loading && !error && !!creativesScaleUser && (
          <Stack spacing={4}>
            <FormControl>
              <FormLabel>Fields of Interest</FormLabel>
              <Stack spacing={2} isInline flexWrap="wrap" gridRowGap={1}>
                {creativesScaleUser?.workInterests.map((workInterest) => (
                  <Tag key={workInterest.id} size="sm" rounded="full" variant="solid" colorScheme="gray">
                    <TagLabel>{workInterest.name}</TagLabel>
                  </Tag>
                ))}
              </Stack>
            </FormControl>
            <FormControl isReadOnly>
              <FormLabel>Grammar Assessment</FormLabel>
              <InputGroup>
                <Input
                  variant="flushed"
                  value={
                    creativesScaleUser.latestUserGrammarTest?.completedAt
                      ? `Completed ${DateTime.fromJSDate(creativesScaleUser.latestUserGrammarTest.completedAt).toFormat(
                          DATE_TIME_FORMAT
                        )}`
                      : "Not Completed"
                  }
                  isDisabled
                />
                {/*TODO: show result */}
                <InputRightAddon backgroundColor={AppColors.white} borderTop={0} borderRight={0}>
                  <Badge colorScheme={creativesScaleUser?.latestUserGrammarTest?.result === "pass" ? "green" : "red"}>
                    {creativesScaleUser?.latestUserGrammarTest?.result === "pass" ? "PASS" : "FAIL"}
                  </Badge>
                </InputRightAddon>
              </InputGroup>
            </FormControl>
            <FormControl isReadOnly>
              <FormLabel>Writing Sample</FormLabel>
              <InputGroup>
                <Input
                  variant="flushed"
                  value={`${
                    creativesScaleUser?.latestWritingSample?.reviewStatus?.id === "accepted"
                      ? "Approved by: "
                      : creativesScaleUser?.latestWritingSample?.reviewStatus?.id === "rejected"
                      ? "Rejected by: "
                      : ""
                  } ${creativesScaleUser?.latestWritingSample?.reviewerUser?.fullName ?? ""}`}
                  isDisabled
                />
                <InputRightAddon backgroundColor={AppColors.white} borderTop={0} borderRight={0}>
                  <Badge
                    colorScheme={
                      creativesScaleUser?.latestWritingSample?.reviewStatus?.id === "accepted" ? "green" : "red"
                    }
                  >
                    {creativesScaleUser?.latestWritingSample?.reviewStatus?.name ?? ""}
                  </Badge>
                </InputRightAddon>
              </InputGroup>
            </FormControl>
            <FormControl isReadOnly>
              <FormLabel>Identity/Payment Verification</FormLabel>
              <InputGroup>
                <Input variant="flushed" value={creativesScaleUser?.stripeAccountId ?? ""} isDisabled />
                <InputRightAddon backgroundColor={AppColors.white} borderTop={0} borderRight={0}>
                  <Badge colorScheme={creativesScaleUser.stripeAccountId ? "green" : "yellow"}>
                    {creativesScaleUser.stripeAccountId ? "Completed" : "Waiting"}
                  </Badge>
                </InputRightAddon>
              </InputGroup>
            </FormControl>
          </Stack>
        )}
      </PanelBottom>
    </PanelGroup>
  );
}
