import { Alert, Link, Text } from "@chakra-ui/react";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { WritingSamplesQueryResult } from "../../generated/graphql";
import AppIcon from "../../components/app-icon";
import {
  Table,
  TableHead,
  TableRow,
  TableHeaderCell,
  TableBody,
  TablePagination,
  TableCell,
  TableFoot,
} from "../../components/table";
import { AppColors } from "../../core/custom-theme";
import { PAGE_LIMIT } from "../../constants";

interface Props {
  queryResult: WritingSamplesQueryResult;
  onPreviousPage: () => void;
  onNextPage: () => void;
}

export default function WritingSampleTable(props: Props) {
  const { queryResult, onNextPage, onPreviousPage } = props;
  const { loading, data, error } = queryResult;

  const totalCount = data?.userWritingSamples?.totalCount ?? 0;
  const writingSamples = data?.userWritingSamples?.nodes ?? [];

  if (error) {
    return (
      <Alert status="error">
        <AppIcon icon={faExclamationTriangle} wideRightMargin />
        An error occurred while retrieving the writing samples.
      </Alert>
    );
  }

  return (
    <Table isLoading={loading} isEmpty={writingSamples.length === 0}>
      <TableHead>
        <TableRow key="head">
          <TableHeaderCell>Title</TableHeaderCell>
          <TableHeaderCell>Category</TableHeaderCell>
          <TableHeaderCell>Topic</TableHeaderCell>
          <TableHeaderCell>Team Member</TableHeaderCell>
          <TableHeaderCell />
        </TableRow>
      </TableHead>
      <TableBody>
        {writingSamples.map((writingSample) => (
          <TableRow key={writingSample.id}>
            <TableCell>
              <Link href={`/admin/writing-samples/${writingSample.id}`} color={AppColors.blue}>
                {writingSample.contentItem?.title ?? "-"}
              </Link>
            </TableCell>
            <TableCell>{writingSample.writingSampleTopic?.category?.name ?? "-"}</TableCell>
            <TableCell>
              <Text isTruncated maxWidth={200}>
                {writingSample.writingSampleTopic?.topic ?? "-"}
              </Text>
            </TableCell>
            <TableCell>{writingSample.user.fullName}</TableCell>
            <TableCell>
              <Link href={`/admin/writing-samples/${writingSample.id}`} color={AppColors.blue}>
                View
              </Link>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
      <TableFoot>
        <TablePagination
          totalCount={totalCount}
          limit={queryResult.variables?.limit ?? PAGE_LIMIT}
          offset={queryResult.variables?.offset ?? 0}
          onNextPage={onNextPage}
          onPreviousPage={onPreviousPage}
        />
      </TableFoot>
    </Table>
  );
}
