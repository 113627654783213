import { useToast } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Papa from "papaparse";

import {
  useTitleMetaDescriptionTaskWorkspaceQuery,
  useSaveTaskTitleMetaDescriptionMutation,
} from "../../../../generated/graphql";
import { getDisplayMessageForError } from "../../../../util/error-helper";
import { TaskWorkspaceCommonProps } from "../../task-workspace-common-props";

export function useTitleMetaDescription(options: TaskWorkspaceCommonProps) {
  const { onComplete, task } = options;
  const taskWorkspaceTitleMetaDescriptionQueryResult = useTitleMetaDescriptionTaskWorkspaceQuery({
    fetchPolicy: "no-cache",
    variables: {
      taskId: task.id,
    },
  });
  const [saveTaskTitleMetaDescription, { loading: submitLoading }] = useSaveTaskTitleMetaDescriptionMutation();
  const [titleMetaDescriptions, setTitleMetaDescriptions] = useState<any>([
    {
      titleTag: "",
      metaDescription: "",
      url: "",
    },
  ]);
  const toast = useToast();

  const taskQueryResult = taskWorkspaceTitleMetaDescriptionQueryResult.data?.task ?? null;

  useEffect(() => {
    if (taskQueryResult?.titleMetaDescriptionItem?.titleMetaDescriptions) {
      const titleMetaDescriptions = taskQueryResult.titleMetaDescriptionItem.titleMetaDescriptions.map(
        (titleMetaDescription) => {
          return {
            id: titleMetaDescription.id,
            titleTag: titleMetaDescription.titleTag,
            metaDescription: titleMetaDescription.metaDescription,
            url: titleMetaDescription.url,
          };
        }
      );
      setTitleMetaDescriptions(titleMetaDescriptions);
    }
  }, [taskQueryResult]);

  async function onSubmit() {
    if (titleMetaDescriptions.length > 10) {
      toast({
        title: "Not Allowed!",
        description: "Can not enter more than 10 title and meta descriptions",
        status: "warning",
      });
    }

    let isValid = true;
    titleMetaDescriptions.forEach((titleMetaDescription: any) => {
      if (titleMetaDescription.titleTag.length > 70 || titleMetaDescription.metaDescription.length > 160) {
        isValid = false;
      }
    });
    if (!isValid) {
      toast({
        title: "Invalid Title Tag or Meta Description",
        description:
          "Title Tag should be less than 70 characters and Meta Description should be less than 160 characters",
        status: "error",
      });
      return;
    }
    try {
      const response = await saveTaskTitleMetaDescription({
        variables: {
          input: {
            taskId: task.id,
            titleMetaDescriptions,
          },
        },
      });

      if (!response.data?.saveTaskTitleMetaDescriptions.ok) {
        throw new Error(response.data?.saveTaskTitleMetaDescriptions?.error?.message ?? "Something went wrong!");
      }
      onComplete();
    } catch (e: any) {
      toast({ title: "Unable to Complete Task", description: getDisplayMessageForError(e), status: "error" });
    }
  }

  function onTitleTagChange(index: number, e: React.ChangeEvent<HTMLInputElement>) {
    const titleMetaDescriptionsClone: any = [...titleMetaDescriptions];
    titleMetaDescriptionsClone[index].titleTag = e.target.value.substring(0, 70);
    setTitleMetaDescriptions(titleMetaDescriptionsClone);
    if (e.target.value.length > 70) {
      toast({
        title: "Title Tag is too long",
        description: "Title Tag should be less than 70 characters",
        status: "error",
      });
    }
  }

  function onMetaDescriptionChange(index: number, e: React.ChangeEvent<HTMLTextAreaElement>) {
    const titleMetaDescriptionsClone: any = [...titleMetaDescriptions];
    titleMetaDescriptionsClone[index].metaDescription = e.target.value.substring(0, 160);
    setTitleMetaDescriptions(titleMetaDescriptionsClone);
    if (e.target.value.length > 160) {
      toast({
        title: "Meta Description is too long",
        description: "Meta Description should be less than 160 characters",
        status: "error",
      });
    }
  }

  function onUrlChange(index: number, e: React.ChangeEvent<HTMLInputElement>) {
    const titleMetaDescriptionsClone: any = [...titleMetaDescriptions];
    titleMetaDescriptionsClone[index].url = e.target.value;
    setTitleMetaDescriptions(titleMetaDescriptionsClone);
  }

  function onAddItem() {
    const titleMetaDescriptionsClone: any = [...titleMetaDescriptions];
    titleMetaDescriptionsClone.push({
      url: "",
      titleTag: "",
      metaDescription: "",
    });
    setTitleMetaDescriptions(titleMetaDescriptionsClone);
  }

  function onFileSelected(file: any) {
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: (results: any) => {
        const newData = results.data.map((result: any) => {
          return {
            url: result["URL"],
            titleTag: result["Title Tag"],
            metaDescription: result["Meta Description"],
          };
        });
        if (
          titleMetaDescriptions.length === 1 &&
          (!titleMetaDescriptions[0].titleTag ||
            !titleMetaDescriptions[0].url ||
            !titleMetaDescriptions[0].metaDescription)
        ) {
          setTitleMetaDescriptions([...newData]);
        } else {
          setTitleMetaDescriptions([...titleMetaDescriptions, ...newData]);
        }
      },
    });
  }

  return {
    taskId: task.id,
    loading: taskWorkspaceTitleMetaDescriptionQueryResult.loading,
    submitLoading,
    task: taskQueryResult,
    titleMetaDescriptions,
    onSubmit,
    onTitleTagChange,
    onMetaDescriptionChange,
    onUrlChange,
    onAddItem,
    onFileSelected,
  };
}
