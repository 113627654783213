import { useContext } from "react";
import { Navigate } from "react-router-dom";
import { Global } from "@emotion/react";
import { Box, Text, Checkbox, Image, Flex, useToast } from "@chakra-ui/react";

import { AppColors } from "../../core/custom-theme";
import Header from "../../components/header";
import { AuthContext } from "../../core/auth-manager";
import { ONBOARDING_STATUS } from "../../constants";
import { ContentContainer } from "../../components/content-container";
import WeAreSorry from "../../assets/no-data.svg";
import {
  useNonWriter_UpdateCreativesScaleUserMutation,
  useNonWriterQuery,
  UpdateCreativeScaleUserInput,
} from "../../generated/graphql";
import { handleGraphQLResponseError } from "../../util/error-helper";
import { PrimaryButtonLink } from "../../components/buttons/primary-button-link";

export function OnboardingNonWriterRoute() {
  const authContext = useContext(AuthContext);
  const [updateCSUser] = useNonWriter_UpdateCreativesScaleUserMutation();
  const toast = useToast();
  const queryResult = useNonWriterQuery({
    fetchPolicy: "network-only",
  });

  const optInDate = queryResult.data?.viewer.creativesScaleUser?.optedInForUpdatesAt ?? null;

  if (
    !authContext.isLoading &&
    authContext.viewer?.creativesScaleUser?.onboardingStatusId !== ONBOARDING_STATUS.INTERESTS_NOT_YET_SUPPORTED.id
  ) {
    return <Navigate to="/" />;
  }

  async function updateCreativeUser(input: UpdateCreativeScaleUserInput) {
    const response = await updateCSUser({
      variables: {
        input,
      },
    });

    queryResult.refetch();

    if (!response.data?.updateCreativesScaleUser?.ok && response.data?.updateCreativesScaleUser.error) {
      handleGraphQLResponseError(
        response.data?.updateCreativesScaleUser?.error ?? {
          message: "Something went wrong. Please try again.",
          inputFieldErrors: [],
        },
        toast
      );
    }
  }

  async function onOptsInUpdateChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (!authContext.viewer?.creativesScaleUser) {
      return;
    }
    const isOptedIn = event.target.checked;
    const input = {
      id: authContext.viewer?.creativesScaleUser?.id,
      isOptingInForUpdates: isOptedIn,
    };
    await updateCreativeUser(input);
    toast({
      title: "Preferences Saved",
      description: isOptedIn
        ? "We'll make sure to keep you updated with product and service updates."
        : "We won't send you product and service updates.",
      status: "success",
    });
  }

  return (
    <>
      <Global styles={{ body: { backgroundColor: AppColors.body } }} />
      <Header />
      <ContentContainer centerHorizontally>
        <Box p={8}>
          <Flex justifyContent="center">
            <Image alignItems="center" src={WeAreSorry} alt="Creatives Scale" />
          </Flex>
          <Box textAlign="center">
            <Text as="h1" fontSize="4xl" fontWeight="bold" marginBottom={2}>
              We’re Sorry
            </Text>
          </Box>
          <Flex justifyContent="center">
            <Text maxWidth={675} textAlign="center">
              We’re not accepting applicants interested in non writing fields, however in time we will broaden our reach
              to other creative fields. We’ll email you when those changes are happening. Thanks again for your
              interest!
            </Text>
          </Flex>
          <Box textAlign="center" my={12}>
            <Checkbox id="isOptingInForUpdates" onChange={onOptsInUpdateChange} isChecked={!!optInDate}>
              <Text fontSize="sm" color={AppColors.textNormal}>
                I would like to receive product and service updates from Creatives Scale.
              </Text>
            </Checkbox>
          </Box>
          <Box textAlign="center" my={8}>
            <PrimaryButtonLink href="https://www.creativesscale.com">Back to Homepage</PrimaryButtonLink>
          </Box>
        </Box>
      </ContentContainer>
    </>
  );
}
