import * as yup from "yup";
import { Global } from "@emotion/react";
import { Box, Image, FormControl, FormLabel, Input, Stack, FormErrorMessage, useToast, Text } from "@chakra-ui/react";
import CSLogo from "../../assets/creatives-scale-logo-color.png";
import { useFormik, FormikHelpers } from "formik";
import { AppColors } from "../../core/custom-theme";
import { PrimaryButton } from "../../components/buttons";
import { useRequestPasswordResetMutation, RequestPasswordResetMutationResult } from "../../generated/graphql";
import { useNavigate } from "react-router-dom";
import AppIcon from "../../components/app-icon";
import AppLink from "../../components/app-link";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { PanelBox } from "../../components/panels";

interface FormValues {
  email: string;
}

const loginSchema = yup.object().shape({
  email: yup.string().label("Email").required().email().max(255),
});

export function ForgotPassword() {
  const toast = useToast();
  const navigate = useNavigate();
  const [requestPasswordResetMutation] = useRequestPasswordResetMutation();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async function handleFormSubmit(values: FormValues, _formikHelpers: FormikHelpers<FormValues>) {
    const response = (await requestPasswordResetMutation({
      variables: { input: { email: values.email, origin: "cs" } },
    })) as RequestPasswordResetMutationResult;
    if (response.data?.requestPasswordReset.wasRequested === true) {
      toast({
        title: "Password Reset Requested",
        description: "Instructions for resetting your password have been sent to your email.",
        status: "info",
      });
      navigate("/login");
    } else {
      toast({
        title: "Error",
        description: "There was a problem requesting a password reset. Please try again.",
        status: "error",
      });
    }
  }

  const formik = useFormik<FormValues>({
    initialValues: {
      email: "",
    },
    validateOnBlur: true,
    validationSchema: loginSchema,
    onSubmit: handleFormSubmit,
  });

  return (
    <>
      <Global styles={{ body: { backgroundColor: AppColors.body } }} />
      <Box
        width="100%"
        display="flex"
        justifyContent="center"
        paddingBottom={10}
        alignItems={{ base: "flex-start", lg: "center" }}
        height="100vh"
      >
        <Box width="400px">
          <Box justifyContent="center" display="flex">
            <Image src={CSLogo} alt="Creatives Scale" marginY={8} width="250px" height="100px" objectFit="contain" />
          </Box>
          <PanelBox>
            <Stack spacing={6}>
              <AppLink to="/login" fontSize="sm">
                <AppIcon icon={faChevronLeft} standardRightMargin />
                Back to Login
              </AppLink>
              <Box>
                <Text fontSize="xl" fontWeight="bold" marginBottom={2}>
                  Password Recovery
                </Text>
                <Text fontSize="sm">Don&apos;t worry, happens to the best of us.</Text>
              </Box>
              <FormControl isRequired isInvalid={!!formik.errors.email && formik.touched.email}>
                <FormLabel>Email associated with your account</FormLabel>
                <Input type="email" id="email" value={formik.values.email} onChange={formik.handleChange} autoFocus />
                {formik.touched.email && formik.errors.email && (
                  <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
                )}
              </FormControl>
              <Box>
                <PrimaryButton
                  width="100%"
                  onClick={() => {
                    formik.submitForm();
                  }}
                  isDisabled={formik.isSubmitting}
                  isLoading={formik.isSubmitting}
                  loadingText="Requesting..."
                >
                  Email Recovery Link
                </PrimaryButton>
              </Box>
            </Stack>
          </PanelBox>
        </Box>
      </Box>
    </>
  );
}
