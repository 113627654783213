import { useContext } from "react";
import { Navigate } from "react-router-dom";
import { Global } from "@emotion/react";
import { Box, HStack, FormControl, FormLabel } from "@chakra-ui/react";
import { PAGE_LIMIT, CS_VENDOR_ID, TASK_STATUS } from "../../constants";
import { TasksSort, useMyTasksQuery } from "../../generated/graphql";
import { AppColors } from "../../core/custom-theme";
import { ContentContainer } from "../../components/content-container";
import SearchBox from "../../components/search-box";
import MyTaskTable from "./my-task-table";
import { AuthContext } from "../../core/auth-manager";
import { PanelGroup, PanelTop, PanelBottom } from "../../components/panels";
import { PageHeading } from "../../components/page-heading";
import { MainNav } from "../../components/main-nav";
import { StripeActionBar } from "../../common/stripe-action-bar";
import { TaskStatusSelector } from "../../common/task-status-selector";
import { CustomSingleSelect } from "../../components/custom-selector";
import { faSort } from "@fortawesome/free-solid-svg-icons";
import { ArrayParam, NumberParam, StringParam, useQueryParam, withDefault } from "use-query-params";

export function MyTasksRoute() {
  const authContext = useContext(AuthContext);

  const [search, setSearch] = useQueryParam("search", withDefault(StringParam, ""));
  const [offset, setOffset] = useQueryParam("offset", withDefault(NumberParam, 0));
  const [statusIds, setStatusIds] = useQueryParam("statusIds", withDefault(ArrayParam, [TASK_STATUS.ASSIGNED]));
  const [sort, setSortQueryParam] = useQueryParam("sort", withDefault(StringParam, TasksSort.CompletedAtDesc));

  const queryResult = useMyTasksQuery({
    variables: {
      offset,
      search,
      sort: sort ? (sort as TasksSort) : undefined,
      statusIds: statusIds as string[],
      limit: PAGE_LIMIT,
      assignedUserIds: [authContext.viewer?.user?.id ?? 0],
      vendorIds: [CS_VENDOR_ID],
      ignoreUserTaskPermission: true,
    },
    fetchPolicy: "network-only",
    skip: !authContext.viewer?.user?.id,
  });

  if (!authContext.isLoading && !authContext.viewer?.user?.id) {
    return <Navigate to="/login" />;
  }

  function onSearch(value: string) {
    setSearch(value);
  }

  function onNextPage() {
    setOffset(offset + PAGE_LIMIT);
  }

  function onPreviousPage() {
    setOffset(offset - PAGE_LIMIT);
  }

  function handleStatusIdsChange(changedStatusIds: string[]) {
    setStatusIds(changedStatusIds);
  }

  function onSortChange(value: string) {
    setSortQueryParam(value);
  }

  return (
    <>
      <Global styles={{ body: { backgroundColor: AppColors.body } }} />
      <MainNav />
      <ContentContainer centerHorizontally>
        <StripeActionBar />
        <PageHeading>My Tasks</PageHeading>
        <PanelGroup>
          <PanelTop showBottomBorder>
            <Box display="flex" justifyContent="space-between">
              <HStack spacing="8px" justify="left" justifyContent="flex-start" alignItems="flex-end" shouldWrapChildren>
                <FormControl>
                  <FormLabel fontSize="sm">Search (Task ID)</FormLabel>
                  <SearchBox onChange={onSearch} placeholder="Search" defaultValue={search} />
                </FormControl>
                <FormControl width="inherit">
                  <FormLabel fontSize="sm">Sort</FormLabel>
                  <CustomSingleSelect
                    onChange={(value) => {
                      if (!value) {
                        return null;
                      }
                      onSortChange(value);
                    }}
                    allowEmpty={false}
                    value={sort}
                    items={[
                      {
                        displayText: "CompletedAt Oldest First",
                        value: TasksSort.CompletedAtAsc,
                      },
                      {
                        displayText: "CompletedAt Newest First",
                        value: TasksSort.CompletedAtDesc,
                      },
                    ]}
                    selectorIcon={faSort}
                  />
                </FormControl>
                <TaskStatusSelector handleStatusIdsChange={handleStatusIdsChange} statusIds={statusIds as string[]} />
              </HStack>
            </Box>
          </PanelTop>
          <PanelBottom containsTable>
            <MyTaskTable queryResult={queryResult} onPreviousPage={onPreviousPage} onNextPage={onNextPage} />
          </PanelBottom>
        </PanelGroup>
      </ContentContainer>
    </>
  );
}
