import React from "react";
import { Box, BoxProps, useBreakpointValue } from "@chakra-ui/react";

interface Props extends BoxProps {
  children: React.ReactNode;
  centerHorizontally?: boolean;
}

/*
  BREAKPOINTS
  xs: 0-599px
  sm: 600-959px
  md: 960px-1279px
  lg: 1280px-1919px
  xl: 1920px+
*/

export function Container(props: Props) {
  const { children, centerHorizontally, ...rest } = props;
  const width = useBreakpointValue({ base: "95%", lg: "1120px" });
  if (centerHorizontally) {
    return (
      <Box display="flex" justifyContent="center">
        <Box {...rest} width={width}>
          {children}
        </Box>
      </Box>
    );
  }
  return (
    <Box {...rest} width={width}>
      {children}
    </Box>
  );
}
