import { useState } from "react";
import { Box, Flex, Text, Input, Alert, useToast, Stack, FormControl, FormLabel } from "@chakra-ui/react";
import { useParams, useNavigate } from "react-router-dom";
import { faExclamationTriangle, faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { DateTime } from "luxon";
import AppIcon from "../../components/app-icon";
import { useApproveUserWritingSampleMutation, useUserWritingSampleQuery } from "../../generated/graphql";
import { ContentEditor } from "../../components/content-editor";
import { PrimaryButton, SecondaryButton } from "../../components/buttons";
import { AppColors } from "../../core/custom-theme";
import { Global } from "@emotion/react";
import { ContentContainer } from "../../components/content-container";
import AppLink from "../../components/app-link";
import { Loader } from "../../components/loader";
import WritingSampleRevise from "./writing-sample-revise";
import { PanelBox, PanelGroup, PanelTop, PanelBottom, PanelMiddle } from "../../components/panels";
import { PageHeading } from "../../components/page-heading";
import { MainNav } from "../../components/main-nav";
import { DATE_FORMAT, LOADER_TYPE } from "../../constants";
import { ValidationChecklistPanel } from "../../components/validation-checklist-panel";

export function AdminWritingSampleReview() {
  const { id } = useParams<"id">();
  const userWritingSampleId = parseInt(id ?? "", 10);
  const navigate = useNavigate();
  const [showRevise, setShowRevise] = useState(false);
  const { loading, data, error } = useUserWritingSampleQuery({
    variables: {
      userWritingSampleId: userWritingSampleId ?? "",
    },
    fetchPolicy: "network-only",
  });
  const [approveUserWritingSample, { loading: approveUserWritingRequestInProgress }] =
    useApproveUserWritingSampleMutation();
  const toast = useToast();
  const userWritingSample = data?.userWritingSample;
  const resumeFile = data?.userWritingSample?.user?.userProfessional?.resumeFile;

  if (loading) {
    return <Loader type={LOADER_TYPE.fullView} />;
  }

  if (error) {
    return (
      <Alert status="error">
        <AppIcon icon={faExclamationTriangle} wideRightMargin />
        An error occurred while retrieving the writing samples.
      </Alert>
    );
  }

  function navigateToWritingSamples() {
    navigate("/admin/writing-samples");
  }

  function onRevise() {
    setShowRevise(true);
  }

  function onClose(success?: boolean) {
    setShowRevise(false);
    if (success) {
      navigateToWritingSamples();
    }
  }

  async function onApprove() {
    const response = await approveUserWritingSample({
      variables: {
        input: {
          userWritingSampleId,
        },
      },
    });
    if (response?.data?.approveUserWritingSample?.ok) {
      toast({
        title: "Writing sample approved!",
        status: "success",
      });
      navigateToWritingSamples();
    } else {
      toast({
        title: "Error occurred while saving writing sample",
        status: "error",
      });
    }
  }

  return (
    <>
      <Global styles={{ body: { backgroundColor: AppColors.body } }} />
      <MainNav />
      <ContentContainer centerHorizontally marginBottom={8}>
        <AppLink to="/admin/writing-samples">
          <Text textAlign="left" my={4}>
            <AppIcon icon={faChevronLeft} standardRightMargin />
            Back to Writing Samples
          </Text>
        </AppLink>
        <PageHeading>Writing Sample Review</PageHeading>
        <Flex>
          <Stack spacing={4} width="30%" display={{ base: "none", md: "block" }} marginRight={4}>
            <PanelBox>
              <Stack spacing={4}>
                <FormControl>
                  <FormLabel>Submitted</FormLabel>
                  <Input
                    variant="flushed"
                    value={DateTime.fromJSDate(userWritingSample?.endsAt ?? new Date()).toFormat(DATE_FORMAT)}
                    isDisabled
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Team Member</FormLabel>
                  <Input variant="flushed" value={userWritingSample?.user?.fullName ?? "-"} isDisabled />
                </FormControl>
                <FormControl isReadOnly>
                  <FormLabel>LinkedIn Profile URL</FormLabel>
                  <AppLink
                    isExternal
                    to={userWritingSample?.user?.userProfessional?.linkedInProfileUrl ?? ""}
                    target="_blank"
                  >
                    {userWritingSample?.user?.userProfessional?.linkedInProfileUrl ?? ""}
                  </AppLink>
                </FormControl>
                {resumeFile && (
                  <FormControl>
                    <FormLabel>
                      <Box>Resume</Box>
                      <AppLink isExternal to={resumeFile?.url} target="_blank">
                        {resumeFile?.name ?? "-"}
                      </AppLink>
                    </FormLabel>
                  </FormControl>
                )}
              </Stack>
            </PanelBox>
          </Stack>
          <Box width={{ base: "100%", md: "70%" }}>
            <PanelGroup>
              <PanelTop>
                <Input
                  value={userWritingSample?.contentItem?.title}
                  placeholder="Title"
                  fontSize="xl"
                  fontWeight="bold"
                  isReadOnly
                />
              </PanelTop>
              <PanelMiddle>
                <ContentEditor initialValue={userWritingSample?.contentItem?.content ?? ""} height="50vh" readOnly />
              </PanelMiddle>
              <PanelBottom display="flex" justifyContent="flex-end" alignItems="center" showTopBorder>
                <Stack spacing={4} isInline>
                  <SecondaryButton isLoading={loading} onClick={onRevise}>
                    Reject
                  </SecondaryButton>
                  <PrimaryButton isLoading={approveUserWritingRequestInProgress} onClick={onApprove}>
                    Approve
                  </PrimaryButton>
                </Stack>
              </PanelBottom>
            </PanelGroup>
            {userWritingSample?.validationCheckSummary && (
              <ValidationChecklistPanel {...userWritingSample.validationCheckSummary} />
            )}
          </Box>
        </Flex>
      </ContentContainer>
      <WritingSampleRevise isOpen={showRevise} onClose={onClose} userWritingSampleId={userWritingSampleId} />
    </>
  );
}
