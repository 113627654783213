import { useState, ChangeEvent } from "react";
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useToast,
  Textarea,
  FormControl,
  FormLabel,
  FormHelperText,
} from "@chakra-ui/react";
import { useRejectUserWritingSampleMutation } from "../../generated/graphql";
import { PrimaryButton } from "../../components/buttons";

interface Props {
  isOpen: boolean;
  onClose: (success?: boolean) => void;
  userWritingSampleId: number;
}

export default function WritingSampleRevise(props: Props) {
  const { isOpen, onClose, userWritingSampleId } = props;
  const toast = useToast();
  const [rejectUserWritingSample, { loading }] = useRejectUserWritingSampleMutation();
  const [comments, setComments] = useState("");

  const handleInputChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const inputValue = event.target.value;
    setComments(inputValue);
  };

  async function onRevise() {
    const response = await rejectUserWritingSample({
      variables: {
        input: {
          userWritingSampleId,
          comments,
        },
      },
    });

    if (response.data?.rejectUserWritingSample.ok) {
      toast({
        title: "User writing sample sent for revise!",
        status: "success",
      });
      onClose(true);
    } else {
      toast({
        title: "Error occurred while saving user writing sample",
        status: "error",
      });
      onClose(false);
    }
  }

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose.bind(null, false)} size="lg">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader borderBottomWidth="1px">Reject Writing Sample</DrawerHeader>
        <DrawerBody>
          <FormControl>
            <FormLabel>Comments</FormLabel>
            <Textarea value={comments} onChange={handleInputChange} placeholder="Add comment" size="sm" />
            <FormHelperText>
              <strong>Note</strong>: These comments will be provided to the user.
            </FormHelperText>
          </FormControl>
        </DrawerBody>
        <DrawerFooter justifyContent="flex-start" borderTopWidth="1px">
          <PrimaryButton onClick={onRevise} isDisabled={!comments.trim()} isLoading={loading} marginRight={4}>
            Reject
          </PrimaryButton>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}
