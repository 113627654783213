import { Alert, Popover, PopoverTrigger, PopoverContent, PopoverArrow, Box, Text } from "@chakra-ui/react";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import StarRatings from "react-star-ratings";
import parse from "html-react-parser";
import { DateTime } from "luxon";
import { TaskHistoryFragment } from "../../generated/graphql";
import AppIcon from "../../components/app-icon";
import {
  Table,
  TableHead,
  TableRow,
  TableHeaderCell,
  TableBody,
  TableCell,
  TablePagination,
  TableFoot,
} from "../../components/table";
import { TaskStatusIndicator } from "../../common/task-status-indicator";
import { DATE_FORMAT, PAGE_LIMIT, TASK_TYPES } from "../../constants";
import { AppColors } from "../../core/custom-theme";
import { TaskPaymentStatusIndicator } from "../../common/task-payment-status-indicator";
import { currencyFormatter } from "../../util/formatter";
import AppLink from "../../components/app-link";

interface Props {
  tasks: TaskHistoryFragment[];
  loading: boolean;
  error: any;
  totalCount: number;
  limit: number;
  offset: number;
  onNextPage: () => void;
  onPreviousPage: () => void;
}

export default function MyTaskTable(props: Props) {
  const { tasks, loading, error, totalCount, limit, offset, onNextPage, onPreviousPage } = props;

  if (error) {
    return (
      <Alert status="error">
        <AppIcon icon={faExclamationTriangle} wideRightMargin />
        An error occurred while retrieving the tasks.
      </Alert>
    );
  }

  return (
    <Table isLoading={loading} isEmpty={tasks.length === 0}>
      <TableHead>
        <TableRow key="head">
          <TableHeaderCell>Task</TableHeaderCell>
          <TableHeaderCell>Topic</TableHeaderCell>
          <TableHeaderCell>Category</TableHeaderCell>
          <TableHeaderCell>Status</TableHeaderCell>
          <TableHeaderCell>Rating</TableHeaderCell>
          <TableHeaderCell>AI Probability</TableHeaderCell>
          <TableHeaderCell>Order ID</TableHeaderCell>
          <TableHeaderCell>Completed At</TableHeaderCell>
          <TableHeaderCell>Payment</TableHeaderCell>
          <TableHeaderCell># Rejected</TableHeaderCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {tasks.map((task) => (
          <TableRow key={task.id}>
            <TableCell>
              {task.taskType.id === TASK_TYPES.CONTENT ? (
                <AppLink to={`/tasks/${task.id}/view?showRevision=1`}>{task.taskType.name}</AppLink>
              ) : (
                task.taskType.name
              )}
            </TableCell>
            <TableCell>{task.topic}</TableCell>
            <TableCell>{task.category?.name ?? "-"}</TableCell>
            <TableCell>
              <TaskStatusIndicator csTaskStatus={task.creativesScaleTaskStatus} />
            </TableCell>
            <TableCell>
              {task.contentItem?.feedbackContentItems?.[0] ? (
                <Popover trigger="hover">
                  <PopoverTrigger>
                    <span>
                      <StarRatings
                        rating={task.contentItem.feedbackContentItems[0].rating}
                        starRatedColor={AppColors.blue}
                        starDimension="20px"
                        starSpacing="2px"
                      />
                    </span>
                  </PopoverTrigger>
                  <PopoverContent maxW={200} zIndex={500}>
                    <PopoverArrow bg={AppColors.textNormal} />
                    <Box backgroundColor={AppColors.textNormal} color={AppColors.white} p={2} maxW={200} fontSize="sm">
                      {parse(task.contentItem.feedbackContentItems[0].feedback ?? "-")}
                    </Box>
                  </PopoverContent>
                </Popover>
              ) : (
                "-"
              )}
            </TableCell>
            <TableCell>
              {task.contentItem?.probability !== null && task.contentItem?.probability !== undefined ? (
                <Text>{Math.round((task.contentItem?.probability ?? 0) * 100 * 100) / 100}%</Text>
              ) : (
                "-"
              )}
            </TableCell>
            <TableCell>{task.order.id}</TableCell>
            <TableCell>
              {task.completedAt ? (
                <Text fontSize="sm">{DateTime.fromJSDate(task.completedAt).toFormat(DATE_FORMAT)}</Text>
              ) : (
                "-"
              )}
            </TableCell>
            <TableCell>
              {currencyFormatter.format(task.paymentAmount ?? 0)}
              {task.paymentStatus && <TaskPaymentStatusIndicator status={task.paymentStatus} />}
            </TableCell>
            <TableCell>
              <Text>QA : {task.reviewTasksQa.totalCount}</Text>
              <Text>Client : {task.reviewTasksCustomer.totalCount}</Text>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
      <TableFoot>
        <TablePagination
          totalCount={totalCount}
          limit={limit ?? PAGE_LIMIT}
          offset={offset ?? 0}
          onNextPage={onNextPage}
          onPreviousPage={onPreviousPage}
        />
      </TableFoot>
    </Table>
  );
}
