import { useState } from "react";
import { useAdminGrammarTestQuestionsQuery, useSaveGrammarTestQuestionMutation } from "../../generated/graphql";
import { getQueryParams, setParams } from "../../util/query-param-helper";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "qs";
import { useToast } from "@chakra-ui/react";
import { getDisplayMessageForError } from "../../util/error-helper";
import { PAGE_LIMIT } from "../../constants";

export function useAdminGrammarTestQuestions() {
  const [selectedGrammarTestQuestionId, setSelectedGrammarTestQuestionId] = useState<number | null>(null);
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = getQueryParams(location.search, queryString) || {};
  const pageOffset = queryParams?.offset ? parseInt(queryParams.offset, 10) : 0;
  const queryResult = useAdminGrammarTestQuestionsQuery({
    variables: {
      limit: PAGE_LIMIT,
      offset: pageOffset,
    },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });
  const [saveGrammarTestQuestion] = useSaveGrammarTestQuestionMutation();
  const toast = useToast();

  const grammarTestQuestions = queryResult.data?.grammarTestQuestions?.nodes ?? [];

  function onNextPage() {
    const { offset, ...remainingQueryParams } = queryParams;
    const newOffset = parseInt(offset ?? "0", 10) + PAGE_LIMIT;
    const params = setParams(remainingQueryParams, "offset", newOffset.toString());
    navigate({ search: `?${params}` });
  }

  function onPreviousPage() {
    const { offset, ...remainingQueryParams } = queryParams;
    const newOffset = Math.max(parseInt(offset ?? "0", 10) - PAGE_LIMIT, 0);
    const params = setParams(remainingQueryParams, "offset", newOffset.toString());
    navigate({ search: `?${params}` });
  }

  function onEdit(grammarTestQuestionId: number) {
    setSelectedGrammarTestQuestionId(grammarTestQuestionId);
  }

  function onCloseDrawer() {
    setSelectedGrammarTestQuestionId(null);
  }

  function onDrawerSaved() {
    // TODO: refetch
  }

  function onAddQuestion() {
    setSelectedGrammarTestQuestionId(0);
  }

  async function onIsActiveChange(grammarTestQuestionId: number, isActive: boolean) {
    const grammarTestQuestion = grammarTestQuestions.find((x) => x.id === grammarTestQuestionId);
    if (!grammarTestQuestion) {
      return;
    }
    try {
      await saveGrammarTestQuestion({
        variables: {
          input: {
            grammarTestQuestionId,
            isActive,
            prompt: grammarTestQuestion.prompt,
          },
        },
      });
      queryResult.refetch();
    } catch (e: any) {
      toast({
        title: "Unable to Change Grammar Test Question",
        description: getDisplayMessageForError(e),
        status: "error",
      });
    }
  }

  return {
    loading: queryResult.loading,
    selectedGrammarTestQuestionId,
    onEdit,
    onCloseDrawer,
    onDrawerSaved,
    onNextPage,
    onPreviousPage,
    grammarTestQuestions,
    onAddQuestion,
    onIsActiveChange,
    pagination: {
      totalCount: queryResult.data?.grammarTestQuestions?.totalCount ?? 0,
      limit: PAGE_LIMIT,
      offset: pageOffset,
    },
  };
}
