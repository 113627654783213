import { Stack, Input, useToast } from "@chakra-ui/react";
import { Navigate } from "react-router-dom";

import { useContentView } from "./use-content-view";
import { Loader } from "../../components/loader";
import { PageHeading } from "../../components/page-heading";
import { PanelGroup, PanelTop, PanelMiddle } from "../../components/panels";
import { ContentEditor } from "../../components/content-editor";
import { LOADER_TYPE } from "../../constants";
import { LayoutBase } from "../../components/layout/layout-base";
import { RevisionPanel } from "../../common/revisions-panel";

export function TaskContentView() {
  const { loading, task, showRevision } = useContentView();
  const toast = useToast();

  if (loading) {
    return (
      <LayoutBase pageTitle="Task Content View">
        <Loader type={LOADER_TYPE.fullView} />
      </LayoutBase>
    );
  }

  if (!task) {
    toast({ title: "Invalid Task", description: "The specified task was invalid. Please try again." });
    return <Navigate to="/" />;
  }

  return (
    <LayoutBase pageTitle="Task Content View">
      <PageHeading>Content View</PageHeading>
      {showRevision && (
        <Stack paddingBottom={4}>
          <RevisionPanel reviewTasks={task.reviewTasks.nodes ?? []} />
        </Stack>
      )}
      <Stack spacing={4}>
        <PanelGroup>
          <PanelTop>
            <Input size="lg" id="title" value={task.contentItem?.title ?? ""} isReadOnly />
          </PanelTop>
          <PanelMiddle>
            <ContentEditor height={500} min_height={500} initialValue={task.contentItem?.content ?? ""} readOnly />
          </PanelMiddle>
        </PanelGroup>
      </Stack>
    </LayoutBase>
  );
}
