import { Navigate } from "react-router-dom";
import { Global } from "@emotion/react";
import { Box, Text, Divider, Stack } from "@chakra-ui/react";
import { AppColors } from "../../core/custom-theme";
import Header from "../../components/header";
import { ContentContainer } from "../../components/content-container";
import { LOADER_TYPE, ONBOARDING_STATUS } from "../../constants";
import { Loader } from "../../components/loader";
import { useJourneyQuery } from "../../generated/graphql";
import { Subtitle } from "../../components/subtitle";
import { JourneyStepGrammarAssessment } from "../../common/journey-step-grammar-test";
import { JourneyStepWritingSample } from "../../common/journey-step-writing-sample";
import { JourneyStepGrabATask } from "../../common/journey-step-grab-a-task";

export function Journey() {
  const queryResult = useJourneyQuery({ fetchPolicy: "network-only" });

  if (queryResult.loading) {
    return <Loader type={LOADER_TYPE.fullView} />;
  }

  if (!queryResult?.data?.viewer) {
    return <Navigate to="/login" />;
  }

  if (!queryResult?.data?.viewer?.creativesScaleUser) {
    return <Navigate to="/signup" />;
  }

  const onboardingStatusId = queryResult?.data?.viewer?.creativesScaleUser?.onboardingStatusId;

  switch (onboardingStatusId) {
    case ONBOARDING_STATUS.WAITING_FOR_EMAIL_CONFIRM.id:
      return <Navigate to="/email-confirmation-needed" />;
    case ONBOARDING_STATUS.WAITING_FOR_INTEREST_SELECTION.id:
      return <Navigate to="/welcome" />;
    case ONBOARDING_STATUS.COUNTRY_NOT_YET_SUPPORTED.id:
      return <Navigate to="/unsupported-country" />;
    case ONBOARDING_STATUS.INTERESTS_NOT_YET_SUPPORTED.id:
      return <Navigate to="/not-ready-yet" />;
  }

  return (
    <>
      <Global styles={{ body: { backgroundColor: AppColors.body } }} />
      <Header />
      <ContentContainer centerHorizontally maxW="800px">
        <Box p={4}>
          <Box textAlign="center">
            <Text as="h1" fontSize={{ base: "xl", md: "4xl" }} fontWeight="bold" marginTop={4} marginBottom={8}>
              Your Creative Journey
            </Text>
          </Box>
          <Box textAlign="center">
            <Subtitle>{`Let's get started! To sign up for Creatives Scale please complete each step below.`}</Subtitle>
          </Box>
          <Divider marginY={12} />
          <Stack spacing={4} shouldWrapChildren>
            <JourneyStepGrammarAssessment onboardingStatusId={onboardingStatusId} />
            <JourneyStepWritingSample onboardingStatusId={onboardingStatusId} />
            <JourneyStepGrabATask onboardingStatusId={onboardingStatusId} />
          </Stack>
        </Box>
      </ContentContainer>
    </>
  );
}
