import { Box, Text, Switch } from "@chakra-ui/react";
import { ContentContainer } from "../../components/content-container";
import { SecondaryButton } from "../../components/buttons";
import { PanelGroup, PanelTop, PanelBottom } from "../../components/panels";
import { PageHeading } from "../../components/page-heading";
import { useAdminGrammarTestQuestions } from "./use-admin-grammar-test-questions";
import { LayoutBase } from "../../components/layout/layout-base";
import {
  Table,
  TableHead,
  TableRow,
  TableHeaderCell,
  TableBody,
  TableCell,
  TableFoot,
  TablePagination,
} from "../../components/table";
import { PrimaryIconButton } from "../../components/buttons/primary-icon-button";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { QuestionDrawer } from "./question-drawer";
import { HelpText } from "../../components/help-text";

export function AdminGrammarTestQuestionsRoute() {
  const {
    loading,
    onCloseDrawer,
    onDrawerSaved,
    onEdit,
    onNextPage,
    onPreviousPage,
    selectedGrammarTestQuestionId,
    grammarTestQuestions,
    onAddQuestion,
    onIsActiveChange,
    pagination,
  } = useAdminGrammarTestQuestions();

  return (
    <LayoutBase pageTitle="Grammar Test Questions">
      <QuestionDrawer
        isOpen={selectedGrammarTestQuestionId !== null}
        onClose={onCloseDrawer}
        onSaved={onDrawerSaved}
        grammarTestQuestionId={selectedGrammarTestQuestionId}
      />
      <ContentContainer centerHorizontally>
        <PageHeading>Grammar Test Questions</PageHeading>
        <PanelGroup>
          <PanelTop showBottomBorder>
            <Box display="flex" justifyContent="space-between" p={4}>
              <Box>
                <Text fontSize="xl" fontWeight="bold">
                  Grammar Assessment
                </Text>
                <Text>Multiple choice questions</Text>
              </Box>
              <SecondaryButton onClick={onAddQuestion}>Add Question</SecondaryButton>
            </Box>
          </PanelTop>
          <PanelBottom containsTable>
            <Table isLoading={loading} isEmpty={grammarTestQuestions.length === 0}>
              <TableHead>
                <TableRow key="head">
                  <TableHeaderCell>Questions</TableHeaderCell>
                  <TableHeaderCell>Answer</TableHeaderCell>
                  <TableHeaderCell>Active</TableHeaderCell>
                  <TableHeaderCell>
                    Usage <HelpText standardLeftMargin>Displayed as &quot;Correct / Total&quot; attempts</HelpText>
                  </TableHeaderCell>
                  <TableHeaderCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {grammarTestQuestions.map((grammarTestQuestion) => (
                  <TableRow key={grammarTestQuestion.id}>
                    <TableCell>{grammarTestQuestion.prompt}</TableCell>
                    <TableCell>
                      {grammarTestQuestion.answers.find((answer) => answer.isCorrect)?.content ?? "-"}
                    </TableCell>
                    <TableCell>
                      <Switch
                        isChecked={grammarTestQuestion.isActive}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          onIsActiveChange(grammarTestQuestion.id, e.target.checked);
                        }}
                      />
                    </TableCell>
                    <TableCell>{`${grammarTestQuestion.totalCorrectAttempts} / ${grammarTestQuestion.totalAttempts}`}</TableCell>
                    <TableCell>
                      <Box textAlign="right">
                        <PrimaryIconButton
                          onClick={() => {
                            onEdit(grammarTestQuestion.id);
                          }}
                          icon={faPencilAlt}
                          actionName="Edit"
                        />
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableFoot>
                <TablePagination
                  totalCount={pagination.totalCount}
                  limit={pagination.limit}
                  offset={pagination.offset}
                  onNextPage={onNextPage}
                  onPreviousPage={onPreviousPage}
                />
              </TableFoot>
            </Table>
          </PanelBottom>
        </PanelGroup>
      </ContentContainer>
    </LayoutBase>
  );
}
