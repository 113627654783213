import { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Global } from "@emotion/react";
import { Box, Text, Grid } from "@chakra-ui/react";
import { PrimaryButton } from "../../components/buttons";
import { AppColors } from "../../core/custom-theme";
import Header from "../../components/header";
import InterestItem from "./interest-item";
import { useSaveUserWorkInterestsMutation, useWelcomeQuery } from "../../generated/graphql";
import { AuthContext } from "../../core/auth-manager";
import { ContentContainer } from "../../components/content-container";

export function OnboardingWelcomeRoute() {
  const authContext = useContext(AuthContext);
  const [interests, setInterests] = useState<string[]>([]);
  const [saveUserWorkInterests, { loading }] = useSaveUserWorkInterestsMutation();
  const interestsQueryResult = useWelcomeQuery();
  const navigate = useNavigate();

  async function onSaveInterests() {
    const response = await saveUserWorkInterests({
      variables: {
        input: {
          interests,
        },
      },
    });

    if (response.data?.saveUserWorkInterests.interests) {
      await authContext.refresh();
      navigate("/");
    }
  }

  function onToggle(interest: string) {
    const index = interests.indexOf(interest);
    if (index !== -1) {
      setInterests((prevInterests) => {
        const arr = [...prevInterests];
        arr.splice(index, 1);
        return arr;
      });
    } else {
      setInterests((prevInterests) => {
        const arr = [...prevInterests];
        arr.push(interest);
        return arr;
      });
    }
  }

  const workInterests = interestsQueryResult.data?.creativesScaleConfiguration.workInterests ?? [];
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const selectedWorkInterests = authContext.viewer?.creativesScaleUser?.workInterests ?? [];
  const selectedWorkInterestIds = selectedWorkInterests.map((interest) => interest.id);

  useEffect(() => {
    setInterests(selectedWorkInterests.map((interest) => interest.id));
  }, [selectedWorkInterests]);

  return (
    <>
      <Global styles={{ body: { backgroundColor: AppColors.body } }} />
      <Header />
      <ContentContainer centerHorizontally>
        <Box p={4}>
          <Box textAlign="center">
            <Text as="h1" fontSize="4xl" fontWeight="bold" marginBottom={2}>
              Welcome to Creatives Scale
            </Text>
          </Box>
          <Box textAlign="center">
            <Text>
              Before you can starting working on tasks, let’s figure out what creative work you’re looking to do (select
              all that interest you)
            </Text>
          </Box>
          <Grid
            templateColumns="repeat(auto-fit, minmax(220px, 1fr))"
            rowGap={4}
            columnGap={4}
            paddingTop={4}
            justifyContent="center"
            alignItems="center"
            textAlign="center"
          >
            {workInterests.map((interest) => (
              <InterestItem
                key={interest.id}
                name={interest.name}
                isSelected={interests.includes(interest.id)}
                isDisabled={selectedWorkInterestIds.includes(interest.id)}
                onToggle={onToggle.bind(null, interest.id)}
              />
            ))}
          </Grid>
          <Box textAlign="center" my={8}>
            <PrimaryButton
              onClick={onSaveInterests}
              isLoading={loading}
              isDisabled={interests.length === 0}
              paddingX={24}
            >
              Next
            </PrimaryButton>
          </Box>
        </Box>
      </ContentContainer>
    </>
  );
}
