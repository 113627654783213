import { Badge, Tooltip } from "@chakra-ui/react";
import { DateTime } from "luxon";
import { ORDER_STATUS } from "../../constants/order-status";
import { DATE_FORMAT } from "../../constants/date-formats";

interface Props {
  status: {
    id: string;
    name: string;
  };
  startDate: Date;
}

export function OrderStatusIndicator(props: Props) {
  const { status, startDate } = props;

  let color: string | undefined = undefined;
  switch (status.id) {
    case ORDER_STATUS.SCHEDULED.id:
    case ORDER_STATUS.PENDING.id:
      color = "yellow";
      break;
    case ORDER_STATUS.IN_PROGRESS.id:
      color = "blue";
      break;
    case ORDER_STATUS.COMPLETED.id:
      color = "green";
      break;
    case ORDER_STATUS.CANCELLED.id:
      color = "red";
      break;
    case ORDER_STATUS.PAUSED.id:
      color = "orange";
  }
  const indicator = (
    <Badge cursor="help" colorScheme={color}>
      {status.name}
    </Badge>
  );

  const helpLabel = `Scheduled Start Date: ${DateTime.fromJSDate(startDate).toUTC().toFormat(DATE_FORMAT)} (UTC)`;

  return (
    <Tooltip label={helpLabel} aria-label={helpLabel}>
      {indicator}
    </Tooltip>
  );
}
