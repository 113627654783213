import { useQuestionDrawer } from "./use-question-drawer";
import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  Stack,
  FormControl,
  FormLabel,
  Textarea,
  Input,
  Switch,
  Box,
} from "@chakra-ui/react";
import { CancelButton, PrimaryButton, SecondaryButton } from "../../components/buttons";

interface Props {
  grammarTestQuestionId: number | null;
  isOpen: boolean;
  onSaved: () => void;
  onClose: () => void;
}

export function QuestionDrawer(props: Props) {
  const { isOpen } = props;
  const { handleClose, formik, addAdditionalAnswer } = useQuestionDrawer(props);
  return (
    <Drawer isOpen={isOpen} placement="right" onClose={handleClose} size="lg">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader borderBottomWidth="1px">Question Details</DrawerHeader>
        <DrawerBody>
          <Stack spacing={6}>
            <FormControl>
              <FormLabel>Question</FormLabel>
              <Textarea
                id="prompt"
                value={formik.values.prompt}
                onChange={formik.handleChange}
                resize="none"
                placeholder="Question"
              />
            </FormControl>
            <FormControl>
              <FormLabel>Active</FormLabel>
              <Box>
                <Switch id="isActive" isChecked={formik.values.isActive} onChange={formik.handleChange} />
              </Box>
            </FormControl>
            {formik.values.answers.map((answer, index) => (
              <Stack spacing={2} key={index}>
                <FormControl>
                  <FormLabel>Answer {index + 1}</FormLabel>
                  <Input
                    id={`answers[${index}].content`}
                    value={formik.values.answers[index].content ?? ""}
                    onChange={formik.handleChange}
                    resize="none"
                    placeholder="Answer"
                  />
                </FormControl>
                <FormControl>
                  <Switch
                    id={`answers[${index}].isCorrect`}
                    onChange={formik.handleChange}
                    isChecked={formik.values.answers[index].isCorrect ?? false}
                  />
                  <FormLabel marginLeft={2} htmlFor={`answer[${index}].isCorrect`}>
                    Correct Answer
                  </FormLabel>
                </FormControl>
              </Stack>
            ))}
            {formik.values.answers.length < 4 && (
              <SecondaryButton onClick={addAdditionalAnswer}>Add Another Answer</SecondaryButton>
            )}
          </Stack>
        </DrawerBody>
        <DrawerFooter justifyContent="flex-start" borderTopWidth="1px">
          <Stack isInline spacing={2}>
            <PrimaryButton
              onClick={() => {
                formik.handleSubmit();
              }}
              isLoading={formik.isSubmitting}
            >
              Save
            </PrimaryButton>
            <CancelButton isLoading={formik.isSubmitting} onClick={handleClose}>
              Cancel
            </CancelButton>
          </Stack>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}
