import { useParams } from "react-router-dom";
import { useTaskDetailsQuery } from "../../generated/graphql";

export function useTaskDetails() {
  const { id } = useParams<"id">();
  const taskId = parseInt(id ?? "");
  const queryResult = useTaskDetailsQuery({
    fetchPolicy: "network-only",
    variables: {
      taskId,
      ignoreUserTaskPermission: true,
    },
  });

  const task = queryResult.data?.task ?? null;

  return {
    loading: queryResult.loading,
    taskId,
    task,
  };
}
