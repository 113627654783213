import { DateTime } from "luxon";
import { Navigate } from "react-router-dom";
import Color from "color";
import {
  Stack,
  Grid,
  Box,
  useToast,
  Alert,
  AlertIcon,
  Text,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalFooter,
  ModalBody,
  Button,
  useDisclosure,
} from "@chakra-ui/react";

import { PanelGroup, PanelBottom, PanelMiddle } from "../../../../components/panels";
import { useTitleMetaDescription } from "./use-title-meta-description";
import { PrimaryButton, PrimaryLinkButton } from "../../../../components/buttons";
import { PageHeading } from "../../../../components/page-heading";
import { DATE_MINUTE_AT_FORMAT, LOADER_TYPE } from "../../../../constants";
import { AppColors } from "../../../../core/custom-theme";
import { DangerTextButton } from "../../../../components/buttons/danger-text-button";
import { Loader } from "../../../../components/loader";
import { TaskWorkspaceCommonProps } from "../../task-workspace-common-props";
import { TitleMetaDescriptionRow } from "./title-meta-description-row";
import { InformationPanel } from "./information-panel";
import { FileDropUpload } from "../../../../components/file-drop-upload";

export function TitleMetaDescription(props: TaskWorkspaceCommonProps) {
  const { onAbandon } = props;
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const {
    loading,
    titleMetaDescriptions,
    onSubmit,
    task,
    onUrlChange,
    onMetaDescriptionChange,
    onTitleTagChange,
    onAddItem,
    submitLoading,
    onFileSelected,
  } = useTitleMetaDescription(props);

  if (loading) {
    return <Loader type={LOADER_TYPE.fullView} />;
  }

  if (!task) {
    toast({ title: "Invalid Task", description: "The specified task was invalid. Please try again." });
    return <Navigate to="/" />;
  }

  function renderCountDown(expiresAt: Date) {
    return (
      <Alert status="warning" marginY={2} variant="left-accent">
        <AlertIcon />
        <Box display="flex">
          <Text>
            This task will expire on <strong>{DateTime.fromJSDate(expiresAt).toFormat(DATE_MINUTE_AT_FORMAT)}</strong>.
            Please submit for approval before then to avoid a penalty to your account.
          </Text>
        </Box>
      </Alert>
    );
  }

  if (!!task?.expiresAt && task.expiresAt < new Date()) {
    toast({
      title: "Task Expired",
      description: "The specified task is expired. This task will be in available queue.",
    });
    return <Navigate to="/" />;
  }

  return (
    <>
      <PageHeading>Title And Meta Description Task</PageHeading>
      {!!task?.expiresAt && renderCountDown(task.expiresAt)}
      <Grid gridTemplateColumns="1fr 1.5fr" gridGap={4}>
        <Stack spacing={4}>
          <InformationPanel task={task} />
        </Stack>
        <Stack spacing={4}>
          <PanelGroup>
            <PanelMiddle>
              {titleMetaDescriptions.map((titleMetaDescription: any, index: number) => (
                <TitleMetaDescriptionRow
                  key={index}
                  titleTag={titleMetaDescription.titleTag}
                  metaDescription={titleMetaDescription.metaDescription}
                  url={titleMetaDescription.url}
                  onTitleTagChange={onTitleTagChange.bind(null, index)}
                  onMetaDescriptionChange={onMetaDescriptionChange.bind(null, index)}
                  onUrlChange={onUrlChange.bind(null, index)}
                />
              ))}
              <Box paddingY={4}>
                <PrimaryLinkButton onClick={onAddItem} isDisabled={titleMetaDescriptions.length >= 10}>
                  Add Item
                </PrimaryLinkButton>
                <Box>
                  <FileDropUpload
                    acceptedFileExtensions={{
                      "application/csv": [".csv"],
                    }}
                    onFileUpload={onFileSelected}
                  />
                  <Text>Header should be Title Tag, Meta Description, URL</Text>
                </Box>
              </Box>
            </PanelMiddle>
            <PanelBottom showTopBorder>
              <Flex justifyContent="space-between" alignItems="center">
                <Stack isInline spacing={2} justifyContent="flex-end">
                  <DangerTextButton onClick={onOpen} isDisabled={submitLoading}>
                    Unassign Task
                  </DangerTextButton>
                  <PrimaryButton onClick={onSubmit} isDisabled={submitLoading}>
                    Submit
                  </PrimaryButton>
                </Stack>
              </Flex>
            </PanelBottom>
          </PanelGroup>
        </Stack>
      </Grid>
      <Modal onClose={onClose} size="xl" isOpen={isOpen} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Unassign Task?</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Unassigning a task comes with a penalty. Excessive penalties could result in restricted access or banning
            your account.
          </ModalBody>
          <ModalFooter>
            <Button fontSize="14px" paddingX="30px" paddingY="10px" marginRight="20px" onClick={onClose}>
              Cancel
            </Button>
            <Button
              color={"white"}
              backgroundColor={AppColors.error}
              _hover={{
                bg: Color(AppColors.error).darken(0.2).hex(),
              }}
              _active={{
                bg: Color(AppColors.error).darken(0.4).hex(),
              }}
              fontSize="14px"
              paddingX="30px"
              paddingY="10px"
              onClick={onAbandon}
            >
              Unassign
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
