export const GUEST_ARTICLE_CHECKLIST_ITEMS = [
  `Guest Article content does not include the client or business name`,
  `Guest Article content contains one bolded link on the client’s selected keyword that leads to the client’s Home page`,
  `Guest Article does not promote the client’s business; rather,
   all sections of the article solely focus on information regarding the topic and keyword`,
  `Guest Article adheres to all instructions provided by the client`,
  `Guest Article does not contain filler terms such as “it’s crucial”, “it’s vital”, “it’s essential”, “it’s important [to]”, “with that said”, “furthermore”, etc.`,
  `For rejected Guest Articles: ALL revision requests and in-text feedback provided by the editor have been addressed and updated. 
   The editor’s highlighted in-text feedback has been removed before submission.`,
];

export const BLOG_CHECKLIST_ITEMS = [
  `All details and research used to create blog post were gathered via the client’s website`,
  `Blog post is promotional regarding the client’s product or services and is written from the client’s perspective. 
   For example, it refers to the client in first-person “we” or by the client’s name.`,
  `Blog post contains a bolded link on the client’s name that leads to the client’s About or Contact page`,
  `Blog post adheres to all instructions provided by the client`,
  `Blog post does not contain filler terms such as “it’s crucial”, “it’s vital”, “it’s essential”, “it’s important [to]”, 
   “with that said”, “furthermore”, etc.`,
  `For rejected blog posts: ALL revision requests and in-text feedback provided by the editor have been addressed and updated. 
   The editor’s highlighted in-text feedback has been removed before submission.`,
];

export const WEB_COPY_CHECKLIST_ITEMS = [
  `Content is in a professional tone`,
  `Content is written in the third person, speaking about the company?`,
  `Web page topic is clear (Ex. About page is About Company)`,
  `Web content answers all questions about the company as it pertains to that page without bringing up more questions`,
  `Call to action buttons are bolded/noted as a button`,
  `Internal and/or external links are highlighted and included, as needed`,
];
