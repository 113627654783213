import {
  Button,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Stack,
  Tag,
  TagCloseButton,
  TagLabel,
} from "@chakra-ui/react";
import AppIcon from "../../components/app-icon";
import { faCaretDown, faPlus } from "@fortawesome/free-solid-svg-icons";
import { useCustomerKeywordSelectorQuery } from "../../generated/graphql";

enum KeywordType {
  PRIMARY = "primary",
  HALO = "halo",
  ALL = "all",
}

interface Props {
  customerId: number;
  customerKeywordIds: number[];
  keywordType: KeywordType.PRIMARY | KeywordType.HALO | KeywordType.ALL;
  onCustomerKeywordsChanged: (customerKeywordIds: number[]) => void;
}

export function CustomerKeywordMultiSelector(props: Props) {
  const { customerId, keywordType, customerKeywordIds, onCustomerKeywordsChanged } = props;

  let isPrimary: boolean | null = null;
  if (keywordType === KeywordType.PRIMARY) {
    isPrimary = true;
  } else if (keywordType === KeywordType.HALO) {
    isPrimary = false;
  }

  const queryResult = useCustomerKeywordSelectorQuery({
    fetchPolicy: "network-only",
    variables: {
      customerId,
      isPrimary,
      includeInactive: true,
    },
  });

  const keywords = queryResult.data?.customer?.customerKeywords.nodes ?? [];
  const availableKeywords = keywords.filter((x) => !customerKeywordIds.includes(x.id) && x.isActive);

  function onCustomerKeywordSelected(customerKeywordId: number) {
    const isChecked = customerKeywordIds.find((x) => x === customerKeywordId);
    if (isChecked) {
      onCustomerKeywordsChanged(customerKeywordIds.filter((x) => x !== customerKeywordId));
    } else {
      onCustomerKeywordsChanged([...customerKeywordIds, customerKeywordId]);
    }
  }

  if (queryResult.loading) {
    return <Button isLoading>Loading keywords</Button>;
  }

  if (keywords.length === 0) {
    return <Button isDisabled>No Keywords</Button>;
  }

  return (
    <Stack spacing={1} isInline width="100%" flexWrap="wrap" gridRowGap={1}>
      {customerKeywordIds.map((customerKeywordId) => {
        const keyword = keywords.find((x) => x.id === customerKeywordId);
        if (!keyword) {
          return null;
        }
        return (
          <Tag key={customerKeywordId} colorScheme="blue">
            <TagLabel>
              {keyword.displayName}
              {!keyword.isActive && " (inactive)"}
            </TagLabel>
            <TagCloseButton
              onClick={() => {
                onCustomerKeywordSelected(customerKeywordId);
              }}
            />
          </Tag>
        );
      })}
      {availableKeywords.length > 0 && (
        <Menu closeOnSelect={false} closeOnBlur>
          <MenuButton as={Button}>
            <AppIcon icon={faPlus} />
            <AppIcon icon={faCaretDown} standardLeftMargin />
          </MenuButton>
          <MenuList minWidth="240px" maxH="200px" overflowY="scroll">
            <MenuOptionGroup type="checkbox" value={customerKeywordIds.map((customerKeyword) => `${customerKeyword}`)}>
              {availableKeywords.map((keyword) => (
                <MenuItemOption
                  key={keyword.id}
                  value={`${keyword.id}`}
                  onClick={() => {
                    onCustomerKeywordSelected(keyword.id);
                  }}
                >
                  {keyword.displayName}
                </MenuItemOption>
              ))}
            </MenuOptionGroup>
          </MenuList>
        </Menu>
      )}
    </Stack>
  );
}
