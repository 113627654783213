import { Alert, Text, Badge } from "@chakra-ui/react";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { DateTime } from "luxon";

import { ProfileTasksQueryResult } from "../../generated/graphql";
import AppIcon from "../../components/app-icon";
import {
  Table,
  TableHead,
  TableRow,
  TableHeaderCell,
  TableBody,
  TablePagination,
  TableCell,
  TableFoot,
} from "../../components/table";
import { DATE_FORMAT, PAGE_LIMIT, TASK_REVIEW_STATUS } from "../../constants";
import { AppColors } from "../../core/custom-theme";
import { currencyFormatter } from "../../util/formatter";

interface Props {
  queryResult: ProfileTasksQueryResult;
  onPreviousPage: () => void;
  onNextPage: () => void;
}

const IN_PROGRESS = "In Progress";

export default function ProfileTaskTable(props: Props) {
  const { queryResult, onNextPage, onPreviousPage } = props;
  const { loading, data, error } = queryResult;

  const totalCount = data?.tasks?.totalCount ?? 0;
  const tasks = data?.tasks?.nodes ?? [];

  if (error) {
    return (
      <Alert status="error">
        <AppIcon icon={faExclamationTriangle} wideRightMargin />
        An error occurred while retrieving the tasks.
      </Alert>
    );
  }

  return (
    <Table isLoading={loading} isEmpty={tasks.length === 0}>
      <TableHead>
        <TableRow key="head">
          <TableHeaderCell>Task</TableHeaderCell>
          <TableHeaderCell>Topic</TableHeaderCell>
          <TableHeaderCell>Category</TableHeaderCell>
          <TableHeaderCell>Status</TableHeaderCell>
          <TableHeaderCell>Approval Date</TableHeaderCell>
          <TableHeaderCell>Earnings</TableHeaderCell>
          <TableHeaderCell></TableHeaderCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {tasks
          .filter((task) => !task.reviewTargetTaskId)
          .map((task) => {
            const review = task?.reviewTasks?.nodes?.[0];
            const reviewStatus = review?.reviewStatus;
            return (
              <TableRow key={task.id}>
                <TableCell>{task.taskType.name}</TableCell>
                <TableCell>{task.topic ?? "-"}</TableCell>
                <TableCell>{task.category?.name ?? "-"}</TableCell>
                <TableCell>{reviewStatus?.name ?? IN_PROGRESS}</TableCell>
                <TableCell>
                  {reviewStatus?.id === TASK_REVIEW_STATUS.APPROVED && review?.completedAt
                    ? DateTime.fromJSDate(review.completedAt).toFormat(DATE_FORMAT)
                    : ""}
                </TableCell>
                <TableCell>
                  {/* TODO need to come this calculation from server */}
                  <Text color={AppColors.green}>{currencyFormatter.format(0)}</Text>
                </TableCell>
                <TableCell>
                  {/* TODO this needs to be based on new fields added to task for payments */}
                  <Badge colorScheme="green">{reviewStatus?.id === TASK_REVIEW_STATUS.APPROVED ? "Paid" : ""}</Badge>
                </TableCell>
              </TableRow>
            );
          })}
      </TableBody>
      <TableFoot>
        <TablePagination
          totalCount={totalCount}
          limit={queryResult.variables?.limit ?? PAGE_LIMIT}
          offset={queryResult.variables?.offset ?? 0}
          onNextPage={onNextPage}
          onPreviousPage={onPreviousPage}
        />
      </TableFoot>
    </Table>
  );
}
