import * as yup from "yup";
import { useFormik, FormikHelpers } from "formik";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import {
  Text,
  Input,
  FormControl,
  FormLabel,
  Stack,
  FormErrorMessage,
  useToast,
  Button,
  Box,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import axios from "axios";
import {
  ResumeFileFragment,
  useProfile_GenerateUrlForFileUploadMutation,
  useSaveProfileMutation,
  useSaveUserProfessionalMutation,
} from "../../generated/graphql";
import { getDisplayMessageForError, handleGraphQLResponseError } from "../../util/error-helper";
import TimeZoneSelector from "./timezone-selector";
import { PrimaryButton } from "../../components/buttons";
import { PanelGroup, PanelTop, PanelBottom } from "../../components/panels";
import { AppColors } from "../../core/custom-theme";
import { FileDropUpload } from "../../components/file-drop-upload";
import { DangerIconButton } from "../../components/buttons/danger-icon-button";
import { downloadFile } from "../../util/download-file";
import AppLink from "../../components/app-link";
import { processNullableString } from "../../util/form-helpers";
import EmailPreferenceSelector from "./email-preference-selector";

interface FormValues {
  id: number;
  firstName: string;
  lastName: string;
  timezone: string;
  email: string;
  password: string;
  confirmPassword: string;
  linkedInProfileUrl?: string;
  resumeFile?: ResumeFileFragment | null;
  age: number;
  gender: string;
  location: string;
  emailPreference: string;
}

const profileSchema = yup.object().shape({
  id: yup.number().required(),
  firstName: yup.string().label("First Name").required().min(2).max(255),
  lastName: yup.string().label("Last Name").required().min(2).max(255),
  timezone: yup.string().label("Time Zone").required(),
  email: yup.string().label("Email").required().email().max(255).required(),
  password: yup.string().label("Password").min(8),
  confirmPassword: yup
    .string()
    .label("Password Confirmation")
    .oneOf([yup.ref("password")], "Passwords must match")
    .min(8),
  linkedInProfileUrl: yup.string().url().label("LinkedIn URL").notRequired().nullable(),
  age: yup.number().label("Age"),
  gender: yup.string().label("Gender"),
  location: yup.string().label("Location"),
  emailPreference: yup.string().label("Email Preference"),
});

interface Props {
  user?: FormValues;
}

export default function Settings(props: Props) {
  const { user } = props;
  const toast = useToast();
  const [saveProfile] = useSaveProfileMutation();
  const [saveUserProfessional] = useSaveUserProfessionalMutation();
  const [generateUrlForUpload] = useProfile_GenerateUrlForFileUploadMutation();
  const [resumeFile, setResumeFile] = useState<File | null>(null);
  const [isFileChanged, setIsFileChanged] = useState(false);

  async function handleFormSubmit(values: FormValues, formikHelpers: FormikHelpers<FormValues>) {
    try {
      const response = await saveProfile({
        variables: {
          input: {
            userId: values.id,
            timezone: values.timezone,
            emailPreference: values.emailPreference,
          },
        },
      });

      if (response.data?.updateUser.user?.id) {
        toast({
          title: "User information saved successfully!",
          status: "success",
        });
      }

      let fileId: string | undefined | null = undefined;
      if (isFileChanged) {
        fileId = null;
        if (resumeFile) {
          const fileResponse = await generateUrlForUpload({
            variables: {
              input: {
                contentType: resumeFile.type,
                name: resumeFile.name,
                sizeInBytes: resumeFile.size,
              },
            },
          });

          const url = fileResponse.data?.generateUrlForFileUploadSignup.url;
          fileId = fileResponse.data?.generateUrlForFileUploadSignup.fileId;

          if (!url || !fileId) {
            toast({
              title: "There was a problem uploading the file. Please try again.",
              status: "error",
            });
            return;
          }

          try {
            const options = {
              headers: {
                "Content-Type": resumeFile.type,
              },
            };

            await axios.put(url, resumeFile, options);
          } catch (e: any) {
            toast({ title: "Unable to Upload", description: getDisplayMessageForError(e), status: "error" });
            return;
          }
        }
      }

      const userProfessionalResponse = await saveUserProfessional({
        variables: {
          input: {
            userId: values.id,
            resumeS3FileId: fileId,
            linkedInProfileUrl: processNullableString(values.linkedInProfileUrl ?? ""),
          },
        },
      });

      if (!userProfessionalResponse.data?.saveUserProfessional.ok) {
        if (userProfessionalResponse.data?.saveUserProfessional.error) {
          handleGraphQLResponseError(
            userProfessionalResponse.data.saveUserProfessional.error,
            toast,
            formikHelpers.setErrors
          );
        }
      }
    } catch (e: any) {
      toast({
        title: "Unable to Save",
        description: getDisplayMessageForError(e),
        status: "error",
      });
    }
  }

  const formik = useFormik<FormValues>({
    initialValues: {
      id: user?.id ?? 0,
      firstName: user?.firstName ?? "",
      lastName: user?.lastName ?? "",
      timezone: user?.timezone ?? "",
      email: user?.email ?? "",
      password: user?.password ?? "",
      confirmPassword: user?.confirmPassword ?? "",
      linkedInProfileUrl: user?.linkedInProfileUrl ?? "",
      age: user?.age ?? 0,
      gender: user?.gender ?? "",
      location: user?.location ?? "",
      emailPreference: user?.emailPreference ?? "",
    },
    validateOnBlur: true,
    validationSchema: profileSchema,
    onSubmit: handleFormSubmit,
  });

  useEffect(() => {
    async function getData() {
      if (user?.resumeFile?.url && user?.resumeFile?.name) {
        const blob = await downloadFile(user?.resumeFile.url);
        setResumeFile(new File([blob], user?.resumeFile.name, { type: blob.type }));
      }
    }

    getData();
  }, [user?.resumeFile]);

  function onTimezoneChange(event: React.ChangeEvent<{ value: unknown }>) {
    formik.setFieldValue("timezone", event.target.value);
  }

  function onEmailPreferenceChange(event: React.ChangeEvent<{ value: unknown }>) {
    formik.setFieldValue("emailPreference", event.target.value);
  }

  function handleSubmit() {
    formik.submitForm();
  }

  function onCancel() {
    formik.resetForm();
  }

  function onFileSelected(file: File) {
    setIsFileChanged(true);
    setResumeFile(file);
  }

  function removeFile() {
    setIsFileChanged(true);
    setResumeFile(null);
  }

  return (
    <PanelGroup>
      <PanelTop isHeader>
        <Text fontWeight="bold">Settings</Text>
      </PanelTop>
      <PanelBottom>
        <Stack spacing={6}>
          <FormControl isRequired isInvalid={!!formik.errors.firstName && formik.touched.firstName}>
            <FormLabel>First Name</FormLabel>
            <Input
              id="firstName"
              value={formik.values.firstName}
              variant="flushed"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.firstName && formik.errors.firstName && (
              <FormErrorMessage>{formik.errors.firstName}</FormErrorMessage>
            )}
          </FormControl>
          <FormControl isRequired isInvalid={!!formik.errors.lastName && formik.touched.lastName}>
            <FormLabel>Last Name</FormLabel>
            <Input
              id="lastName"
              value={formik.values.lastName}
              variant="flushed"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.lastName && formik.errors.lastName && (
              <FormErrorMessage>{formik.errors.lastName}</FormErrorMessage>
            )}
          </FormControl>
          <FormControl isRequired isInvalid={!!formik.errors.email && formik.touched.email}>
            <FormLabel>Email</FormLabel>
            <Input
              id="email"
              value={formik.values.email}
              variant="flushed"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.email && formik.errors.email && <FormErrorMessage>{formik.errors.email}</FormErrorMessage>}
          </FormControl>
          <FormControl>
            <EmailPreferenceSelector
              emailPreference={formik.values.emailPreference}
              handleChange={onEmailPreferenceChange}
            ></EmailPreferenceSelector>
          </FormControl>
          <FormControl>
            <TimeZoneSelector timezone={formik.values.timezone} handleChange={onTimezoneChange} />
          </FormControl>
          <FormControl>
            <FormLabel>Password</FormLabel>
            <Input
              id="password"
              value={formik.values.password}
              variant="flushed"
              type="password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              autoComplete="new-password"
            />
          </FormControl>
          <FormControl>
            <FormLabel>Confirm Password</FormLabel>
            <Input
              id="confirmPassword"
              value={formik.values.confirmPassword}
              variant="flushed"
              type="password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </FormControl>
          <FormControl isInvalid={!!formik.touched.linkedInProfileUrl && !!formik.errors.linkedInProfileUrl}>
            <FormLabel>LinkedIn Profile URL</FormLabel>
            <Input
              value={formik.values.linkedInProfileUrl ?? ""}
              variant="flushed"
              onChange={formik.handleChange}
              id="linkedInProfileUrl"
            />
            <FormErrorMessage>{formik.errors.linkedInProfileUrl}</FormErrorMessage>
          </FormControl>
          <Box>
            <FileDropUpload
              acceptedFileExtensions={{
                "application/msword": [".doc", ".docx"],
                "application/pdf": [".pdf"],
              }}
              onFileUpload={onFileSelected}
            />
            <Box textAlign="center" marginTop={1} marginBottom={2}>
              <Text fontSize="sm" color={AppColors.textGray}>
                .doc, .docx, and .pdf files accepted (Max size 10MB)
              </Text>
            </Box>
            {resumeFile && (
              <InputGroup>
                {isFileChanged ? (
                  <FormLabel>{resumeFile.name}</FormLabel>
                ) : (
                  <FormLabel>
                    <AppLink isExternal to={user?.resumeFile?.url} target="_blank">
                      {resumeFile.name}
                    </AppLink>
                  </FormLabel>
                )}
                <InputRightElement>
                  <DangerIconButton actionName="Remove" icon={faTimes} onClick={removeFile} />
                </InputRightElement>
              </InputGroup>
            )}
          </Box>
          <FormControl>
            <FormLabel>Age</FormLabel>
            <Input id="age" value={formik.values.age} variant="flushed" isReadOnly />
          </FormControl>
          <FormControl>
            <FormLabel>Gender</FormLabel>
            <Input id="gender" value={formik.values.gender} variant="flushed" isReadOnly />
          </FormControl>
          <FormControl>
            <FormLabel>Location</FormLabel>
            <Input id="location" value={formik.values.location} variant="flushed" isReadOnly />
          </FormControl>
          <FormControl>
            <PrimaryButton
              isDisabled={formik.isSubmitting}
              isLoading={formik.isSubmitting}
              loadingText="Saving..."
              marginRight={4}
              onClick={handleSubmit}
            >
              Save
            </PrimaryButton>
            <Button onClick={onCancel} variant="ghost">
              Cancel
            </Button>
          </FormControl>
        </Stack>
      </PanelBottom>
    </PanelGroup>
  );
}
