import { useToast } from "@chakra-ui/react";
import { useContentStrategyV2TaskWorkspaceQuery } from "../../../../generated/graphql";
import { TaskWorkspaceCommonProps } from "../../task-workspace-common-props";

export function useContentStrategyV2(options: TaskWorkspaceCommonProps) {
  const { task: propsTask, onComplete } = options;

  const queryResult = useContentStrategyV2TaskWorkspaceQuery({
    fetchPolicy: "network-only",
    variables: {
      taskId: propsTask.id,
      ignoreUserTaskPermission: true,
    },
  });
  const toast = useToast();

  const task = queryResult.data?.task ?? null;
  const customer = task?.customer ?? null;
  const customerQuestionnaire = customer?.customerQuestionnaire ?? null;
  const customerProposal = task?.customerProposal ?? null;
  const orderContentStrategies = customerProposal?.orderContentStrategies ?? [];
  const reviewTasks = (task?.reviewTasks.nodes ?? []).filter((reviewTask) => !!reviewTask.reviewComment);

  const canSubmit = orderContentStrategies.every((x) => x.isComplete);

  function handleComplete() {
    if (!canSubmit) {
      toast({
        title: "Unable to Submit for Client Review",
        description: "All content strategies must be complete in order to submit for client review.",
        status: "error",
      });
      return;
    }
    onComplete();
  }

  return {
    isLoading: queryResult.loading,
    task,
    customer,
    customerQuestionnaire,
    customerProposal,
    orderContentStrategies,
    reviewTasks,
    canSubmit,
    handleComplete,
    refetch: queryResult.refetch,
  };
}
