import { useParams } from "react-router-dom";
import { useQueryParam, BooleanParam, withDefault } from "use-query-params";

import { useContentViewQuery } from "../../generated/graphql";

export function useContentView() {
  const { id } = useParams<"id">();
  const taskId = parseInt(id ?? "");

  const [showRevision] = useQueryParam("showRevision", withDefault(BooleanParam, false));

  const queryResult = useContentViewQuery({
    fetchPolicy: "network-only",
    variables: {
      taskId,
    },
  });

  const task = queryResult.data?.task ?? null;

  return {
    loading: queryResult.loading,
    task,
    showRevision,
  };
}
