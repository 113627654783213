import { Suspense, lazy } from "react";
import { Routes as RouterRoutes, Route } from "react-router-dom";
import { ConfirmEmailRoute } from "./confirm-email";
import { NotFoundRoute } from "./not-found";
import { OnboardingSignupRoute } from "./onboarding-signup";
import { LoginRoute } from "./login";
import { ForgotPasswordRoute } from "./forgot-password";
import { OnboardingGrammarTestRoute } from "./onboarding-grammar-test";
import { OnboardingWelcomeRoute } from "./onboarding-welcome";
import { OnboardingWritingSampleRoute } from "./onboarding-writing-sample";
import { OnboardingNonWriterRoute } from "./onboarding-non-writer";
import { OnboardingUnsupportedCountryRoute } from "./onboarding-unsupported-country";
import { DefaultRoute } from "./_default";
import { OnboardingEmailConfirmationNeededRoute } from "./onboarding-email-confirmation-needed";
import { OnboardingJourneyRoute } from "./onboarding-journey";
import { ProfileRoute } from "./profile";
import { MyTasksRoute } from "./my-tasks";
import { FindTasksRoute } from "./find-tasks";
import { ProfileTasks } from "./profile-tasks";
import { AdminGrammarTestQuestionsRoute } from "./admin-grammar-test-questions";
import { AdminTeamMembersRoute } from "./admin-team-members";
import { OnboardingStartStripeRoute } from "./onboarding-start-stripe";
import { CSAdminRequired } from "../components/cs-admin-required";
import { OnboardingGrammarTestResultRoute } from "./onboarding-grammar-test-result";
import { TaskDetailsRoute } from "./task-details";
import { TaskContentView } from "./task-content-view";
import { OnboardingRouteAuthorization } from "../common/onboarding-route-authorization";
import { ONBOARDING_STATUS } from "../constants";
import { AdminTeamMemberProfileRoute } from "./admin-team-member-profile";
import { AdminTeamMemberTasksHistoryRoute } from "./admin-team-member-task-history";
import { AdminWritingSampleReview } from "./admin-writing-sample-review";
import { AdminWritingSamplesRoute } from "./admin-writing-samples";
import { ResetPasswordRoute } from "./reset-password";
import { LogoutRoute } from "./logout";
import { StripeDashboardRoute } from "./stripe-dashboard";
import { UnsubscribePaymentIdentityReminder } from "./unsubscribe-payment-identity-reminder";
import { AdminWritingSampleTopicsRoute } from "./admin-writing-sample-topics";
import { RouteAuthorization } from "../common/route-authorization";
import { AdminTasksRoute } from "./admin-tasks";
import { AdminQaViewRoute } from "./admin-qa-view";
import { Loader } from "../components/loader";
const UserReports = lazy(() => import("./user-reports"));
const AdminUserTaskReports = lazy(() => import("./admin-user-task-reports"));

export function Routes() {
  return (
    <Suspense fallback={<Loader />}>
      <RouterRoutes>
        <Route path="/" element={<DefaultRoute />} />
        <Route path="/confirm/:confirmationToken" element={<ConfirmEmailRoute />} />
        <Route path="/signup" element={<OnboardingSignupRoute />} />
        <Route path="/login" element={<LoginRoute />} />
        <Route path="/logout" element={<LogoutRoute />} />
        <Route path="/forgot-password" element={<ForgotPasswordRoute />} />
        <Route path="/reset-password/:id" element={<ResetPasswordRoute />} />
        <Route
          path="/grammar-test"
          element={
            <OnboardingRouteAuthorization
              allowedOnboardingStatuses={[
                ONBOARDING_STATUS.READY_FOR_GRAMMAR_TEST.id,
                ONBOARDING_STATUS.GRAMMAR_TEST_IN_PROGRESS.id,
              ]}
            >
              <OnboardingGrammarTestRoute />
            </OnboardingRouteAuthorization>
          }
        />
        <Route
          path="/grammar-test-result"
          element={
            <OnboardingRouteAuthorization
              allowedOnboardingStatuses={[
                ONBOARDING_STATUS.GRAMMAR_TEST_IN_PROGRESS.id,
                ONBOARDING_STATUS.WAITING_FOR_GRAMMAR_TEST_RETRY.id,
                ONBOARDING_STATUS.READY_FOR_WRITING_SAMPLE.id,
              ]}
            >
              <OnboardingGrammarTestResultRoute />
            </OnboardingRouteAuthorization>
          }
        />
        <Route path="/email-confirmation-needed" element={<OnboardingEmailConfirmationNeededRoute />} />
        <Route path="/welcome" element={<OnboardingWelcomeRoute />} />
        <Route
          path="/writing-sample"
          element={
            <OnboardingRouteAuthorization
              allowedOnboardingStatuses={[
                ONBOARDING_STATUS.READY_FOR_WRITING_SAMPLE.id,
                ONBOARDING_STATUS.WRITING_SAMPLE_IN_PROGRESS.id,
              ]}
            >
              <OnboardingWritingSampleRoute />
            </OnboardingRouteAuthorization>
          }
        />
        <Route path="/not-ready-yet" element={<OnboardingNonWriterRoute />} />
        <Route path="/unsupported-country" element={<OnboardingUnsupportedCountryRoute />} />
        <Route path="/journey" element={<OnboardingJourneyRoute />} />
        <Route
          path="/profile"
          element={
            <RouteAuthorization requiresCompletedOnboarding>
              <ProfileRoute />
            </RouteAuthorization>
          }
        />
        <Route
          path="/my-tasks"
          element={
            <RouteAuthorization requiresCompletedOnboarding>
              <MyTasksRoute />
            </RouteAuthorization>
          }
        />
        <Route
          path="/find-tasks"
          element={
            <RouteAuthorization requiresCompletedOnboarding>
              <FindTasksRoute />
            </RouteAuthorization>
          }
        />
        <Route
          path="/user-task-report"
          element={
            <RouteAuthorization requiresCompletedOnboarding>
              <UserReports />
            </RouteAuthorization>
          }
        />
        <Route
          path="/tasks/:id/view"
          element={
            <RouteAuthorization requiresCompletedOnboarding>
              <TaskContentView />
            </RouteAuthorization>
          }
        />
        <Route
          path="/tasks/:id"
          element={
            <RouteAuthorization requiresCompletedOnboarding>
              <TaskDetailsRoute />
            </RouteAuthorization>
          }
        />
        <Route
          path="/profile/tasks"
          element={
            <RouteAuthorization requiresCompletedOnboarding>
              <ProfileTasks />
            </RouteAuthorization>
          }
        />
        <Route
          path="/admin/grammar-test-questions"
          element={
            <CSAdminRequired>
              <AdminGrammarTestQuestionsRoute />
            </CSAdminRequired>
          }
        />
        <Route
          path="/admin/tasks"
          element={
            <CSAdminRequired>
              <AdminTasksRoute />
            </CSAdminRequired>
          }
        />
        <Route
          path="/admin/qa-view"
          element={
            <CSAdminRequired>
              <AdminQaViewRoute />
            </CSAdminRequired>
          }
        />
        <Route
          path="/admin/team-members"
          element={
            <CSAdminRequired>
              <AdminTeamMembersRoute />
            </CSAdminRequired>
          }
        />
        <Route
          path="/admin/team-members/:id"
          element={
            <CSAdminRequired>
              <AdminTeamMemberProfileRoute />
            </CSAdminRequired>
          }
        />
        <Route
          path="/admin/team-members/:id/tasks"
          element={
            <CSAdminRequired>
              <AdminTeamMemberTasksHistoryRoute />
            </CSAdminRequired>
          }
        />
        <Route
          path="/admin/writing-sample-topics"
          element={
            <CSAdminRequired>
              <AdminWritingSampleTopicsRoute />
            </CSAdminRequired>
          }
        />
        <Route
          path="/admin/writing-samples"
          element={
            <CSAdminRequired>
              <AdminWritingSamplesRoute />
            </CSAdminRequired>
          }
        />
        <Route
          path="/admin/writing-samples/:id"
          element={
            <CSAdminRequired>
              <AdminWritingSampleReview />
            </CSAdminRequired>
          }
        />
        <Route
          path="/admin/user-task-reports"
          element={
            <CSAdminRequired>
              <AdminUserTaskReports />
            </CSAdminRequired>
          }
        />
        <Route path="/start-stripe-onboarding" element={<OnboardingStartStripeRoute />} />
        <Route path="/stripe-dashboard" element={<StripeDashboardRoute />} />
        <Route path="/unsubscribe/payment-identity-reminder" element={<UnsubscribePaymentIdentityReminder />} />
        <Route path="*" element={<NotFoundRoute />} />
      </RouterRoutes>
    </Suspense>
  );
}
