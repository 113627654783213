import { useState } from "react";
import ReactHelmet from "react-helmet";
import { DateTime } from "luxon";
import { Text, Box, Divider, Flex, Stack } from "@chakra-ui/react";
import {
  useGrammarTestQuery,
  useSaveUserGrammarTestResponseMutation,
  GrammarTest_OptionFragment,
} from "../../generated/graphql";
import { AppColors } from "../../core/custom-theme";
import Header from "../../components/header";
import { ContentContainer } from "../../components/content-container";
import { Global } from "@emotion/react";
import { NO_QUESTION_GRAMMAR_TEST, LOADER_TYPE, ONBOARDING_STATUS } from "../../constants";
import { PrimaryButton } from "../../components/buttons";
import { Loader } from "../../components/loader";
import { CountdownText } from "../../components/countdown-text";
import { useExpiration } from "../../util/use-expiration";
import { Navigate } from "react-router-dom";
import { PanelBox } from "../../components/panels";

export function OnboardingGrammarTestRoute() {
  const [answerId, setAnswerId] = useState(-1);

  const { data, loading, refetch } = useGrammarTestQuery({
    fetchPolicy: "network-only",
  });

  const [saveAnswer, { loading: answerLoading }] = useSaveUserGrammarTestResponseMutation();
  const expiresAt =
    data?.viewer.creativesScaleUser?.activeUserGrammarTest?.expiresAt ??
    DateTime.now().plus({ minutes: 30 }).toJSDate();
  const [isExpired] = useExpiration(expiresAt);

  const onboardingStatusId = data?.viewer.creativesScaleUser?.onboardingStatusId;
  const activeUserGrammarTest = data?.viewer.creativesScaleUser?.activeUserGrammarTest ?? null;
  const latestUserGrammarTest = data?.viewer.creativesScaleUser?.latestUserGrammarTest ?? null;

  // If the test is expired but the status is still in progress, refetch, the status should be updated
  if (isExpired) {
    console.log("Grammar Test has expired, redirecting to /grammar-test-result");
    return <Navigate to="/grammar-test-result" />;
  }

  if (!loading && !activeUserGrammarTest && latestUserGrammarTest) {
    console.log(
      "Unable to find active Grammar Test but found a latest Grammar Test, redirecting to /grammar-test-result"
    );
    return <Navigate to="/grammar-test-result" />;
  }

  if (!loading && onboardingStatusId === ONBOARDING_STATUS.READY_FOR_GRAMMAR_TEST.id) {
    console.log("Status is ready for grammar test, redirecting to /");
    return <Navigate to="/" />;
  }

  if (loading) {
    return <Loader type={LOADER_TYPE.fullView} />;
  }

  const activeQuestion = activeUserGrammarTest?.activeQuestion ?? null;
  if (!activeQuestion) {
    console.log("Unable to find active question, redirecting to /");
    return <Navigate to="/" />;
  }

  function onSelectAnswer(answerId: number) {
    setAnswerId(answerId);
  }

  async function onNext() {
    await saveAnswer({
      variables: {
        input: {
          answerId,
        },
      },
    });
    setAnswerId(-1);
    refetch();
  }

  function renderOption(option: GrammarTest_OptionFragment) {
    const isSelected = option.id === answerId;
    return (
      <Box
        bg={isSelected ? AppColors.blue : "white"}
        color={isSelected ? "white" : "inherit"}
        onClick={onSelectAnswer.bind(null, option.id)}
        display="flex"
        alignItems="center"
        justifyContent="center"
        cursor="pointer"
        p={4}
        borderRadius={4}
        border={`1px solid ${AppColors.borderColor}`}
      >
        <Text>{option.content}</Text>
      </Box>
    );
  }

  return (
    <>
      <Global styles={{ body: { backgroundColor: AppColors.body } }} />
      <ReactHelmet>
        <link href="https://fonts.googleapis.com/css2?family=Arvo&display=swap" rel="stylesheet" />
      </ReactHelmet>
      <Header />
      {loading && <Loader type={LOADER_TYPE.fullView} />}
      {!loading && (
        <ContentContainer centerHorizontally>
          <Box p={4}>
            <Box display="flex">
              <PanelBox py={2} borderRadius={0} width="50%">
                <Text textAlign="center" fontSize="xl" fontWeight="bold">{`${
                  activeQuestion.questionIndex + 1
                }/${NO_QUESTION_GRAMMAR_TEST}`}</Text>
              </PanelBox>
              <PanelBox py={2} borderRadius={0} width="50%">
                <CountdownText
                  textAlign="center"
                  color={AppColors.textOrange}
                  fontSize="xl"
                  fontWeight="bold"
                  date={data?.viewer.creativesScaleUser?.activeUserGrammarTest?.expiresAt ?? new Date()}
                />
              </PanelBox>
            </Box>
            <PanelBox textAlign="center">
              <Text as="h1" fontSize={{ base: "xl", md: "3xl" }} textAlign="center" mb={2} fontWeight="bold">
                Grammar Assessment
              </Text>
              <Flex justifyContent="center">
                <Text textAlign="center" mb={4} maxW={700} justifyContent="center">
                  Choose the correct answer to complete the sentence. You may only choose one answer. Once an answer has
                  been submitted, you may not go back to change your answer.
                </Text>
              </Flex>
              <Divider />
              <Text textAlign="center" fontSize="xl" marginTop={8} marginBottom={12} fontFamily="'Arvo', serif">
                {activeQuestion.grammarTestQuestion.prompt}
              </Text>
              <Flex justifyContent="center">
                <Box maxWidth="260px">
                  <Stack spacing={3}>
                    {!!activeQuestion.option1Answer && renderOption(activeQuestion.option1Answer)}
                    {!!activeQuestion.option2Answer && renderOption(activeQuestion.option2Answer)}
                    {!!activeQuestion.option3Answer && renderOption(activeQuestion.option3Answer)}
                    {!!activeQuestion.option4Answer && renderOption(activeQuestion.option4Answer)}
                  </Stack>
                </Box>
              </Flex>
              <Box textAlign="center" my={8}>
                <PrimaryButton
                  onClick={onNext}
                  isLoading={answerLoading}
                  paddingX={24}
                  isDisabled={answerId <= 0 || new Date() > expiresAt}
                >
                  Next
                </PrimaryButton>
              </Box>
            </PanelBox>
          </Box>
        </ContentContainer>
      )}
    </>
  );
}
