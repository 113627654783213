import { useState } from "react";
import * as yup from "yup";
import { useToast } from "@chakra-ui/react";
import { useFormik } from "formik";
import axios from "axios";

import {
  useCreateUserProfessionalMutation,
  useCreativesScaleSignupMutation,
  useSignup_GenerateUrlForFileUploadMutation,
  useSignupViewerQuery,
} from "../../generated/graphql";
import { processNullableString } from "../../util/form-helpers";
import { getDisplayMessageForError, handleGraphQLResponseError } from "../../util/error-helper";

interface FormValues {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  confirmPassword: string;
  linkedInProfileUrl?: string | null;
  isAuthorizedToDoWorkInUS: string;
  isTermsAccepted: boolean;
}

const validationSchemaWithPassword = yup.object().shape({
  firstName: yup.string().label("First Name").required().min(2).max(255),
  lastName: yup.string().label("Last Name").required().min(2).max(255),
  email: yup.string().label("Email").required().email().max(255),
  password: yup.string().label("Password").required().min(8),
  confirmPassword: yup
    .string()
    .label("Password Confirmation")
    .required()
    .oneOf([yup.ref("password")], "Passwords must match")
    .min(8),
  linkedInProfileUrl: yup.string().url().label("LinkedIn URL").notRequired().nullable(),
  isTermsAccepted: yup.bool().label("Acceptance").required().oneOf([true], "Terms must be accepted"),
});

const validationSchemaWithoutPassword = yup.object().shape({
  isTermsAccepted: yup.bool().label("Acceptance").required().oneOf([true], "Terms must be accepted"),
});

export function useSignup() {
  const [wasSuccessful, setWasSuccessful] = useState(false);
  const [resumeFile, setResumeFile] = useState<File | null>(null);
  const [signupMutation] = useCreativesScaleSignupMutation();
  const [generateUrlForUpload] = useSignup_GenerateUrlForFileUploadMutation();
  const [createUserProfessional] = useCreateUserProfessionalMutation();
  const toast = useToast();
  const queryResult = useSignupViewerQuery({
    fetchPolicy: "network-only",
  });

  const viewer = queryResult.data?.viewer;
  const existingUser = viewer?.user;
  const isExistingUser = !!existingUser;
  const isExistingCSUser = !!viewer?.creativesScaleUser;

  const formik = useFormik<FormValues>({
    initialValues: {
      firstName: viewer?.user?.firstName ?? "",
      lastName: viewer?.user?.lastName ?? "",
      email: viewer?.user?.email ?? "",
      password: "",
      confirmPassword: "",
      linkedInProfileUrl: "",
      isAuthorizedToDoWorkInUS: "yes",
      isTermsAccepted: false,
    },
    enableReinitialize: true,
    validateOnBlur: true,
    validationSchema: existingUser?.isPasswordSet ? validationSchemaWithoutPassword : validationSchemaWithPassword,
    onSubmit: async (values, formikHelpers) => {
      const response = await signupMutation({
        variables: {
          input: {
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            isAuthorizedToWorkInUS: values.isAuthorizedToDoWorkInUS === "yes",
            password: values.password,
          },
        },
      });

      let fileId: string | undefined = undefined;
      if (response.data?.signupCSUser.ok === true) {
        setWasSuccessful(true);
        if (resumeFile) {
          const fileResponse = await generateUrlForUpload({
            variables: {
              input: {
                contentType: resumeFile.type,
                name: resumeFile.name,
                sizeInBytes: resumeFile.size,
              },
            },
          });

          const url = fileResponse.data?.generateUrlForFileUploadSignup.url;
          fileId = fileResponse.data?.generateUrlForFileUploadSignup.fileId;

          if (!url || !fileId) {
            toast({
              title: "There was a problem uploading the file. Please try again.",
              status: "error",
            });
            return;
          }

          try {
            const options = {
              headers: {
                "Content-Type": resumeFile.type,
              },
            };

            await axios.put(url, resumeFile, options);
          } catch (e: any) {
            toast({ title: "Unable to Upload", description: getDisplayMessageForError(e), status: "error" });
            return;
          }
        }

        const userProfessionalResponse = await createUserProfessional({
          variables: {
            input: {
              userId: response.data.signupCSUser.creativesScaleUser?.userId ?? 0,
              resumeS3FileId: fileId,
              linkedInProfileUrl: processNullableString(values.linkedInProfileUrl ?? ""),
            },
          },
        });

        if (!userProfessionalResponse.data?.createUserProfessional.ok) {
          if (userProfessionalResponse.data?.createUserProfessional.error) {
            handleGraphQLResponseError(
              userProfessionalResponse.data.createUserProfessional.error,
              toast,
              formikHelpers.setErrors
            );
          }
        }
      } else if (response.data?.signupCSUser.error) {
        handleGraphQLResponseError(response.data.signupCSUser.error, toast, formikHelpers.setErrors);
      }
    },
  });

  function onFileSelected(file: File) {
    setResumeFile(file);
  }

  function removeFile() {
    setResumeFile(null);
  }

  return {
    existingUser,
    isExistingCSUser,
    isExistingUser,
    wasSuccessful,
    formik,
    resumeFile,
    onFileSelected,
    removeFile,
  };
}
