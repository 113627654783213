import { useLocation, useNavigate } from "react-router-dom";
import { Global } from "@emotion/react";
import queryString from "qs";
import { Box, Menu, MenuList, MenuButton, MenuOptionGroup, MenuItemOption } from "@chakra-ui/react";
import { ArrowUpDownIcon } from "@chakra-ui/icons";
import { getQueryParams, setParams } from "../../util/query-param-helper";
import { useWritingSamplesQuery } from "../../generated/graphql";
import { AppColors } from "../../core/custom-theme";
import { ContentContainer } from "../../components/content-container";
import SearchBox from "../../components/search-box";
import { SecondaryButton } from "../../components/buttons";
import WritingSampleTable from "./writing-sample-table";
import { PanelGroup, PanelTop, PanelBottom } from "../../components/panels";
import { PageHeading } from "../../components/page-heading";
import { MainNav } from "../../components/main-nav";
import { PAGE_LIMIT } from "../../constants";

export function AdminWritingSamplesRoute() {
  const location = useLocation();
  const navigate = useNavigate();

  const queryParams = getQueryParams(location.search, queryString) || {};
  const pageOffset = queryParams?.offset ? parseInt(queryParams.offset, 10) : 0;

  const queryResult = useWritingSamplesQuery({
    variables: {
      limit: PAGE_LIMIT,
      offset: pageOffset,
      search: queryParams?.q ?? "",
      sort: queryParams?.sort ?? "CREATED_AT_ASC",
      reviewedAt: {
        orIsNull: true,
      },
    },
    fetchPolicy: "network-only",
  });

  function onSearch(value: string) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { q, ...remainingQueryParams } = queryParams;
    const params = setParams(remainingQueryParams, "q", value);
    navigate({ search: `?${params}` });
  }

  function onNextPage() {
    const { offset, ...remainingQueryParams } = queryParams;
    const newOffset = parseInt(offset ?? "0", 10) + PAGE_LIMIT;
    const params = setParams(remainingQueryParams, "offset", newOffset.toString());
    navigate({ search: `?${params}` });
  }

  function onPreviousPage() {
    const { offset, ...remainingQueryParams } = queryParams;
    const newOffset = Math.max(parseInt(offset ?? "0", 10) - PAGE_LIMIT, 0);
    const params = setParams(remainingQueryParams, "offset", newOffset.toString());
    navigate({ search: `?${params}` });
  }

  function onSortClick(value: any) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { sort, ...remainingQueryParams } = queryParams;
    const params = setParams(remainingQueryParams, "sort", value);
    navigate({ search: `?${params}` });
  }

  return (
    <>
      <Global styles={{ body: { backgroundColor: AppColors.body } }} />
      <MainNav />
      <ContentContainer centerHorizontally>
        <PageHeading>Writing Sample Reviews</PageHeading>
        <PanelGroup>
          <PanelTop showBottomBorder>
            <Box display="flex" justifyContent="space-between">
              <Box>
                <SearchBox onChange={onSearch} placeholder="Search" defaultValue={queryParams.q ?? ""} />
              </Box>
              <Box display="flex">
                <Box marginX={2}>
                  <Menu>
                    <MenuButton as={SecondaryButton}>
                      Sort <ArrowUpDownIcon ml={2} />
                    </MenuButton>
                    <MenuList>
                      <MenuOptionGroup
                        defaultValue="CREATED_AT_ASC"
                        title="Created At"
                        type="radio"
                        onChange={onSortClick}
                        value={queryParams.sort}
                      >
                        <MenuItemOption value="CREATED_AT_ASC">Ascending</MenuItemOption>
                        <MenuItemOption value="CREATED_AT_DESC">Descending</MenuItemOption>
                      </MenuOptionGroup>
                    </MenuList>
                  </Menu>
                </Box>
              </Box>
            </Box>
          </PanelTop>
          <PanelBottom containsTable>
            <WritingSampleTable queryResult={queryResult} onPreviousPage={onPreviousPage} onNextPage={onNextPage} />
          </PanelBottom>
        </PanelGroup>
      </ContentContainer>
    </>
  );
}
