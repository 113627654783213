import { useToast } from "@chakra-ui/react";
import { useState } from "react";
import { PAGE_LIMIT } from "../../constants";
import { useAdminWritingSampleTopicsQuery, useAdminWritingSampleTopics_UpdateMutation } from "../../generated/graphql";
import { getDisplayMessageForError } from "../../util/error-helper";
import { usePagination } from "../../util/use-pagination";

export function useAdminWritingSampleTopics() {
  const [selectedWritingSampleTopicId, setSelectedWritingSampleTopicId] = useState<number | null>(null);
  const pagination = usePagination(PAGE_LIMIT);
  const queryResult = useAdminWritingSampleTopicsQuery({
    fetchPolicy: "network-only",
    variables: {
      limit: PAGE_LIMIT,
      offset: pagination.offset,
    },
  });
  const [updateWritingSampleTopicMutation] = useAdminWritingSampleTopics_UpdateMutation();
  const toast = useToast();

  const writingSampleTopics = queryResult.data?.writingSampleTopics.nodes ?? [];

  function handleAddTopic() {
    setSelectedWritingSampleTopicId(0);
  }

  function handleEditTopic(writingSampleTopicId: number) {
    setSelectedWritingSampleTopicId(writingSampleTopicId);
  }

  function handleCancelDrawer() {
    setSelectedWritingSampleTopicId(null);
  }

  function handleDrawerSaved() {
    queryResult.refetch();
  }

  async function handleIsActiveChange(writingSampleTopicId: number, isActive: boolean) {
    try {
      await updateWritingSampleTopicMutation({
        variables: {
          input: {
            writingSampleTopicId,
            isActive,
          },
        },
      });
      queryResult.refetch();
    } catch (e: any) {
      toast({
        title: "Unable to Change Is Active",
        description: getDisplayMessageForError(e),
        status: "warning",
      });
    }
  }

  const selectedWritingSampleTopic = selectedWritingSampleTopicId
    ? writingSampleTopics.find((x) => x.id === selectedWritingSampleTopicId) ?? null
    : null;

  return {
    loading: queryResult.loading,
    selectedWritingSampleTopic,
    selectedWritingSampleTopicId,
    handleAddTopic,
    handleEditTopic,
    handleCancelDrawer,
    handleDrawerSaved,
    writingSampleTopics,
    isDrawerOpen: selectedWritingSampleTopicId !== null,
    pagination,
    totalCount: queryResult.data?.writingSampleTopics.totalCount ?? 0,
    handleIsActiveChange,
  };
}
