import React from "react";
import { FormControl, FormLabel, Input, Stack, Textarea } from "@chakra-ui/react";
import { AppColors } from "../../../../core/custom-theme";

interface Props {
  titleTag: string;
  metaDescription: string;
  url: string;
  onTitleTagChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onMetaDescriptionChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onUrlChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}
export function TitleMetaDescriptionRow(props: Props) {
  const { titleTag, metaDescription, url, onTitleTagChange, onMetaDescriptionChange, onUrlChange } = props;

  return (
    <Stack spacing={4} marginY={4} border={`1px solid ${AppColors.borderColor}`} padding={2} borderRadius={4}>
      <FormControl isRequired>
        <FormLabel>Title Tag</FormLabel>
        <Input value={titleTag} onChange={onTitleTagChange} />
      </FormControl>
      <FormControl isRequired>
        <FormLabel>Meta Description</FormLabel>
        <Textarea value={metaDescription} resize="none" onChange={onMetaDescriptionChange} />
      </FormControl>
      <FormControl isRequired>
        <FormLabel>Url</FormLabel>
        <Input value={url} onChange={onUrlChange} />
      </FormControl>
    </Stack>
  );
}
