import { FormControl, FormLabel, Stack, Select } from "@chakra-ui/react";
import {
  TeamMemberProfile_ProductTaskTypeFragment,
  TeamMemberProfile_UserTaskPermissionFragment,
} from "../../generated/graphql";
import { WildcardPermissionItem } from "./wildcard-permission-item";
import { usePermissions } from "./use-permissions";
import { PrimaryButton } from "../../components/buttons";
import { PermissionItem } from "./permission-item";

interface Props {
  userId: number;
  productTaskTypes: TeamMemberProfile_ProductTaskTypeFragment[];
  userTaskPermissions: TeamMemberProfile_UserTaskPermissionFragment[];
  onPermissionsUpdated: () => void;
}

export function Permissions(props: Props) {
  const {
    createUserTaskPermission,
    createWildcardUserPermission,
    deleteUserTaskPermission,
    handleWildcardProductChange,
    handleWildcardTaskTypeChange,
    productTaskTypes,
    products,
    taskTypes,
    userTaskPermissions,
    wildcardProductId,
    wildcardTaskTypeId,
    wildcardUserTaskPermissions,
  } = usePermissions(props);

  return (
    <Stack spacing={4}>
      <FormControl>
        <FormLabel>Individual Task Permissions</FormLabel>
        <Stack spacing={1} maxHeight="25vh" overflowY="scroll" borderRadius={4} border={`1px solid #E2E8F0`}>
          {productTaskTypes.map((productTaskType) => {
            const wildcardTaskPermission = userTaskPermissions.find((x) => {
              if (!x.product && (!x.taskType || x.taskType.id === productTaskType.taskType.id)) {
                return true;
              }
              if (!x.taskType && x.product?.id === productTaskType.product.id) {
                return true;
              }
              return false;
            });
            const isGrantedByWildcard = !!wildcardTaskPermission;
            const taskPermission = userTaskPermissions.find(
              (x) =>
                (x.product?.id ?? 0) === productTaskType.product.id &&
                (x.taskType?.id ?? 0) === productTaskType.taskType.id
            );
            const isGranted = !!taskPermission || !!wildcardTaskPermission;
            return (
              <PermissionItem
                key={productTaskType.id}
                isGranted={isGranted}
                isGrantedByWildcard={isGrantedByWildcard}
                product={productTaskType.product}
                taskType={productTaskType.taskType}
                onCreate={() => {
                  createUserTaskPermission(productTaskType.product.id, productTaskType.taskType.id);
                }}
                onDelete={() => {
                  if (taskPermission) {
                    deleteUserTaskPermission(taskPermission.id);
                  }
                }}
              />
            );
          })}
        </Stack>
      </FormControl>
      <FormControl>
        <FormLabel>Wildcard Task Permissions</FormLabel>
        <Stack spacing={1}>
          {wildcardUserTaskPermissions.map((permission) => (
            <WildcardPermissionItem
              key={permission.id}
              product={permission.product}
              taskType={permission.taskType}
              onDelete={() => {
                deleteUserTaskPermission(permission.id);
              }}
            />
          ))}
        </Stack>
        <Stack spacing={2}>
          <Stack spacing={1} isInline marginTop={4}>
            <Select
              value={wildcardProductId}
              onChange={(e) => {
                handleWildcardProductChange(e.target.value);
              }}
            >
              <option value="">Any Product</option>
              {products.map((product) => (
                <option key={product.id} value={product.id}>
                  {product.name}
                </option>
              ))}
            </Select>
            <Select
              value={wildcardTaskTypeId}
              onChange={(e) => {
                handleWildcardTaskTypeChange(e.target.value);
              }}
            >
              <option value="">Any Task Type</option>
              {taskTypes.map((taskType) => (
                <option key={taskType.id} value={taskType.id}>
                  {taskType.name}
                </option>
              ))}
            </Select>
          </Stack>
          <PrimaryButton onClick={createWildcardUserPermission}>Add</PrimaryButton>
        </Stack>
      </FormControl>
    </Stack>
  );
}
