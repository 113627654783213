import { Navigate, useParams } from "react-router-dom";
import { useTasksHistoryTeamMemberQuery } from "../../generated/graphql";
import { TeamMemberTasksHistory } from "./team-member-task-history";
import { Loader } from "../../components/loader";
import { LOADER_TYPE } from "../../constants";

export function AdminTeamMemberTasksHistoryRoute() {
  const params = useParams<"id">();
  const { id } = params;
  if (!id) {
    return <Navigate to="/admin/team-members" />;
  }
  const teamMemberId = parseInt(id, 10);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { data, loading } = useTasksHistoryTeamMemberQuery({
    variables: {
      creativesScaleUserId: teamMemberId,
    },
  });

  if (loading) {
    return <Loader type={LOADER_TYPE.fullView} />;
  }

  return <TeamMemberTasksHistory teamMemberId={teamMemberId} userId={data?.creativesScaleUser?.user.id ?? 0} />;
}
