import { DateTime } from "luxon";
import { Box, Text, Image, Flex, useToast, Stack } from "@chakra-ui/react";
import { PrimaryButton } from "../../components/buttons";
import { JourneyStepIconBox, JourneyStepContainer } from "../journey-step-common";
import {
  useJourneyStepWritingSampleQuery,
  useStartWritingSampleMutation,
  StartWritingSampleMutationResult,
} from "../../generated/graphql";
import { useNavigate } from "react-router-dom";
import { Loader } from "../../components/loader";
import AppIcon from "../../components/app-icon";
import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";
import { CountdownText } from "../../components/countdown-text";
import SampleSVG from "../../assets/file.svg";
import { AppColors } from "../../core/custom-theme";
import { DATE_NUMBER_FORMAT, LOADER_TYPE, ONBOARDING_STATUS } from "../../constants";

interface Props {
  onboardingStatusId: string;
}

export function JourneyStepWritingSample(props: Props) {
  const { onboardingStatusId } = props;
  const navigate = useNavigate();
  const toast = useToast();
  const queryResult = useJourneyStepWritingSampleQuery({ fetchPolicy: "network-only" });
  const [startWritingSampleMutation] = useStartWritingSampleMutation();

  if (queryResult.loading) {
    return <Loader type={LOADER_TYPE.content} />;
  }

  const canBegin = onboardingStatusId === ONBOARDING_STATUS.READY_FOR_WRITING_SAMPLE.id;
  const isInProgress = onboardingStatusId === ONBOARDING_STATUS.WRITING_SAMPLE_IN_PROGRESS.id;
  const isInReview = onboardingStatusId === ONBOARDING_STATUS.WAITING_FOR_WRITING_SAMPLE_REVIEW.id;
  const isCompleted = [ONBOARDING_STATUS.READY_FOR_IDENTITY_PAYMENT_SETUP.id, ONBOARDING_STATUS.COMPLETED.id].includes(
    onboardingStatusId
  );
  const isWaitingForRetry = onboardingStatusId === ONBOARDING_STATUS.WAITING_FOR_WRITING_SAMPLE_RETRY.id;

  async function onBegin() {
    const response = (await startWritingSampleMutation()) as StartWritingSampleMutationResult;
    if (response.data?.startWritingSample.ok) {
      navigate("/writing-sample");
    } else {
      toast({
        title: "Something went wrong",
        description:
          response.data?.startWritingSample.error?.message ??
          "Something went wrong while trying to begin the writing sample. Please try again.",
        status: "error",
      });
    }
  }

  function onResume() {
    navigate("/writing-sample");
  }

  function renderActionArea() {
    if (isCompleted || isInReview) {
      return (
        <Box marginTop={{ base: 0, md: 2 }} textAlign={{ base: "center", md: "left" }}>
          <Text color={AppColors.textDarkGreen} textTransform="uppercase" fontWeight="bold">
            <AppIcon icon={faCheckCircle} standardRightMargin />
            Completed
          </Text>
        </Box>
      );
    }
    if (isInProgress) {
      return (
        <Box marginTop={{ base: 0, md: 2 }}>
          <PrimaryButton onClick={onResume} width="100%">
            Resume
          </PrimaryButton>
        </Box>
      );
    }
    return (
      <Box marginTop={{ base: 0, md: 2 }}>
        <PrimaryButton onClick={onBegin} isDisabled={!canBegin} width="100%">
          Begin
        </PrimaryButton>
      </Box>
    );
  }

  function renderTimerArea() {
    const expiresAt = queryResult.data?.viewer.creativesScaleUser?.activeWritingSample?.endsAt;
    const retryAt = queryResult.data?.viewer.creativesScaleUser?.retryWritingSampleAt;
    if (isWaitingForRetry && retryAt) {
      return (
        <Flex
          justifyContent={{ base: "center", md: "flex-start" }}
          alignItems="stretch"
          borderLeft={{ base: undefined, md: "1px solid #DADADA" }}
          paddingY="10px"
          paddingLeft={2}
          marginTop={{ base: 0, md: 2 }}
        >
          <Stack isInline spacing={2}>
            <Text color={AppColors.textPink} fontSize="small">
              Can retake on {DateTime.fromJSDate(retryAt).toFormat(DATE_NUMBER_FORMAT)}
            </Text>
          </Stack>
        </Flex>
      );
    }
    if (!isInProgress && !isInReview) {
      return null;
    }
    if (isInProgress) {
      if (!expiresAt) {
        return null;
      }
      return (
        <Flex
          justifyContent={{ base: "center", md: "flex-start" }}
          alignItems="stretch"
          borderLeft={{ base: undefined, md: "1px solid #DADADA" }}
          paddingY="10px"
          paddingLeft={4}
          marginTop={{ base: 0, md: 2 }}
        >
          <Stack isInline spacing={2}>
            <CountdownText date={expiresAt} color={AppColors.textPink} fontSize="small" marginLeft={2} />
            <Text color={AppColors.textPink} fontSize="small">
              Remaining
            </Text>
          </Stack>
        </Flex>
      );
    }
    if (isInReview) {
      return (
        <Flex
          justifyContent={{ base: "center", md: "flex-start" }}
          alignItems="stretch"
          borderLeft={{ base: undefined, md: "1px solid #DADADA" }}
          paddingLeft={4}
          paddingY="10px"
          marginTop={{ base: 0, md: 2 }}
        >
          <Text color={AppColors.textOrange} fontSize="small">
            Pending Approval
          </Text>
        </Flex>
      );
    }
  }

  return (
    <JourneyStepContainer>
      <Box>
        <Flex>
          <JourneyStepIconBox>
            <Image src={SampleSVG} alt="Grammar Assessment" marginTop={{ base: 1, md: 2 }} />
          </JourneyStepIconBox>
          <Text fontWeight="bold" fontSize={{ base: "md", md: "xl" }}>
            Writing Sample
          </Text>
        </Flex>
        <Text marginLeft="40px" color={AppColors.textDarkGray}>
          400 Word writing sample
        </Text>
        <Text marginLeft="40px" color={AppColors.textDarkGray}>
          60 Minutes to complete the test
        </Text>
      </Box>
      {renderActionArea()}
      {renderTimerArea()}
    </JourneyStepContainer>
  );
}
